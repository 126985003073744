<template>
  <!-- eslint-disable  -->

<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24.0252 9.67016L13.5286 17.406C12.9282 17.855 12.2198 18.0948 11.4948 18.0944H8.65744C7.68894 18.0896 6.7586 18.5112 6.07223 19.266C5.38585 20.0208 4.99999 21.0465 5 22.1164V26.6455C4.99999 27.7154 5.38585 28.7411 6.07223 29.4959C6.7586 30.2507 7.68894 30.6723 8.65744 30.6675H11.4948C12.2198 30.6671 12.9282 30.9069 13.5286 31.3559L24.0252 39.0917C25.1458 39.9103 26.5824 39.9843 27.7671 39.2846C28.9518 38.5849 29.6939 37.2241 29.7 35.7401V13.0218C29.6939 11.5378 28.9518 10.177 27.7671 9.4773C26.5824 8.7776 25.1458 8.85164 24.0252 9.67016Z"/>
  <path d="M33.9566 15.9475C33.5319 15.4167 32.7376 15.297 32.1582 15.6766C31.6029 16.0812 31.477 16.8224 31.8705 17.3699C35.1169 21.4998 35.1169 27.1421 31.8705 31.272C31.6863 31.4873 31.5906 31.7578 31.6007 32.034C31.6125 32.5387 31.9448 32.9875 32.4437 33.1725C32.9426 33.3574 33.5106 33.2424 33.8846 32.8807C37.8629 27.866 37.8921 20.992 33.9566 15.9475Z"/>
  <path d="M43 24.4152C42.997 19.6701 41.3208 15.0601 38.2343 11.309C37.7819 10.7534 36.9373 10.6473 36.3461 11.0719C36.0108 11.3151 35.812 11.6894 35.8066 12.0879C35.7908 12.3753 35.8727 12.66 36.0404 12.9007C41.6375 19.6977 41.6375 29.2005 36.0404 35.9975C35.6336 36.5549 35.7621 37.3147 36.3327 37.7256C36.9032 38.1364 37.7157 38.0541 38.1804 37.5384C41.2948 33.7918 42.9911 29.1731 43 24.4152Z"/>
</svg>

</template>
<script>
export default {
  name: 'VolumeUpIcon',
};
</script>
