<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.2927 21.516C39.6833 21.9066 39.6832 22.5399 39.2924 22.9304L38.2209 24.0012C37.8936 24.3282 37.3631 24.3281 37.0359 24.0009C36.7086 23.6737 36.178 23.6737 35.8507 24.0009L29.5474 30.3042C29.4078 30.4438 29.3127 30.6217 29.274 30.8153L28.2077 36.1479C28.169 36.3416 28.0738 36.5195 27.934 36.6592L26.4839 38.1083C26.0933 38.4986 25.4602 38.4985 25.0698 38.108L19.3738 32.4106C18.9833 32.02 18.35 32.02 17.9595 32.4105L11.0771 39.2929C10.6866 39.6834 10.0534 39.6834 9.66289 39.2929L8.70711 38.3371C8.31658 37.9466 8.31658 37.3134 8.70711 36.9229L15.5895 30.0405C15.98 29.65 15.98 29.0167 15.5894 28.6262L9.89202 22.9302C9.50152 22.5398 9.50137 21.9067 9.89169 21.5162L11.3408 20.066C11.4805 19.9263 11.6584 19.831 11.8521 19.7923L17.1864 18.726C17.38 18.6873 17.5579 18.5922 17.6975 18.4525L24.0007 12.1493C24.328 11.822 24.328 11.2914 24.0007 10.9641C23.6735 10.6369 23.6734 10.1064 24.0005 9.77907L25.0712 8.70765C25.4617 8.31687 26.0951 8.31678 26.4857 8.70744L39.2927 21.516Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'PinMessageIcon',
};
</script>
