<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4444 29.0855C16.4444 26.6736 18.4898 24.7266 21.0236 24.7266C21.3142 24.7266 21.6004 24.7567 21.8771 24.8099C22.213 24.8745 22.5499 24.6336 22.5499 24.2915V16.8603C22.5499 16.3695 22.9061 15.9513 23.3906 15.8731L30.9759 14.6491C31.2799 14.6 31.5556 14.8348 31.5556 15.1427V19.7308C31.5556 20.0549 31.2519 20.2934 30.937 20.2165L26.2212 19.0656C25.9064 18.9888 25.6027 19.2273 25.6027 19.5514V29.1291C25.5722 31.512 23.5421 33.4444 21.0236 33.4444C18.4898 33.4444 16.4444 31.4975 16.4444 29.0855Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8 7H34.2C37.9555 7 41 10.0445 41 13.8V34.2C41 37.9555 37.9555 41 34.2 41H13.8C10.0445 41 7 37.9555 7 34.2V13.8C7 10.0445 10.0445 7 13.8 7ZM36.8249 36.8249C37.5773 36.0726 38 35.0521 38 33.9881V14.0119C38 12.9479 37.5773 11.9274 36.8249 11.1751C36.0726 10.4227 35.0521 10 33.9881 10H14.0119C11.7962 10 10 11.7962 10 14.0119V33.9881C10 35.0521 10.4227 36.0726 11.1751 36.8249C11.9274 37.5773 12.9479 38 14.0119 38H33.9881C35.0521 38 36.0726 37.5773 36.8249 36.8249Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'AudioRecIcon',
};
</script>
