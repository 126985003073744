<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20.7788 41C20.5026 41 20.2788 40.7761 20.2788 40.5V13.9849C20.2788 13.7087 20.0549 13.4849 19.7788 13.4849H11.5C11.2239 13.4849 11 13.261 11 12.9849V7.5C11 7.22386 11.2239 7 11.5 7H36.9444C37.2206 7 37.4444 7.22386 37.4444 7.5V12.9849C37.4444 13.261 37.2206 13.4849 36.9444 13.4849H28.6657C28.3895 13.4849 28.1657 13.7087 28.1657 13.9849V40.5C28.1657 40.7761 27.9418 41 27.6657 41H20.7788Z"/>
  </svg>
</template>
<script>
export default {
  name: 'SyncIcon',
};
</script>
