<template>
  <!-- eslint-disable  -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" data-name="rise-hand-off-icon">
    <path
      d="M 25.455078 4.7265625 C 24.096896 4.7265625 23 5.8234492 23 7.1816406 L 23 19.353516 L 41 37.357422 L 41 13.726562 C 41 12.368372 39.903104 11.273438 38.544922 11.273438 C 37.186741 11.273437 36.091797 12.368372 36.091797 13.726562 L 36.091797 22.726562 L 34.455078 22.726562 L 34.455078 8.8183594 C 34.455078 7.460167 33.358182 6.3632812 32 6.3632812 C 30.641818 6.3632812 29.544922 7.460167 29.544922 8.8183594 L 29.544922 22.726562 L 27.908203 22.726562 L 27.908203 7.1816406 C 27.908203 5.8234492 26.813259 4.7265625 25.455078 4.7265625 z M 18.908203 8.8183594 C 17.55002 8.8183594 16.455078 9.9152464 16.455078 11.273438 L 16.455078 12.806641 L 21.363281 17.716797 L 21.363281 11.273438 C 21.363281 9.9152464 20.266385 8.8183594 18.908203 8.8183594 z M 16.455078 17.904297 L 16.455078 30.761719 C 16.455078 30.761719 9.4678038 26.752688 9.4023438 26.736328 C 9.1077988 26.572689 8.7799221 26.474609 8.4199219 26.474609 C 7.929013 26.474609 7.486953 26.638308 7.1269531 26.949219 C 7.0614984 26.981946 5 28.994141 5 28.994141 L 17.845703 42.052734 C 19.072976 43.296378 20.742495 44 22.509766 44 L 34.455078 44 C 36.648759 44 38.595907 42.903419 39.785156 41.234375 L 16.455078 17.904297 z "
    />
    <path d="M 8.55,10 6,12.55 39.45,46 42,43.46 Z" />
  </svg>
</template>
<script>
export default {
  name: 'HandOffIcon',
};
</script>
