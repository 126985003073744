<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.0018 25.0766L34.7376 41H13.2661L24.0018 25.0766ZM24.0018 31.446L19.958 37.4614H28.0456L24.0018 31.446ZM22.1051 20.7596C21.8414 20.5166 21.6299 20.2236 21.4833 19.8981C21.3366 19.5725 21.2577 19.2211 21.2513 18.8648C21.245 18.5084 21.3113 18.1545 21.4462 17.824C21.5812 17.4936 21.7822 17.1934 22.037 16.9414C22.2919 16.6894 22.5955 16.4907 22.9297 16.3572C23.2639 16.2237 23.6218 16.1582 23.9822 16.1645C24.3426 16.1708 24.698 16.2488 25.0272 16.3938C25.3564 16.5389 25.6527 16.748 25.8984 17.0088C26.3725 17.5118 26.6306 18.1772 26.6184 18.8648C26.6061 19.5523 26.3244 20.2083 25.8327 20.6946C25.3409 21.1808 24.6775 21.4593 23.9822 21.4715C23.2869 21.4836 22.6139 21.2284 22.1051 20.7596ZM11.9796 7L14.5114 9.50351C11.995 11.992 10.5814 15.3669 10.5814 18.8859C10.5814 22.405 11.995 25.7799 14.5114 28.2683L11.9796 30.772C10.4008 29.2111 9.14854 27.3581 8.29415 25.3186C7.43975 23.2792 7 21.0934 7 18.8859C7 16.6785 7.43975 14.4926 8.29415 12.4532C9.14854 10.4138 10.4008 8.5608 11.9796 7ZM36.0205 7C37.5992 8.5608 38.8514 10.4138 39.7059 12.4532C40.5603 14.4926 41 16.6785 41 18.8859C41 21.0934 40.5603 23.2792 39.7059 25.3186C38.8514 27.3581 37.5992 29.2111 36.0205 30.772L33.4886 28.2683C36.005 25.7799 37.4185 22.405 37.4185 18.8859C37.4185 15.3669 36.005 11.992 33.4886 9.50351L36.0205 7ZM17.0415 12.0035L19.5715 14.5052C18.9899 15.0802 18.5286 15.7629 18.2138 16.5143C17.899 17.2656 17.737 18.0709 17.737 18.8842C17.737 19.6974 17.899 20.5027 18.2138 21.2541C18.5286 22.0054 18.9899 22.6881 19.5715 23.2631L17.0415 25.7648C15.196 23.9399 14.1592 21.4649 14.1592 18.8842C14.1592 16.3034 15.196 13.8284 17.0415 12.0035ZM30.9585 12.0035C32.8041 13.8284 33.8409 16.3034 33.8409 18.8842C33.8409 21.4649 32.8041 23.9399 30.9585 25.7648L28.4285 23.2631C29.0101 22.6881 29.4715 22.0054 29.7862 21.2541C30.1009 20.5027 30.263 19.6974 30.263 18.8842C30.263 18.0709 30.1009 17.2656 29.7862 16.5143C29.4715 15.7629 29.0101 15.0802 28.4285 14.5052L30.9585 12.0035Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'TelecommunicationTowerIcon',
};
</script>
