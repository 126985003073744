<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.1181 9.9002H30.6842L28.6748 7.32607C27.9393 6.47697 26.9495 6.0008 25.9181 6H21.4455C20.3963 6.01031 19.3932 6.50812 18.6556 7.38457L16.6795 9.9002H12.2455C11.5576 9.9002 11 10.555 11 11.3628C11 12.1705 11.5576 12.8253 12.2455 12.8253H35.1181C35.806 12.8253 36.3636 12.1705 36.3636 11.3628C36.3636 10.555 35.806 9.9002 35.1181 9.9002ZM20.4507 9.38982C20.7157 9.08051 21.0734 8.90548 21.4471 8.9023H25.9196C26.2778 8.90105 26.6227 9.06164 26.8828 9.35082L27.298 9.87735H20.0688L20.4507 9.38982Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 19.0909L11 33.8182C11 40.3636 12.5852 42 18.8881 42H28.4375C34.7784 42 36.359 40.3636 36.359 33.8182V19.0909C36.359 15.8182 34.7784 14.8156 31.608 14.8156H15.7557C12.5852 14.8156 11 15.8182 11 19.0909ZM20.1336 27.3992C20.1336 28.207 19.576 28.8618 18.8881 28.8618C18.2003 28.8618 17.6426 28.207 17.6426 27.3992V20.9775C17.6426 20.1697 18.2003 19.5149 18.8881 19.5149C19.576 19.5149 20.1336 20.1697 20.1336 20.9775V27.3992ZM23.8701 36.5647C24.558 36.5647 25.1156 35.9098 25.1156 35.1021V20.9775C25.1156 20.1697 24.558 19.5149 23.8701 19.5149C23.1822 19.5149 22.6246 20.1697 22.6246 20.9775V35.1021C22.6246 35.9098 23.1822 36.5647 23.8701 36.5647ZM30.0976 27.3992C30.0976 28.207 29.54 28.8618 28.8521 28.8618C28.1642 28.8618 27.6066 28.207 27.6066 27.3992V20.9775C27.6066 20.1697 28.1642 19.5149 28.8521 19.5149C29.54 19.5149 30.0976 20.1697 30.0976 20.9775V27.3992Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'DeleteIcon',
};
</script>
