<template>
  <!-- eslint-disable  -->
  <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.500001 1.39258C0.50121 1.7526 0.634085 2.09759 0.869799 2.3527L7.59338 9.59887C7.82865 9.85554 8.14949 10 8.48426 10C8.81903 10 9.13986 9.85554 9.37513 9.59887L16.0987 2.3527C16.436 2.014 16.5748 1.50389 16.4608 1.0225C16.3467 0.541118 15.9979 0.165224 15.5512 0.0422859C15.1046 -0.0806523 14.6312 0.0689735 14.317 0.432466L8.48426 6.71852L2.65155 0.432465C2.41628 0.175795 2.09544 0.0313347 1.76067 0.0313347C1.4259 0.0313347 1.10507 0.175795 0.869799 0.432465C0.634085 0.687577 0.50121 1.03256 0.500001 1.39258Z"
      fill="currentColor"
      style="fill: currentColor; fill: color(display-p3 0.2627 0.3373 0.4275); fill-opacity: 1"
    />
  </svg>
</template>
<script>
export default {
  name: 'ArrowDownOutlineIcon',
};
</script>
