<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0832 8.00169H32.0119C36.322 7.96527 39.8708 11.3804 40 15.6887V27.227C39.8707 31.5416 36.3123 34.9591 31.996 34.914H29.5235C28.9076 34.9159 28.3253 35.1954 27.9386 35.6748L25.5929 38.6545C25.2014 39.1257 24.6206 39.3984 24.0079 39.3984C23.3953 39.3984 22.8144 39.1257 22.423 38.6545L20.0614 35.6748C19.6747 35.1954 19.0924 34.9159 18.4765 34.914H16.0832C11.7363 35.0029 8.12953 31.5729 8 27.227V15.6887C8.12953 11.3428 11.7363 7.9128 16.0832 8.00169ZM28.4595 16.4453V18.6286C28.4595 19.3655 29.0531 19.9629 29.7853 19.9629C30.5175 19.9629 31.1111 19.3655 31.1111 18.6286V16.4453C31.1111 15.7085 30.5175 15.1111 29.7853 15.1111C29.0531 15.1111 28.4595 15.7085 28.4595 16.4453ZM19.5404 16.4453V18.6286C19.5404 19.3655 18.9469 19.9629 18.2147 19.9629C17.4824 19.9629 16.8889 19.3655 16.8889 18.6286V16.4453C16.8889 15.7085 17.4824 15.1111 18.2147 15.1111C18.9469 15.1111 19.5404 15.7085 19.5404 16.4453ZM28.6869 27.7268C29.708 26.9329 30.5075 25.8962 31.0191 24.7182C31.2879 24.0995 30.8888 23.4266 30.2393 23.2575C29.6619 23.1071 29.0821 23.4637 28.82 24.0029C28.4613 24.7409 27.9374 25.3912 27.2839 25.8994C26.3435 26.6305 25.1882 27.0262 23.9999 27.0239C22.8116 27.0216 21.6578 26.6216 20.7202 25.8869C20.0686 25.3762 19.5471 24.724 19.1912 23.9846C18.9311 23.4444 18.3527 23.0856 17.7747 23.2338C17.1245 23.4005 16.723 24.0718 16.9894 24.6915C17.4966 25.8715 18.2922 26.9112 19.3103 27.709C20.6497 28.7586 22.298 29.3301 23.9956 29.3333C25.6932 29.3365 27.3436 28.7714 28.6869 27.7268Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'FillFeedbackIcon',
};
</script>
