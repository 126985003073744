<template>
  <!-- eslint-disable -->
  
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M26.6947 6.98838C28.0125 5.67054 30.1491 5.67054 31.4669 6.98838L41.0116 16.5329C42.3295 17.8508 42.3295 19.9874 41.0116 21.3052L24.5622 37.7546H35.9828C39 37.7546 39 42 36 41.9952H19.0969C18.175 42.0436 17.2371 41.7158 16.5329 41.0114L6.98836 31.467C5.67055 30.1491 5.67055 28.0126 6.98836 26.6947L26.6947 6.98838ZM31.1584 31.1584L16.8415 16.8416L14.8415 18.8416L29.1584 33.1584L31.1584 31.1584ZM12.2454 21.4377L26.5622 35.7546L24.5622 37.7546L10.2454 23.4377L12.2454 21.4377Z"/>
  </svg>

</template>

<script>
export default {
  name: 'EraserIcon',
};
</script>
