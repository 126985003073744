<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" data-name="board-icon">
    <path
      d="M21.4604 20.241L34.8801 8.27935C35.1354 8.08092 35.4545 7.9825 35.7772 8.00256C36.1 8.02261 36.4044 8.15976 36.6333 8.38828L40.6112 12.3654C40.84 12.5944 40.9774 12.8991 40.9974 13.2222C41.0175 13.5453 40.9189 13.8646 40.7201 14.1202L28.7602 27.539C27.6519 25.2311 23.7703 21.349 21.4604 20.241ZM28.6869 22.3838L37.2995 13.2978L35.8791 11.8774L26.7931 20.49C27.1806 20.8775 27.3525 21.0492 27.74 21.4369C28.0844 21.7814 28.3423 22.0396 28.6869 22.3838Z"
    />
    <path
      d="M20.5512 22.1542C22.3429 23.0278 23.2576 23.8626 24.2513 24.9255C25.1051 25.8387 26.0959 26.9088 26.8472 28.448C27.1574 29.7131 27.176 31.0322 26.9017 32.3056C26.6273 33.5791 26.0672 34.7735 25.2637 35.7987C24.4601 36.824 23.4341 37.6532 22.2631 38.2238C21.0921 38.7944 19.8067 39.0915 18.5041 39.0925C14.8701 39.0925 10.2979 37.6807 7.34045 36.1376C6.91948 35.9179 6.88788 35.341 7.25138 35.0355C10.9074 31.9627 9.99578 30.0245 10.4828 28.2074C11.6949 23.6824 16.0297 21.0396 20.5512 22.1542Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'WhiteboardIcon',
};
</script>
