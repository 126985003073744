<template>
  <!-- eslint-disable  -->
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :data-name="dataName ? dataName : 'mic-off-icon'"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.2535 7.56948C27.8608 5.07084 24.2607 4.31513 21.1256 5.65344C18.9989 6.56132 17.3689 8.31213 16.5339 10.4461L32.13 26.0425C32.5012 25.055 32.705 23.98 32.705 22.8554V13.6907C32.6913 11.3942 31.8107 9.19543 30.2535 7.56948ZM36.0625 29.9751C37.07 27.9791 37.6262 25.705 37.5991 23.3071C37.5991 22.573 37.0277 21.978 36.3229 21.978C35.6182 21.978 35.0468 22.573 35.0468 23.3071C35.0738 24.9534 34.7572 26.5689 34.1367 28.0493L36.0625 29.9751Z"
    />
    <path
      d="M27.5 43H21C20.1716 43 19.5 42.3284 19.5 41.5C19.5 40.6716 20.1716 40 21 40H23.0245V37.4559C16.1615 36.7013 10.9641 30.6526 11.0022 23.4645C10.9752 22.9897 11.1935 22.5359 11.5749 22.2741C11.9562 22.0123 12.4426 21.9823 12.851 22.1954C13.2593 22.4085 13.5275 22.8323 13.5545 23.3071C13.5051 26.3252 14.6101 29.2398 16.626 31.4084C18.6418 33.577 21.403 34.8215 24.3006 34.8675C26.5782 34.8314 28.7715 34.0548 30.5848 32.671L28.4047 30.4909C27.1933 31.1973 25.7966 31.6 24.3095 31.6C19.6729 31.6 15.9141 27.6849 15.9141 22.8554V18L11.414 13.4999C11.0235 13.1093 11.0235 12.4762 11.414 12.0857L12.0876 11.412C12.4781 11.0215 13.1113 11.0215 13.5018 11.412L37.1754 35.0861C37.566 35.4766 37.566 36.1098 37.1754 36.5003L36.5018 37.1739C36.1113 37.5644 35.4781 37.5644 35.0876 37.1739L32.4234 34.5097C30.5038 36.0696 28.1585 37.1022 25.5768 37.386V40H27.5C28.3284 40 29 40.6716 29 41.5C29 42.3284 28.3284 43 27.5 43Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'MicOffIcon',
  props: {
    dataName: String,
  },
};
</script>
