<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 8C7.79086 8 6 9.79086 6 12V36C6 38.2091 7.79086 40 10 40H22C24.2091 40 26 38.2091 26 36V26H16C14.8954 26 14 25.1046 14 24C14 22.8954 14.8954 22 16 22H26V12C26 9.79086 24.2091 8 22 8H10Z"/>
  <path d="M26 22V26H35.9498L32.4814 29.3106C32.1734 29.6047 32 30.0057 32 30.4242C32 30.8426 32.1734 31.2437 32.4814 31.5378C32.7875 31.8324 33.2015 31.9985 33.6335 32C34.0655 31.9985 34.4795 31.8324 34.7856 31.5378L41.481 25.1333C41.789 24.8392 41.9624 24.4381 41.9624 24.0197C41.9624 23.6012 41.789 23.2002 41.481 22.9061L34.7856 16.5016C34.3792 16.08 33.7671 15.9065 33.1894 16.049C32.6117 16.1916 32.1607 16.6276 32.0131 17.186C31.8656 17.7443 32.0452 18.3359 32.4814 18.7288L35.9086 22H26Z"/>
  </svg>


</template>
<script>
export default {
  name: 'ExitIcon',
};
</script>
