<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.9629 40C21.2194 40.0003 20.5051 39.7097 19.9719 39.19L6.82396 26.0039C5.72535 24.9008 5.72535 23.1136 6.82396 22.0105L19.9719 8.82439C20.6866 8.11102 21.7264 7.83422 22.6998 8.09824C23.6731 8.36227 24.4321 9.12701 24.6907 10.1044C24.9494 11.0818 24.6685 12.1234 23.9538 12.8367L15.6276 21.2012H39.1826C40.7386 21.2012 42 22.4662 42 24.0268C42 25.5873 40.7386 26.8523 39.1826 26.8523H15.6386L23.9538 35.1777C24.7586 35.9857 24.9991 37.2002 24.5635 38.2554C24.1278 39.3106 23.1016 39.999 21.9629 40Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ArrowBackIcon',
};
</script>
