<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24ZM24 40.4091C33.0625 40.4091 40.4091 33.0625 40.4091 24C40.4091 14.9375 33.0625 7.59091 24 7.59091C14.9375 7.59091 7.59091 14.9375 7.59091 24C7.59091 33.0625 14.9375 40.4091 24 40.4091Z"
      fill="#43566D"
    />
    <path
      d="M25.75 19.6249C25.75 18.8095 25.75 18.4018 25.8832 18.0802C26.0608 17.6514 26.4015 17.3108 26.8303 17.1331C27.1519 16.9999 27.5596 16.9999 28.375 16.9999C29.1904 16.9999 29.5981 16.9999 29.9197 17.1331C30.3485 17.3108 30.6892 17.6514 30.8668 18.0802C31 18.4018 31 18.8095 31 19.6249V28.3749C31 29.1903 31 29.598 30.8668 29.9196C30.6892 30.3484 30.3485 30.6891 29.9197 30.8667C29.5981 30.9999 29.1904 30.9999 28.375 30.9999C27.5596 30.9999 27.1519 30.9999 26.8303 30.8667C26.4015 30.6891 26.0608 30.3484 25.8832 29.9196C25.75 29.598 25.75 29.1903 25.75 28.3749V19.6249Z"
    />
    <path
      d="M17 19.6249C17 18.8095 17 18.4018 17.1332 18.0802C17.3108 17.6514 17.6515 17.3108 18.0803 17.1331C18.4019 16.9999 18.8096 16.9999 19.625 16.9999C20.4404 16.9999 20.8481 16.9999 21.1697 17.1331C21.5985 17.3108 21.9392 17.6514 22.1168 18.0802C22.25 18.4018 22.25 18.8095 22.25 19.6249V28.3749C22.25 29.1903 22.25 29.598 22.1168 29.9196C21.9392 30.3484 21.5985 30.6891 21.1697 30.8667C20.8481 30.9999 20.4404 30.9999 19.625 30.9999C18.8096 30.9999 18.4019 30.9999 18.0803 30.8667C17.6515 30.6891 17.3108 30.3484 17.1332 29.9196C17 29.598 17 29.1903 17 28.3749V19.6249Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'PauseCircleIcon',
};
</script>
