<template>
  <!-- eslint-disable  -->

<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0252 9.67016L19.2656 13.1779L29.7 23.6123V13.0218C29.6939 11.5378 28.9518 10.177 27.7671 9.4773C26.5824 8.7776 25.1458 8.85164 24.0252 9.67016ZM35.7881 29.7004L33.7788 27.6911C34.8846 24.2318 34.2485 20.3951 31.8705 17.3699C31.477 16.8224 31.6029 16.0812 32.1582 15.6766C32.7376 15.297 33.5319 15.4167 33.9566 15.9475C37.1102 19.9897 37.718 25.2067 35.7881 29.7004ZM40.3975 34.3098L38.41 32.3223C41.4757 26.0472 40.6858 18.542 36.0403 12.9007C35.8727 12.66 35.7908 12.3753 35.8066 12.0879C35.812 11.6894 36.0108 11.3151 36.3461 11.0719C36.9373 10.6473 37.7819 10.7534 38.2343 11.309C41.3208 15.0601 42.997 19.6701 43 24.4152C42.9935 27.8817 42.0913 31.2744 40.3975 34.3098Z"/>
  <path d="M13.5286 17.406C12.9282 17.855 12.2198 18.0948 11.4948 18.0944H8.65744C7.68894 18.0896 6.7586 18.5112 6.07223 19.266C5.38585 20.0208 4.99999 21.0465 5 22.1164V26.6455C4.99999 27.7154 5.38585 28.7411 6.07223 29.4959C6.7586 30.2507 7.68894 30.6723 8.65744 30.6675H11.4948C12.2198 30.6671 12.9282 30.9069 13.5286 31.3559L24.0252 39.0917C25.1458 39.9103 26.5824 39.9843 27.7671 39.2846C28.9518 38.5849 29.6939 37.2241 29.7 35.7401V31.6129L37.204 39.0593C37.5945 39.4468 38.2277 39.4468 38.6182 39.0593L39.2919 38.3908C39.6824 38.0033 39.6824 37.375 39.2919 36.9875L11.7949 9.70173C11.4044 9.31421 10.7713 9.31421 10.3807 9.70173L9.70711 10.3702C9.31658 10.7577 9.31658 11.386 9.70711 11.7735L14.5928 16.6217L13.5286 17.406Z"/>
</svg>
</template>
<script>
export default {
  name: 'VolumeOffIcon',
};
</script>
