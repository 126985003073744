<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.1559 30.7411L30.2384 12.588C28.9996 10.3741 26.6427 9 24.0839 9C21.5251 9 19.1682 10.3741 17.9294 12.588L7.85367 30.7237C6.6699 32.8776 6.72023 35.4887 7.98616 37.5964C9.25208 39.7041 11.5466 40.9972 14.0257 41H33.9662C36.4432 41 38.7376 39.7117 40.0067 37.6083C41.2757 35.5049 41.3324 32.8965 40.1559 30.7411ZM22 18.9608C22 17.8779 22.8954 17 24 17C25.1046 17 26 17.8779 26 18.9608V27.0392C26 28.1221 25.1046 29 24 29C22.8954 29 22 28.1221 22 27.0392V18.9608ZM24 36C25.1046 36 26 35.1046 26 34L25.9733 33.9733C25.9733 32.8835 25.0898 32 24 32C22.9014 32.0144 22.0144 32.9014 22 34C22 35.1046 22.8954 36 24 36Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'FeedbackIcon',
};
</script>
