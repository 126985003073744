<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0768 8.57291C7.12293 9.58737 5 12.3896 5 15.6877V35.4792C5 39.6328 8.36717 43 12.5208 43L32.3123 43C35.6103 43 38.4126 40.8771 39.427 37.9233C38.428 38.5407 37.2735 38.9308 36.0359 39.0214C35.0991 40.0057 33.7776 40.6205 32.3123 40.625L12.5208 40.625C9.68244 40.6163 7.38368 38.3175 7.37498 35.4792V15.6877C7.37947 14.2224 7.99434 12.9008 8.97872 11.964C9.06934 10.7264 9.45943 9.57199 10.0768 8.57291Z"
      fill="#43566D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.6515 12.9299L35.0228 5.30109C34.7895 5.10115 34.4908 4.99407 34.1836 5.00025L16.4792 5.00025C12.3256 5.00025 8.9584 8.36745 8.9584 12.5211L8.95831 31.5208C8.95831 37.9233 10.0768 39.0417 16.4791 39.0417H35.479C39.6326 39.0417 42.9997 35.6745 42.9997 31.5208L42.9998 13.8165C43.0052 13.4866 42.88 13.1679 42.6515 12.9299ZM11.3332 31.5208C11.3332 36.6667 11.3333 36.6667 16.479 36.6667H35.4789C38.3172 36.658 40.616 34.3592 40.6247 31.5208L40.6248 15.004H36.0909C34.3636 15.004 32.996 13.6364 32.996 11.9091V7.37525L16.4791 7.37525C13.6407 7.38395 11.342 9.68273 11.3333 12.5211L11.3332 31.5208Z"
      fill="#43566D"
    />
  </svg>
</template>
<script>
export default {
  name: 'RecordMorePartsIcon',
};
</script>
