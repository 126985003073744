<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2857 34.1055C17.2857 33.6205 17.4677 33.1354 17.8331 32.7653L26.4857 24L17.8331 15.2347C17.1037 14.4945 17.1037 13.2945 17.8331 12.5542C18.5637 11.8153 19.7481 11.8153 20.4787 12.5542L30.453 22.6598C31.1823 23.4 31.1823 24.6 30.453 25.3402L20.4787 35.4458C19.7481 36.1847 18.5637 36.1847 17.8331 35.4458C17.4677 35.0757 17.2857 34.5906 17.2857 34.1055Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'KeyboardArrowRightIcon',
};
</script>
