<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M34.5 22.2C34.6657 22.2 34.8 22.0657 34.8 21.9V17.5243C34.8 17.257 35.1231 17.1231 35.3121 17.3121L41.7879 23.7879C41.905 23.905 41.905 24.095 41.7879 24.2121L35.3121 30.6879C35.1231 30.8769 34.8 30.743 34.8 30.4757V26.1C34.8 25.9343 34.6657 25.8 34.5 25.8H26.3C26.0239 25.8 25.8 26.0239 25.8 26.3V34.5C25.8 34.6657 25.9343 34.8 26.1 34.8H30.4757C30.743 34.8 30.8769 35.1231 30.6879 35.3121L24.2121 41.7879C24.095 41.905 23.905 41.905 23.7879 41.7879L17.3121 35.3121C17.1231 35.1231 17.257 34.8 17.5243 34.8H21.9C22.0657 34.8 22.2 34.6657 22.2 34.5V26.3C22.2 26.0239 21.9761 25.8 21.7 25.8H13.5C13.3343 25.8 13.2 25.9343 13.2 26.1V30.4757C13.2 30.743 12.8769 30.8769 12.6879 30.6879L6.21213 24.2121C6.09497 24.095 6.09497 23.905 6.21213 23.7879L12.6879 17.3121C12.8769 17.1231 13.2 17.257 13.2 17.5243V21.9C13.2 22.0657 13.3343 22.2 13.5 22.2H21.7C21.9761 22.2 22.2 21.9761 22.2 21.7V13.5C22.2 13.3343 22.0657 13.2 21.9 13.2H17.5243C17.257 13.2 17.1231 12.8769 17.3121 12.6879L23.7879 6.21213C23.905 6.09497 24.095 6.09497 24.2121 6.21213L30.6879 12.6879C30.8769 12.8769 30.743 13.2 30.4757 13.2H26.1C25.9343 13.2 25.8 13.3343 25.8 13.5V21.7C25.8 21.9761 26.0239 22.2 26.3 22.2H34.5Z"/>
  </svg>

</template>
<script>
export default {
  name: 'PanIcon',
};
</script>
