<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24ZM24 40.4091C33.0625 40.4091 40.4091 33.0625 40.4091 24C40.4091 14.9375 33.0625 7.59091 24 7.59091C14.9375 7.59091 7.59091 14.9375 7.59091 24C7.59091 33.0625 14.9375 40.4091 24 40.4091Z"
      fill="#43566D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.1851 16H16.8204C14.9106 16.0912 13.4305 17.7337 13.5055 19.6787V26.8659C13.4305 28.811 14.9106 30.4534 16.8204 30.5446H20.8535C20.8535 30.5446 20.288 32.0561 20.069 32.6725C19.9561 32.9549 19.9805 33.275 20.1349 33.5362C20.2893 33.7975 20.5556 33.9693 20.8535 34H27.1519C27.4499 33.9693 27.7162 33.7975 27.8706 33.5362C28.025 33.275 28.0494 32.9549 27.9364 32.6725C27.7175 32.0561 27.1519 30.5446 27.1519 30.5446H31.1851C33.0949 30.4534 34.5749 28.811 34.5 26.8659V19.6787C34.5749 17.7337 33.0949 16.0912 31.1851 16ZM21.9585 32.3125L22.511 31.1071H25.406L25.9585 32.3125H21.9585ZM31.1851 28.8571C32.1771 28.7619 32.913 27.8778 32.8425 26.8659V19.6787C32.913 18.6668 32.1771 17.7827 31.1851 17.6875H16.8204C15.8284 17.7827 15.0925 18.6668 15.1629 19.6787V26.8659C15.0925 27.8778 15.8284 28.7619 16.8204 28.8571H31.1851Z"
      fill="#43566D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.9901 19.4714C24.9901 19.2327 24.8861 19.0038 24.7009 18.835C24.5158 18.6663 24.2646 18.5714 24.0027 18.5714C23.7408 18.5714 23.4897 18.6663 23.3045 18.835C23.1193 19.0038 23.0153 19.2327 23.0153 19.4714V24.499L21.7386 23.3352C21.5524 23.1713 21.3029 23.0806 21.0441 23.0826C20.7852 23.0847 20.5375 23.1793 20.3544 23.3462C20.1714 23.5131 20.0675 23.7388 20.0653 23.9748C20.063 24.2108 20.1625 24.4381 20.3424 24.6079L23.3046 27.3079C23.4898 27.4766 23.7409 27.5714 24.0027 27.5714C24.2646 27.5714 24.5157 27.4766 24.7008 27.3079L27.663 24.6079C27.8429 24.4381 27.9424 24.2108 27.9402 23.9748C27.9379 23.7388 27.8341 23.5131 27.651 23.3462C27.4679 23.1793 27.2203 23.0847 26.9614 23.0826C26.7025 23.0806 26.4531 23.1713 26.2669 23.3352L24.9901 24.499V19.4714Z"
      fill="#43566D"
    />
  </svg>
</template>
<script>
export default {
  name: 'LocalRecordCircleIcon',
};
</script>
