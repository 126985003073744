<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="24" cy="24" r="19"/>
  </svg>

</template>
<script>
export default {
  name: 'FilledCircleIcon',
};
</script>
