export const VERTICAL = 'vertical';
export const HORIZONTAL = 'horizontal';
export const LEFT = 'left';
export const RIGHT = 'right';
export const CENTER = 'center';
export const TOP = 'top';
export const BOTTOM = 'bottom';
export const MIDDLE = 'middle';
export const KEY_ESC = 27;
export const KEY_ENTER = 13;
export const KEY_ARROW_UP = 38;
export const AJAX_RESPONSE_TYPE_DEFAULT = '';
export const AJAX_RESPONSE_TYPE_ARRAY_BUFFER = 'arraybuffer';
export const AJAX_RESPONSE_TYPE_BLOB = 'blob';
export const AJAX_RESPONSE_TYPE_DOCUMENT = 'document';
export const AJAX_RESPONSE_TYPE_JSON = 'json';
export const AJAX_RESPONSE_TYPE_TEXT = 'text';
export const AJAX_CONTENT_TYPE_FORM_DATA = 'formData';
export const AJAX_CONTENT_TYPE_STRING = 'string';
export const AJAX_CONTENT_TYPE_JSON = 'json';

// TODO backward compatibility
window.VERTICAL = VERTICAL;
window.HORIZONTAL = HORIZONTAL;
window.LEFT = LEFT;
window.RIGHT = RIGHT;
window.CENTER = CENTER;
window.TOP = TOP;
window.BOTTOM = BOTTOM;
window.MIDDLE = MIDDLE;
window.KEY_ESC = KEY_ESC;
window.KEY_ENTER = KEY_ENTER;
window.KEY_ARROW_UP = KEY_ARROW_UP;
window.AJAX_RESPONSE_TYPE_DEFAULT = AJAX_RESPONSE_TYPE_DEFAULT;
window.AJAX_RESPONSE_TYPE_ARRAY_BUFFER = AJAX_RESPONSE_TYPE_ARRAY_BUFFER;
window.AJAX_RESPONSE_TYPE_BLOB = AJAX_RESPONSE_TYPE_BLOB;
window.AJAX_RESPONSE_TYPE_DOCUMENT = AJAX_RESPONSE_TYPE_DOCUMENT;
window.AJAX_RESPONSE_TYPE_JSON = AJAX_RESPONSE_TYPE_JSON;
window.AJAX_RESPONSE_TYPE_TEXT = AJAX_RESPONSE_TYPE_TEXT;
window.AJAX_CONTENT_TYPE_FORM_DATA = AJAX_CONTENT_TYPE_FORM_DATA;
window.AJAX_CONTENT_TYPE_STRING = AJAX_CONTENT_TYPE_STRING;
window.AJAX_CONTENT_TYPE_JSON = AJAX_CONTENT_TYPE_JSON;
