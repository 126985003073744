<template>
  <svg width="10" height="13" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7467 4.59878C13.356 4.59878 13.0392 4.92389 13.0392 5.32492V13.4504C13.0392 15.1612 11.6879 16.548 10.0211 16.548H4.93415C3.26729 16.548 1.91603 15.1612 1.91603 13.4504V5.32492C1.91603 4.92389 1.59927 4.59878 1.20852 4.59878C0.817771 4.59878 0.501007 4.92389 0.501007 5.32492V13.4504C0.525564 15.9707 2.52322 18.0005 4.97893 18.0003H10.0659C12.5041 17.9754 14.4747 15.9529 14.499 13.4504V5.32492C14.4994 5.1241 14.4187 4.93208 14.2762 4.7945C14.1337 4.65692 13.942 4.58607 13.7467 4.59878Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7467 3.2903H1.20852C0.817771 3.2903 0.501007 2.9652 0.501007 2.56416C0.501007 2.16312 0.817771 1.83801 1.20852 1.83801H3.58182L4.63861 0.661469C5.04258 0.239946 5.59398 0.0016643 6.17006 -0.000335693H8.78516C9.35285 -0.000342906 9.89728 0.231103 10.2987 0.643086L11.3734 1.83801H13.7467C14.1374 1.83801 14.4542 2.16312 14.4542 2.56416C14.4542 2.9652 14.1374 3.2903 13.7467 3.2903ZM8.78551 1.45052H6.17041C5.99137 1.45059 5.81884 1.51945 5.68679 1.64355L5.51663 1.82739H9.43929L9.26913 1.64355C9.13805 1.51787 8.96489 1.44875 8.78551 1.45052Z"
      fill="currentColor"
    />
    <path
      d="M4.11819 6.23983V8.99735C4.11819 9.37808 4.41892 9.68673 4.78988 9.68673C5.16085 9.68673 5.46157 9.37808 5.46157 8.99735V6.23983C5.46157 5.85909 5.16085 5.55045 4.78988 5.55045C4.41892 5.55045 4.11819 5.85909 4.11819 6.23983Z"
      fill="currentColor"
    />
    <path
      d="M6.80569 6.23983V13.5932C6.80569 13.9739 7.10642 14.2826 7.47738 14.2826C7.84835 14.2826 8.14907 13.9739 8.14907 13.5932V6.23983C8.14907 5.85909 7.84835 5.55045 7.47738 5.55045C7.10642 5.55045 6.80569 5.85909 6.80569 6.23983Z"
      fill="currentColor"
    />
    <path
      d="M9.49319 6.23983V8.99735C9.49319 9.37808 9.79392 9.68673 10.1649 9.68673C10.5358 9.68673 10.8366 9.37808 10.8366 8.99735V6.23983C10.8366 5.85909 10.5358 5.55045 10.1649 5.55045C9.79392 5.55045 9.49319 5.85909 9.49319 6.23983Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TrashIcon',
};
</script>
