<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.36333 9.67412C6.16375 9.47815 6.16375 9.15657 6.36333 8.96059L8.00972 7.34398C8.20422 7.153 8.51585 7.153 8.71035 7.34398L37.7437 35.8524C37.9433 36.0483 37.9433 36.3699 37.7437 36.5659L36.0974 38.1825C35.9029 38.3735 35.5912 38.3735 35.3967 38.1825L30.4921 33.3665H17.758C16.7186 33.3756 15.7273 33.7976 15.0101 34.5363L11.2457 38.4821C10.7313 38.9961 9.94648 39.1435 9.27525 38.8521C8.60403 38.5607 8.18683 37.8915 8.22783 37.172V15.7583C8.19673 14.5126 8.49108 13.3327 9.03507 12.2975L6.36333 9.67412Z"
      fill="#CF7071"
      style="fill: #cf7071; fill: color(display-p3 0.8118 0.4392 0.4431); fill-opacity: 1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.9426 8.41244H15.8222L38.4419 30.6231C39.5048 29.293 40.0596 27.6302 39.9949 25.9271V15.7583C40.0931 11.8252 36.947 8.5481 32.9426 8.41244Z"
      fill="#CF7071"
      style="fill: #cf7071; fill: color(display-p3 0.8118 0.4392 0.4431); fill-opacity: 1"
    />
  </svg>
</template>

<script>
export default {
  name: 'DisabledChatIcon',
};
</script>
