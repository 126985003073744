<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24ZM24 12C17.3726 12 12 17.3726 12 24C12 26.2671 12.6287 28.3873 13.7212 30.1959L30.2133 13.7317C28.4009 12.6327 26.2744 12 24 12ZM36 24C36 30.6274 30.6274 36 24 36C21.742 36 19.6297 35.3763 17.8258 34.2919L34.2516 17.7593C35.3609 19.5776 36 21.7142 36 24Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'BlockIcon',
};
</script>
