<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4891 30.3516C10.6802 27.9374 8.67542 25.1802 7.73298 23.7545C8.67358 22.3288 10.6802 19.5716 13.4891 17.1574C16.3902 14.6657 19.9718 12.6886 23.9998 12.6886C28.0278 12.6886 31.6094 14.6657 34.5105 17.1574C37.3194 19.5716 39.3242 22.3288 40.2666 23.7545C39.326 25.1802 37.3194 27.9374 34.5105 30.3516C31.6094 32.8433 28.0278 34.8204 23.9998 34.8204C19.9718 34.8204 16.3902 32.8433 13.4891 30.3516ZM23.9998 9C18.7398 9 14.3301 11.5728 11.0859 14.3596C7.83072 17.1574 5.57144 20.3204 4.56814 21.8549C4.19749 22.4191 4 23.0794 4 23.7545C4 24.4296 4.19749 25.0899 4.56814 25.6541C5.57144 27.1886 7.83072 30.3516 11.0859 33.1494C14.3319 35.9362 18.7398 38.509 23.9998 38.509C29.2598 38.509 33.6695 35.9362 36.9137 33.1494C40.1689 30.3516 42.4282 27.1886 43.4315 25.6541C44.1895 24.4959 44.1895 23.0149 43.4315 21.8549C42.4282 20.3204 40.1689 17.1574 36.9137 14.3596C33.6677 11.5728 29.2598 9 23.9998 9ZM20.3112 23.7545C20.3112 22.7762 20.6998 21.838 21.3916 21.1462C22.0833 20.4545 23.0215 20.0659 23.9998 20.0659C24.9781 20.0659 25.9163 20.4545 26.6081 21.1462C27.2998 21.838 27.6884 22.7762 27.6884 23.7545C27.6884 24.7328 27.2998 25.671 26.6081 26.3628C25.9163 27.0545 24.9781 27.4431 23.9998 27.4431C23.0215 27.4431 22.0833 27.0545 21.3916 26.3628C20.6998 25.671 20.3112 24.7328 20.3112 23.7545ZM23.9998 16.3772C22.0432 16.3772 20.1668 17.1545 18.7833 18.538C17.3998 19.9215 16.6226 21.7979 16.6226 23.7545C16.6226 25.7111 17.3998 27.5875 18.7833 28.971C20.1668 30.3545 22.0432 31.1317 23.9998 31.1317C25.9564 31.1317 27.8328 30.3545 29.2163 28.971C30.5998 27.5875 31.3771 25.7111 31.3771 23.7545C31.3771 21.7979 30.5998 19.9215 29.2163 18.538C27.8328 17.1545 25.9564 16.3772 23.9998 16.3772Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'PreviewIcon',
};
</script>
