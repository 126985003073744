<template>
  <!-- eslint-disable  -->
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :data-name="dataName ? dataName : 'webcam-icon'"
  >
    <path
      d="M28.9975 26.8198L37.058 31.8085C38.09 32.4473 40.1476 33.7792 41.1804 33.1419C42.2132 32.5047 41.9753 30.1346 41.9785 28.8571V18.8559C41.9753 17.5784 42.2132 14.9229 41.1804 14.2857C40.1476 13.6485 38.09 14.9863 37.058 15.6251L28.9975 20.6138C27.962 21.2528 27.3238 22.436 27.3238 23.7168C27.3238 24.9976 27.962 26.1809 28.9975 26.8198Z"
    />
    <path
      d="M6 13.4286C6 11.535 7.43194 10 9.19833 10H28.3883C30.1547 10 31.5867 11.535 31.5867 13.4286V34C31.5867 35.8935 30.1547 37.4286 28.3883 37.4286H9.19833C7.43194 37.4286 6 35.8935 6 34V13.4286Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'VideocamIcon',
  props: {
    dataName: String,
  },
};
</script>
