/* eslint no-prototype-builtins:0 */

import {
  AJAX_CONTENT_TYPE_FORM_DATA,
  AJAX_CONTENT_TYPE_JSON,
  AJAX_RESPONSE_TYPE_DEFAULT,
  AJAX_RESPONSE_TYPE_JSON,
  HORIZONTAL,
  VERTICAL,
} from '@/modules/whiteboard.v1/assets/js/consts';
import { isArray } from '@room/ui.sdk/core/utils/misc';

const Util = {};

let IS_TOUCH_DEVICE = null;

// TODO
/**
 * @deprecated
 */
export { isArray };
/**
 * @deprecated
 * @type {(o: any) => boolean}
 */
Util.isArray = isArray; // TODO

/**
 *
 * @param obj
 * @returns {boolean}
 */
Util.isObject = function (obj) {
  return obj && typeof obj === 'object' && !this.isArray(obj);
};

/**
 * Extend the target object with properties from source
 * @param target
 * @param source
 * @returns {*}
 */
Util.deepExtend = function (target, source) {
  if (this.isObject(target) && this.isObject(source)) {
    for (let key in source) {
      // eslint-disable-next-line no-prototype-builtins
      if (source.hasOwnProperty(key)) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          this.deepExtend(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  }

  return target;
};

export function isDefined(variable) {
  return variable !== undefined;
}

export function extendOptionsWithDefaults(options, defaults) {
  options = options || {};
  let util = Util;
  for (let key in defaults) {
    // eslint-disable-next-line no-prototype-builtins
    if (defaults.hasOwnProperty(key)) {
      if (!isDefined(options[key])) options[key] = defaults[key];
      else if (util.isObject(options[key])) {
        if (defaults[key]) options[key] = this.extendOptionsWithDefaults(options[key], defaults[key]);
      } else if (util.isArray(options[key])) options[key] = options[key].concat(defaults[key]);
    }
  }

  return options;
}

// Test via a getter in the options object to see if the passive property is accessed
let supportsPassive = false;
try {
  let opts = Object.defineProperty({}, 'passive', {
    // eslint-disable-next-line getter-return
    get: function () {
      supportsPassive = true;
    },
  });
  window.addEventListener('test', null, opts);
} catch (e) {}

export function isFunction(value) {
  return typeof value === 'function';
}

let _util = {
  Dom: {
    SupportsPassiveEventHandler: supportsPassive,
    /**
     *
     * @param {Element|NodeList|HTMLCollection} el
     * @param newClassName
     * @returns {Element}
     */
    addClass: function (el, newClassName) {
      if (el instanceof Element) {
        let className = el.className;
        if (className.indexOf(newClassName) == -1) {
          className += ' ' + newClassName;
          el.className = className;
        }

        return el;
      } else if (el instanceof NodeList) {
        for (let node of el) this.addClass(node, newClassName);
      }
    },

    /**
     *
     * @param {Element|NodeList|HTMLCollection} el
     * @param className
     * @returns {Element}
     */
    removeClass: function (el, className) {
      if (el instanceof Element) {
        let oldClassName = el.className;
        if (oldClassName.indexOf(className) != -1) {
          oldClassName = oldClassName.replace(className.trim(), '');
          el.className = oldClassName.trim();
        }

        return el;
      } else {
        for (let node of el) this.removeClass(node, className);
      }
    },

    /**
     *
     * @param html
     * @returns {NodeList|Element}
     */
    htmlToDom: function (html) {
      let wrapMap = {
        option: [1, '<select multiple="multiple">', '</select>'],
        legend: [1, '<fieldset>', '</fieldset>'],
        area: [1, '<map>', '</map>'],
        param: [1, '<object>', '</object>'],
        thead: [1, '<table>', '</table>'],
        tr: [2, '<table><tbody>', '</tbody></table>'],
        col: [2, '<table><tbody></tbody><colgroup>', '</colgroup></table>'],
        td: [3, '<table><tbody><tr>', '</tr></tbody></table>'],
        body: [0, '', ''],
        _default: [1, '<div>', '</div>'],
      };
      wrapMap.optgroup = wrapMap.option;
      wrapMap.tbody = wrapMap.tfoot = wrapMap.colgroup = wrapMap.caption = wrapMap.thead;
      wrapMap.th = wrapMap.td;
      let match = /<\s*\w.*?>/g.exec(html);
      let element = document.createElement('div');
      if (match != null) {
        let tag = match[0].replace(/</g, '').replace(/>/g, '').split(' ')[0];
        if (tag.toLowerCase() === 'body') {
          let dom = document.implementation.createDocument('http://www.w3.org/1999/xhtml', 'html', null);
          let body = document.createElement('body');
          // keeping the attributes
          element.innerHTML = html.replace(/<body/g, '<div').replace(/<\/body>/g, '</div>');
          let attrs = element.firstChild.attributes;
          body.innerHTML = html;
          for (let i = 0; i < attrs.length; i++) {
            body.setAttribute(attrs[i].name, attrs[i].value);
          }

          return body;
        } else {
          let map = wrapMap[tag] || wrapMap._default;
          html = map[1] + html + map[2];
          element.innerHTML = html;
          // Descend through wrappers to the right content
          let j = map[0] + 1;
          while (j--) {
            element = element.lastChild;
          }
        }
      } else {
        element.innerHTML = html;
        element = element.lastChild;
      }

      return element;
    },

    /**
     *
     * @param str
     * @param isXhtml
     * @return {string}
     */
    nl2br: function (str, isXhtml) {
      let breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },

    placeCaretAtEnd: function (el) {
      el.focus();
      if (typeof window.getSelection != 'undefined' && typeof document.createRange != 'undefined') {
        let range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange != 'undefined') {
        let textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    },

    viewPortSize: function () {
      let w = window;
      let d = document;
      let e = d.documentElement;
      let g = d.getElementsByTagName('body')[0];
      let x = w.innerWidth || e.clientWidth || g.clientWidth;
      let y = w.innerHeight || e.clientHeight || g.clientHeight;

      return { width: x, height: y, orientation: x > y ? HORIZONTAL : VERTICAL };
    },
  },
  File: {
    ext: function (str) {
      const ext = str.split('.').pop();
      return ext === '' ? null : ext.toLowerCase();
    },

    dataURItoBlob: function (dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
      else byteString = decodeURI(dataURI.split(',')[1]);

      // separate out the mime component
      let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
  },
  // TODO use screenfull
  FullScreen: {
    _callback: null,
    enabled: function (i) {
      return (
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled ||
        (i && i.webkitDisplayingFullscreen)
      );
    },

    request: function (i) {
      if (i.requestFullscreen) {
        i.requestFullscreen();
      } else if (i.webkitRequestFullscreen) {
        i.webkitRequestFullscreen();
      } else if (i.mozRequestFullScreen) {
        i.mozRequestFullScreen();
      } else if (i.msRequestFullscreen) {
        i.msRequestFullscreen();
      }
    },

    element: function (i) {
      return (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement ||
        (i && i.webkitDisplayingFullscreen)
      );
    },

    exit: function (i) {
      // if (callback) {
      //   if (!this.element()) {
      //     callback();
      //     return;
      //   }
      //
      //   let exitHandler = () => {
      //     console.log('exitHandler');
      //     console.log(this.element());
      //     if (!this.element()) {
      //       this.removeOnChange(exitHandler);
      //       setTimeout(callback);
      //     }
      //   };
      //
      //   this.onChange(exitHandler);
      // }

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (i && i.webkitExitFullscreen) {
        i.webkitExitFullscreen();
      }
    },

    onChange: function (fn, i) {
      if (document.fullscreenEnabled) document.addEventListener('fullscreenchange', fn);
      else if (document.webkitFullscreenEnabled) document.addEventListener('webkitfullscreenchange', fn);
      else if (document.mozFullScreenEnabled) document.addEventListener('mozfullscreenchange', fn);
      else if (document.msFullscreenEnabled) document.addEventListener('MSFullscreenChange', fn);
      else if (i && i.webkitDisplayingFullscreen) i.addEventListener('webkitendfullscreen', fn);
    },

    removeOnChange: function (fn, i) {
      if (document.fullscreenEnabled) document.removeEventListener('fullscreenchange', fn);
      else if (document.webkitFullscreenEnabled) document.removeEventListener('webkitfullscreenchange', fn);
      else if (document.mozFullScreenEnabled) document.removeEventListener('mozfullscreenchange', fn);
      else if (document.msFullscreenEnabled) document.removeEventListener('MSFullscreenChange', fn);
      else if (i && i.webkitSupportsFullscreen) i.removeEventListener('webkitendfullscreen', fn);
    },
  },
  Icon: {
    /**
     * @deprecated switch to new icons import
     * @param name
     * @param className
     * @param attr
     * @return string
     */
    get: function getIcon(name, className, attr) {
      className = 'icon ' + (className || '');
      attr = extendOptionsWithDefaults(attr, {
        width: 24,
        height: 24,
        class: className,
      });

      let attrString = '';
      if (attr) {
        for (let key in attr) {
          // eslint-disable-next-line no-prototype-builtins
          if (attr.hasOwnProperty(key)) attrString += `${key}="${attr[key]}" `;
        }
      }

      return `<svg ${attrString}>
                    <use xlink:href="${import.meta.env.BASE_URL}board.v1/icons.svg#shape_${name}"></use>
                  </svg>`;
    },
  },
  DEBUG: false,

  log: function log(msg) {
    if (this.DEBUG && console && console.log) console.log(msg);
  },

  error: function error(msg) {
    if (this.DEBUG && console && console.error) console.error(msg);
  },

  /**
   * @deprecated use error instead
   * @param msg
   * @return {*}
   */
  err: function err(msg) {
    return this.error(msg);
  },

  /**
   *
   * @param text
   * @param flags
   * @returns {boolean}
   */
  isRtlLang: function isRtlLang(text, flags) {
    flags = flags || '';

    //arabic-farsi
    let pattern = new RegExp('[\u0600-\u06FF]', flags);
    if (pattern.test(text)) return true;

    //Hebrew 0590 — 05FF
    let pattern2 = new RegExp('[\u0590-\u05FF]', flags);
    if (pattern2.test(text)) return true;

    //0700 — 074F  	Syriac
    let pattern3 = new RegExp('[\u0700-\u074F]', flags);
    return pattern3.test(text);
  },

  isDefined,

  isString: function isString(variable) {
    return typeof variable == 'string';
  },
  isBoolean: function (variable) {
    return typeof variable === 'boolean';
  },

  clone: function clone(obj) {
    if (this.isObject(obj)) return JSON.parse(JSON.stringify(obj));

    return obj;
  },
  parseJson(json) {
    if (this.isString(json)) {
      try {
        json = JSON.parse(json);
      } catch (e) {
        json = null;
      }
    }

    return json;
  },
  isFunction,

  ajax: function ajax(url, params) {
    let xhr = new window.XMLHttpRequest();

    if (Util.isObject(url)) {
      params = url;
      url = undefined;
    }

    params = params || {};

    let data = params.data || {};
    url = url || params.url;
    let headers = params.headers;
    let method = params.method || 'GET';
    let responseType = params.responseType || AJAX_RESPONSE_TYPE_DEFAULT;
    let contentType = params.contentType || AJAX_CONTENT_TYPE_FORM_DATA;
    let async = isDefined(params.async) ? params.async : true;

    let validMethods = ['put', 'post', 'get', 'delete'];
    if (validMethods.indexOf(method.toLowerCase()) === -1) method = 'GET';

    // To account for sites that may require CORS
    if (params.withCredentials === true) {
      xhr.withCredentials = true;
    }

    xhr.open(method, url, async);
    xhr.responseType = responseType;

    if (headers) {
      for (let headerKey in headers) {
        // eslint-disable-next-line no-prototype-builtins
        if (headers.hasOwnProperty(headerKey)) {
          xhr.setRequestHeader(headerKey, headers[headerKey]);
        }
      }
    }

    if (params.onUploadStart && isFunction(params.onUploadStart)) {
      // Triggered when upload starts:
      if ('onloadstart' in xhr) {
        xhr.upload.onloadstart = () => {
          params.onUploadStart(xhr);
        };
      }
    }

    if (params.onProgress && isFunction(params.onProgress)) {
      // Triggered many times during upload:
      if ('onprogress' in xhr) {
        xhr.upload.onprogress = (event) => {
          if (!event.lengthComputable) {
            return;
          }

          params.onProgress(event, xhr);
        };
      }
    }

    if (params.onSuccess && isFunction(params.onSuccess)) {
      let __successCallback = () => {
        let response = xhr.response || xhr.responseText;
        if (responseType === AJAX_RESPONSE_TYPE_JSON && !Util.isObject(response)) {
          try {
            response = JSON.parse(response);
          } catch (e) {
            response = null;
          }
        }

        params.onSuccess(response, xhr);
      };

      // Triggered when request is completed:
      if ('onload' in xhr) {
        xhr.onload = __successCallback;
      } else {
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) __successCallback();
        };
      }
    }

    if (params.onError && isFunction(params.onError)) {
      // Triggered when request fails:
      xhr.onerror = (e) => {
        params.onError(e, xhr.status, xhr);
      };
    }

    if (params.onAbort && isFunction(params.onAbort)) {
      xhr.onabort = (e) => {
        params.onAbort(e, xhr.status, xhr);
      };
    }

    if (params.onComplete && isFunction(params.onComplete)) {
      // triggered for all three load-ending conditions (abort, load, or error)
      xhr.onloadend = (e) => {
        params.onComplete(e, xhr.status, xhr);
      };
    }

    let sendData = null;
    if (data) {
      if (contentType == AJAX_CONTENT_TYPE_FORM_DATA) {
        sendData = new window.FormData();
        // Append additional data if provided:

        for (let prop in data) {
          // eslint-disable-next-line no-prototype-builtins
          if (data.hasOwnProperty(prop)) {
            sendData.append(prop, data[prop]);
          }
        }
      } else {
        sendData = data;
        if (Util.isObject(sendData)) sendData = JSON.stringify(sendData);

        if (contentType == AJAX_CONTENT_TYPE_JSON) {
          xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        }
      }
    }

    if (params.onBeforeStart && isFunction(params.onBeforeStart)) params.onBeforeStart(xhr);

    // Initiate upload:
    xhr.send(sendData);

    return xhr;
  },

  extendOptionsWithDefaults,

  /**
   *
   * @param {boolean} [milisecond]
   * @return {number}
   */
  getTimestamp: function getTimestamp(milisecond) {
    let time = Date.now();
    if (milisecond) return time;

    return Math.floor(time / 1000);
  },

  random: function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },

  hexToRgba: function hexToRgbA(hex, opacity) {
    opacity = opacity || 1;
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }

      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }

    return hex;
  },

  parseInt: function (value, defaultValue) {
    try {
      value = parseInt(value);
    } catch (e) {
      value = defaultValue ? defaultValue : 0;
    }

    if (isNaN(value)) value = defaultValue ? defaultValue : 0;

    return value;
  },

  scale: function (srcWidth, srcHeight, maxWidth, maxHeight) {
    let resizeWidth = srcWidth;
    let resizeHeight = srcHeight;

    let aspect = resizeWidth / resizeHeight;
    let scaleX = maxWidth / srcWidth;
    let scaleY = maxHeight / srcHeight;
    let scale = Math.min(scaleX, scaleY);

    resizeWidth *= scale;
    resizeHeight *= scale;

    if (resizeWidth > maxWidth) {
      resizeWidth = maxWidth;
      resizeHeight = resizeWidth / aspect;
    }

    if (resizeHeight > maxHeight) {
      aspect = resizeWidth / resizeHeight;
      resizeHeight = maxHeight;
      resizeWidth = resizeHeight * aspect;
    }

    return {
      width: resizeWidth,
      height: resizeHeight,
    };
  },

  scaleDown: function (srcWidth, srcHeight, maxWidth, maxHeight) {
    let resizeWidth = srcWidth;
    let resizeHeight = srcHeight;

    let aspect = resizeWidth / resizeHeight;
    let scaleX = maxWidth / srcWidth;
    let scaleY = maxHeight / srcHeight;
    let scale = Math.min(scaleX, scaleY);

    if (resizeWidth > maxWidth || resizeHeight > maxHeight) {
      resizeWidth *= scale;
      resizeHeight *= scale;
    }

    if (resizeWidth > maxWidth) {
      resizeWidth = maxWidth;
      resizeHeight = resizeWidth / aspect;
    }

    if (resizeHeight > maxHeight) {
      aspect = resizeWidth / resizeHeight;
      resizeHeight = maxHeight;
      resizeWidth = resizeHeight * aspect;
    }

    return {
      width: resizeWidth,
      height: resizeHeight,
      scale: scale,
    };
  },

  isEmptyObject: function (obj) {
    return this.isObject(obj) && Object.keys(obj).length === 0;
  },

  touchScroll: function (el, direction) {
    let parent = el.parent();
    let startPos = null;
    let thisSize = 0;
    let parentSize = 0;
    let moved = false;
    let prop = direction === VERTICAL ? 'top' : 'left';
    let button = null;

    el.attr('data-' + prop, 0);

    parent.on('touchstart', (e) => {
      button = moved = false;
      startPos = direction === VERTICAL ? e.touches[0].clientY : e.touches[0].clientX;
      thisSize = direction === VERTICAL ? el.outerHeight() : el.outerWidth();
      parentSize = direction === VERTICAL ? parent.innerHeight() : parent.innerWidth();
    });
    parent.on(
      'touchmove',
      (e) => {
        if (parentSize < thisSize && startPos !== null) {
          let newPos = direction === VERTICAL ? e.touches[0].clientY : e.touches[0].clientX;
          let d = startPos - newPos;

          if (Math.abs(d) > 2) {
            moved = true;
            if (e?.cancelable) e.preventDefault();
            e.stopPropagation();
          } else return;

          let propValue = parseInt(el.attr('data-' + prop));
          let newPropValue = propValue - d;

          if (newPropValue > 0) newPropValue = 0;

          if (newPropValue < parentSize - thisSize) newPropValue = parentSize - thisSize;

          el.attr('data-' + prop, newPropValue);
          el.style(prop, newPropValue + 'px');
          startPos = newPos;
        }
      },
      { passive: false },
    );
    parent.on(
      'touchend',
      (e) => {
        if (moved) {
          if (e?.cancelable) e.preventDefault();
          e.stopPropagation();
        }

        startPos = null;
      },
      { passive: false },
    );
    // TODO cleanup
    window.addEventListener('resize', (e) => {
      el.attr('data-' + prop, 0);
      el.style(prop, 0 + 'px');
      startPos = 0;
    });
  },

  findInArray: function (array, property, value) {
    for (let i = 0, l = array.length; i < l; i++) {
      let obj = array[i];
      if (obj[property] === value) return obj;
    }

    return null;
  },
  removeFromArray: function (array, value) {
    var idx = array.indexOf(value);
    if (idx !== -1) {
      array.splice(idx, 1);
    }
    return array;
  },
  isRtl: function () {
    return document.body.classList.contains('rtl');
  },
  isTouchDevice: function () {
    if (IS_TOUCH_DEVICE != null) return IS_TOUCH_DEVICE;

    let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    let mq = function (query) {
      return window.matchMedia(query).matches;
    };

    // eslint-disable-next-line
    if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
      IS_TOUCH_DEVICE = true;
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    let query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    IS_TOUCH_DEVICE = mq(query);

    return IS_TOUCH_DEVICE;
  },
};

Util.deepExtend(Util, _util);
String.prototype.hashCode = function () {
  let hash = 0;
  let i;
  let chr;
  let len;
  if (this.length === 0) return hash;
  for (i = 0, len = this.length; i < len; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};

if (!String.prototype.trim) {
  String.prototype.trim = function trim(x) {
    return x.replace(/^\s+|\s+$/gm, '');
  };
}
if (!Array.prototype.contains) {
  Array.prototype.contains = function (needle) {
    return this.indexOf(needle) !== -1;
  };
}

export default Util;
