<template>
  <!-- eslint-disable  -->
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.08619 5.81869C4.15299 3.33026 6.65254 1.70651 9.42742 1.69931C12.4181 1.68401 15.0773 3.54488 16.0095 6.30535C16.9418 9.06583 15.9341 12.0953 13.5153 13.8039C11.0965 15.5125 7.81915 15.51 5.40318 13.7976L3.24894 15.8903C3.02991 16.1028 2.67509 16.1028 2.45606 15.8903C2.2373 15.6775 2.2373 15.3328 2.45606 15.12L4.54298 13.0928C2.59362 11.1744 2.01939 8.30711 3.08619 5.81869ZM4.08638 10.5415C4.98209 12.6383 7.09047 14.004 9.42742 14.001V13.972C12.5991 13.9681 15.174 11.4798 15.1945 8.39878C15.1975 6.12862 13.7917 4.08049 11.6332 3.21038C9.47474 2.34026 6.98919 2.81973 5.33671 4.42498C3.68424 6.03023 3.19067 8.44475 4.08638 10.5415Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'SearchIcon',
};
</script>
