<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41 14.5556C41 10.3827 37.6173 7 33.4444 7H14.5556C10.3827 7 7 10.3827 7 14.5556V33.4444C7 37.6173 10.3827 41 14.5556 41H33.4444C37.6173 41 41 37.6173 41 33.4444V14.5556ZM33.4444 10.7778H14.5556C12.4691 10.7778 10.7778 12.4691 10.7778 14.5556V33.4444C10.7778 35.5309 12.4691 37.2222 14.5556 37.2222H33.4444C35.5309 37.2222 37.2222 35.5309 37.2222 33.4444V14.5556C37.2222 12.4691 35.5309 10.7778 33.4444 10.7778Z"
      fill="#23B9D7"
    />
    <path
      d="M20.2425 33.0094C19.7944 33.009 19.3601 32.8381 19.0136 32.5255L14.5547 28.7234C11.9747 26.1903 14.9727 23.6908 17.0126 25.422L19.4959 27.4868C19.8739 27.8011 20.4299 27.7728 20.7741 27.4218L30.6111 17.3903C31.5713 16.4299 33.0383 16.2654 33.7998 16.966C34.5613 17.6667 34.5976 19.1588 33.7998 19.9564L21.5621 32.4402C21.2046 32.8086 20.7324 33.0123 20.2425 33.0094Z"
      fill="#23B9D7"
    />
  </svg>
</template>
<script>
export default {
  name: 'CheckBoxOutlineBlankIcon',
};
</script>
