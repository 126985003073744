<template>
  <!-- eslint-disable  -->
  <svg fill="#A9A8A8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="7" height="7" rx="1.5" stroke="#A9A8A8" />
  </svg>
</template>
<script>
export default {
  name: 'SquareIcon',
};
</script>
