<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.7143 34.1055C30.7143 33.6205 30.5323 33.1354 30.1669 32.7653L21.5143 24L30.1669 15.2347C30.8963 14.4945 30.8963 13.2945 30.1669 12.5542C29.4363 11.8153 28.2519 11.8153 27.5213 12.5542L17.547 22.6598C16.8177 23.4 16.8177 24.6 17.547 25.3402L27.5213 35.4458C28.2519 36.1847 29.4363 36.1847 30.1669 35.4458C30.5323 35.0757 30.7143 34.5906 30.7143 34.1055Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'KeyboardArrowLeftIcon',
};
</script>
