<template>
  <!-- eslint-disable  -->

<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M32.8926 8H15.105C11.0723 8.13914 7.9039 11.5002 8.00279 15.5341V37.4967C7.9615 38.2346 8.38165 38.921 9.05763 39.2198C9.73361 39.5187 10.524 39.3675 11.042 38.8404L14.8331 34.7934C15.5554 34.0358 16.5537 33.603 17.6004 33.5937H32.8287C34.7947 33.5437 36.6589 32.7088 38.0053 31.2753C39.3517 29.8417 40.0682 27.9289 39.9949 25.9636V15.5341C40.0938 11.5002 36.9254 8.13914 32.8926 8ZM17.9975 16.3502H25.9955C26.6581 16.3502 27.1952 16.8873 27.1952 17.5499C27.1952 18.2125 26.6581 18.7496 25.9955 18.7496H17.9975C17.3349 18.7496 16.7978 18.2125 16.7978 17.5499C16.7978 16.8873 17.3349 16.3502 17.9975 16.3502ZM17.9975 25.1476H29.9945C30.6571 25.1476 31.1942 24.6104 31.1942 23.9479C31.1942 23.2853 30.6571 22.7482 29.9945 22.7482H17.9975C17.3349 22.7482 16.7978 23.2853 16.7978 23.9479C16.7978 24.6104 17.3349 25.1476 17.9975 25.1476Z"/>
</svg>

</template>
<script>
export default {
  name: 'ChatIcon',
};
</script>
