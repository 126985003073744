<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5113 10.784C21.5113 10.1564 20.7626 9.81307 20.2686 10.2141L4.45612 23.0511C3.84856 23.5444 3.84785 24.4553 4.45464 24.9494L20.2677 37.8269C20.7615 38.229 21.5113 37.8859 21.5113 37.2577V30.3053C21.5113 29.8961 21.8539 29.5656 22.2724 29.5531C34.0853 29.199 42.0429 21.5286 43.369 10.7834C43.4648 10.0073 42.3805 9.70867 41.922 10.3493C36.7489 17.5768 28.5508 18.3094 22.2685 18.4273C21.8499 18.4352 21.5113 18.1027 21.5113 17.6935V10.784Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ReplyIcon',
};
</script>
