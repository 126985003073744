<template>
  <!-- eslint-disable  -->
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.75" y="0.75" width="14.5" height="14.5" rx="3.25" stroke="#23B9D7" stroke-width="1.5" />
    <path
      d="M6.59923 11.5554C6.39525 11.5553 6.1976 11.4752 6.03985 11.3287L4.01034 9.54682C2.83601 8.35964 4.20061 7.18824 5.1291 7.99959L6.2594 8.96725C6.43146 9.11455 6.68449 9.10131 6.84116 8.93681L10.727 4.84481C11.1641 4.39473 11.8318 4.31763 12.1784 4.64599C12.525 4.97435 12.5415 5.67362 12.1784 6.04746L7.19983 11.2887C7.03713 11.4613 6.82219 11.5568 6.59923 11.5554Z"
      fill="#23B9D7"
    />
  </svg>
</template>
<script>
export default {
  name: 'DoneIconSquare',
};
</script>
