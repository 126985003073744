<template>
  <!-- eslint-disable  -->
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 8.86783C15.2216 8.26091 13.7785 8.09362 12.3952 8.39197C11.0119 8.69033 9.76596 9.43761 8.85141 10.5175C7.93686 11.5974 7.40494 12.9493 7.33844 14.3629C7.27195 15.7765 7.67461 17.1724 8.48375 18.3333H0.91025C0.669513 18.3317 0.439099 18.2353 0.268783 18.0652C0.0984664 17.8951 0.0019201 17.6647 0 17.424V0.909333C0 0.407 0.407917 0 0.91025 0H11.9167L16.5 4.58333V8.86783Z"
      fill="#E0E0E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.585 19.17C16.1172 19.17 18.17 17.1172 18.17 14.585C18.17 12.0528 16.1172 10 13.585 10C11.0528 10 9 12.0528 9 14.585C9 17.1172 11.0528 19.17 13.585 19.17ZM11 17H12.3284L15.9083 13.42C15.967 13.3613 16 13.2817 16 13.1987C16 13.1157 15.967 13.0361 15.9083 12.9773L15.0227 12.0917C14.9639 12.033 14.8843 12 14.8013 12C14.7183 12 14.6387 12.033 14.58 12.0917L11 15.6716V17Z"
      fill="#E0E0E5"
    />
  </svg>
</template>
<script>
export default {
  name: 'EditPoolIcon',
};
</script>
