<template>
  <!-- eslint-disable  -->
  
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M31.1125 11.0045C30.7933 11.0045 30.5289 11.1797 30.3647 11.4286L30.2462 11.6959L20.7897 33.1841C20.4979 33.7095 20.3247 34.3087 20.3247 34.9632C20.3247 37.0005 21.9661 38.6598 23.9814 38.6598C25.7414 38.6598 27.2004 37.4061 27.5561 35.7376L27.5652 35.6731L32.0244 11.9264C32.0244 11.4194 31.614 11.0045 31.1125 11.0045ZM5.82249 16.6384C4.73627 17.5552 4.74275 19.1931 5.74239 20.2036C6.75698 21.2292 8.40576 21.2157 9.52849 20.3097C14.5174 16.2837 20.7716 14.6521 26.7992 15.3833L28.9695 10.4422C20.9471 9.00661 12.4256 11.0651 5.82249 16.6384ZM38.4701 20.3097C39.5935 21.2158 41.2426 21.229 42.2575 20.203C43.257 19.1926 43.2642 17.5552 42.1769 16.6401C39.6467 14.5105 36.8305 12.9056 33.8756 11.7973L32.8998 16.9873C34.8633 17.8104 36.7416 18.9156 38.4701 20.3097ZM31.094 27.7835C32.2965 28.5814 33.9473 28.6037 34.9622 27.5777C35.9617 26.5673 35.9711 24.9229 34.8353 24.0686C33.9045 23.3685 32.9221 22.7724 31.9058 22.2695L30.8936 27.653C30.9607 27.6959 31.0275 27.7394 31.094 27.7835ZM13.1625 24.064C12.0295 24.9225 12.0384 26.5682 13.0381 27.5789C14.0525 28.6042 15.6976 28.5764 16.898 27.7768C18.5324 26.6881 20.3572 26.02 22.2305 25.7632L24.5741 20.4442C20.5616 20.3213 16.5238 21.5172 13.1625 24.064Z"/>
  </svg>

</template>
<script>
export default {
  name: 'NetworkCheckIcon',
};
</script>
