<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <rect x="17" y="16.9999" width="14" height="14" rx="1" fill="#CF7071" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24ZM24 40.4091C33.0625 40.4091 40.4091 33.0625 40.4091 24C40.4091 14.9375 33.0625 7.59091 24 7.59091C14.9375 7.59091 7.59091 14.9375 7.59091 24C7.59091 33.0625 14.9375 40.4091 24 40.4091Z"
      fill="#43566D"
    />
  </svg>
</template>
<script>
export default {
  name: 'StopCircleIcon',
};
</script>
