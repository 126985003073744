<template>
  <!-- eslint-disable  -->
  <svg viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.95293 11.3158C9.79092 12.2281 11.2091 12.2281 12.0471 11.3158L20.0951 2.55438C20.9847 1.58588 20.3123 0 19.0121 0H1.98789C0.687649 0 0.0153032 1.58588 0.904943 2.55439L8.95293 11.3158Z"
      fill="currentColor"
      style="fill: currentColor; fill: color(display-p3 0.8784 0.8784 0.8784); fill-opacity: 1"
    />
  </svg>
</template>
<script>
export default {
  name: 'ArrowDropDownIcon',
};
</script>
