<template>
  <!-- eslint-disable  -->

<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.5065 24.4292H18.6447C22.4616 24.4292 25.5558 21.335 25.5558 17.5182V14.8055C25.5512 13.2553 24.9286 11.7708 23.826 10.6811C22.7233 9.5914 21.2316 8.98642 19.6813 9.00023H17.4007C14.1984 9.00972 11.6049 11.6033 11.5954 14.8055V17.5182C11.5954 21.335 14.6896 24.4292 18.5065 24.4292Z"/>
  <path d="M33.3342 23.1362C36.1969 23.1362 38.5176 20.8155 38.5176 17.9528V15.9659C38.5176 13.5747 36.5893 11.6309 34.1981 11.6119H32.4704C30.0792 11.6309 28.1509 13.5747 28.1509 15.9659V17.9528C28.1509 20.8155 30.4716 23.1362 33.3342 23.1362Z"/>
  <path d="M37.6534 25.7243H27.114C25.826 25.727 24.6254 26.376 23.9176 27.4521H11.3852C4.2072 27.4573 4.20066 39.8575 11.3913 39.8575H25.6972C28.449 39.8575 30.6041 37.7973 30.6041 35.348H37.999C39.219 35.3119 40.3568 34.7242 41.0924 33.7501C43.1114 31.0765 41.674 25.7243 37.6534 25.7243Z"/>
</svg>


</template>
<script>
export default {
  name: 'GroupIcon',
};
</script>
