<template>
  <!-- eslint-disable  -->
<svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 48 48">
  <path
     d="M 42,26 H 6 v -4 h 36 z" />
</svg>

</template>
<script>
export default {
  name: 'HLineIcon',
};
</script>
