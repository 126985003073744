<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.3904 14.6096C34.4526 13.5474 36.1976 13.5369 37.0647 14.7635C38.3539 16.5869 39.253 18.6687 39.6926 20.8786C40.3099 23.9822 39.9931 27.1993 38.7821 30.1229C37.5711 33.0466 35.5203 35.5454 32.8891 37.3035C30.2579 39.0616 27.1645 40 24 40C20.8355 40 17.7421 39.0616 15.1109 37.3035C12.4797 35.5454 10.4289 33.0466 9.21793 30.1229C8.00693 27.1993 7.69008 23.9822 8.30744 20.8786C8.74701 18.6687 9.64614 16.5869 10.9353 14.7635C11.8025 13.5369 13.5474 13.5474 14.6096 14.6096C15.6719 15.6718 15.6337 17.3837 14.8771 18.6814C14.2913 19.6864 13.8723 20.7867 13.6429 21.9398C13.2355 23.9883 13.4446 26.1115 14.2438 28.0411C15.0431 29.9707 16.3966 31.62 18.1332 32.7803C19.8698 33.9407 21.9114 34.56 24 34.56C26.0886 34.56 28.1302 33.9407 29.8668 32.7803C31.6034 31.62 32.9569 29.9707 33.7562 28.0411C34.5554 26.1115 34.7646 23.9883 34.3571 21.9398C34.1277 20.7867 33.7088 19.6864 33.1229 18.6814C32.3663 17.3837 32.3282 15.6718 33.3904 14.6096Z"/>
  <path d="M20.8899 11.1111C20.8899 9.3929 22.2828 8 24.0011 8C25.7193 8 27.1122 9.39289 27.1122 11.1111V22.0741C27.1122 23.7923 25.7193 25.1852 24.0011 25.1852C22.2828 25.1852 20.8899 23.7923 20.8899 22.0741V11.1111Z"/>
  </svg>

</template>
<script>
export default {
  name: 'PowerSettingsNewIcon',
};
</script>
