<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M35.5736 12.4023C35.2809 12.7548 34.7076 12.7548 34.4149 12.4023L30.8349 8.09122C30.4648 7.64553 30.8075 7 31.4142 7L38.5743 7C39.181 7 39.5237 7.64553 39.1536 8.09122L35.5736 12.4023Z"/>
  <path d="M23.7393 34.5897C23.6264 34.2355 23.2932 33.9945 22.9165 33.9945H12.5518C12.1741 33.9945 11.8404 34.2366 11.7282 34.592L9.8939 40.4025C9.78172 40.7579 9.44799 41 9.07034 41H3.8632C3.2699 41 2.85376 40.4234 3.04847 39.871L14.4357 7.57104C14.5562 7.22917 14.8832 7 15.2504 7H20.1728C20.5395 7 20.8661 7.2285 20.987 7.56966L32.4373 39.8697C32.6332 40.4222 32.217 41 31.6231 41H26.4162C26.0395 41 25.7064 40.759 25.5934 40.4048L23.7393 34.5897ZM14.0547 27.2184C13.8826 27.7653 14.2975 28.3201 14.8785 28.3201H20.584C21.1662 28.3201 21.5811 27.7633 21.407 27.2159L18.5386 18.1958C18.2856 17.4 17.1423 17.4018 16.8918 18.1983L14.0547 27.2184Z"/>
  <path d="M39.2865 36.6234C39.1997 36.3611 38.9515 36.1837 38.6716 36.1837H31.3026C31.022 36.1837 30.7734 36.362 30.687 36.6251L30.5886 36.9254C30.4028 37.4917 29.6008 37.5205 29.3737 36.9691L27.5169 32.4608C27.4569 32.315 27.4537 32.1525 27.5079 32.0046L32.6206 18.0461C32.7132 17.7934 32.9565 17.625 33.229 17.625H36.7129C36.9851 17.625 37.2281 17.7929 37.321 18.045L45.4606 40.145C45.6137 40.5607 45.3013 41 44.8526 41H41.2057C40.9258 41 40.6776 40.8226 40.5907 40.5603L39.2865 36.6234ZM32.3835 31.4496C32.249 31.8612 32.5605 32.2825 32.9993 32.2825H36.9704C37.4101 32.2825 37.7216 31.8596 37.5855 31.4477L35.589 25.4049C35.3948 24.8171 34.5505 24.8184 34.3582 25.4068L32.3835 31.4496Z"/>
  </svg>
</template>
<script>
export default {
  name: 'FontSizeIcon',
};
</script>
