<template>
  <!-- eslint-disable  -->

<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.7753 37.3929C15.5125 37.3929 15.2994 37.6081 15.2994 37.8737V41.5182C15.2994 41.9259 14.8287 42.1486 14.5187 41.8876L7.17125 35.7015C6.94292 35.5092 6.94292 35.1549 7.17125 34.9627L14.5187 28.7765C14.8287 28.5155 15.2994 28.7382 15.2994 29.1459V32.7904C15.2994 33.056 15.5125 33.2713 15.7753 33.2713L40.5241 33.2713C40.7869 33.2713 41 33.4865 41 33.7521V36.912C41 37.1776 40.7869 37.3929 40.5241 37.3929H15.7753Z"/>
  <path d="M18.2807 22.1945C18.3344 22.1945 18.3779 22.2382 18.3779 22.2921V29.6367C18.3779 29.8397 18.5418 30.0044 18.744 30.0044H22.6851C22.8873 30.0044 23.0512 29.8397 23.0512 29.6367L23.0513 11.2764L23.0512 6H18.9276C17.0608 6 15.4217 6.34347 14.0103 7.0304C12.5874 7.71733 11.4947 8.69621 10.7321 9.96704C9.96944 11.2264 9.58812 12.6633 9.58812 14.2775C9.58812 16.7276 10.4247 18.6625 12.098 20.0821C13.7599 21.4903 15.3326 22.1945 18.2807 22.1945Z"/>
  <path d="M31.4552 30.0044C31.6574 30.0044 31.8213 29.8397 31.8213 29.6367L31.8214 10.5408C31.8214 10.3377 31.9853 10.1731 32.1875 10.1731H37.7783C37.9805 10.1731 38.1444 10.0085 38.1444 9.80541L38.1444 6.36771C38.1444 6.16463 37.9804 6 37.7782 6H23.0512L23.0513 11.2764V10.654C23.0513 10.3884 23.2644 10.1731 23.5272 10.1731H26.583C26.7852 10.1731 26.9491 10.3377 26.9491 10.5408L26.949 29.6367C26.949 29.8397 27.113 30.0044 27.3152 30.0044H31.4552Z"/>
</svg>

</template>
<script>
export default {
  name: 'RtlIcon',
};
</script>
