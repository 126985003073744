<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7426 37.8679C22.6224 38.7342 25.709 38.7006 28.5682 37.7718C31.4274 36.843 33.9155 35.0657 35.6827 32.6899C37.2734 30.5514 38.208 28.0248 38.3883 25.4028C38.4216 24.9195 38.824 24.5283 39.3218 24.5306L41.1244 24.5388C41.6219 24.5411 42.0258 24.9355 41.9987 25.4188C41.8443 28.1787 41.0208 30.8684 39.5902 33.2658C38.0085 35.9163 35.7401 38.1169 33.0113 39.6481C25.0538 44.1184 15.0453 42.0123 9.63996 35.059L7.76375 36.1129C7.16304 36.4503 6.41215 36.0285 6.41215 35.3537L6.41215 27.7617C6.41215 27.0869 7.16304 26.6651 7.76375 27.0025L14.5217 30.7985C15.1225 31.1359 15.1225 31.9795 14.5217 32.3169L12.7917 33.2887C14.5883 35.4494 17.0069 37.045 19.7426 37.8679Z"
    />
    <path
      d="M28.2574 10.1321C25.3776 9.2658 22.291 9.29943 19.4318 10.2282C16.5726 11.157 14.0845 12.9343 12.3173 15.3101C10.7266 17.4486 9.79205 19.9752 9.61167 22.5972C9.57843 23.0805 9.17599 23.4717 8.67822 23.4694L6.87559 23.4612C6.37807 23.4589 5.97425 23.0645 6.00128 22.5812C6.15567 19.8213 6.97919 17.1316 8.40984 14.7342C9.99149 12.0837 12.2599 9.88308 14.9887 8.35188C22.9447 3.88243 32.9511 5.98694 38.3571 12.9372L40.3595 11.8125C40.9602 11.4751 41.7111 11.8968 41.7111 12.5717V20.1637C41.7111 20.8385 40.9602 21.2603 40.3595 20.9228L33.6015 17.1269C33.0008 16.7894 33.0008 15.9459 33.6015 15.6085L35.2052 14.7076C33.4089 12.5488 30.9916 10.9545 28.2574 10.1321Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'SyncIcon',
};
</script>
