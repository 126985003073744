<template>
  <!-- eslint-disable  -->
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :data-name="dataName ? dataName : 'share-screen-icon'"
  >
    <path
      d="M13.3613 36.9677C13.3613 35.938 14.1961 35.1032 15.2258 35.1032H32.7742C33.8039 35.1032 34.6387 35.938 34.6387 36.9677C34.6387 37.9975 33.8039 38.8323 32.7742 38.8323H15.2258C14.1961 38.8323 13.3613 37.9975 13.3613 36.9677Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.729 9C8.66954 9 7 10.6695 7 12.729V29.5097C7 31.5692 8.66955 33.2387 10.729 33.2387H37.271C39.3305 33.2387 41 31.5692 41 29.5097V12.729C41 10.6695 39.3305 9 37.271 9H10.729ZM25.75 25.9484V19.7397L29.0348 22.4812C29.3741 22.8275 29.8188 23 30.2634 23C30.708 23 31.1527 22.8275 31.492 22.4812C32.1693 21.7886 32.1693 20.6659 31.492 19.9733L25.2286 14.5185C24.55 13.8272 23.45 13.8272 22.7714 14.5185L16.508 19.9733C15.8307 20.6659 15.8307 21.7886 16.508 22.4812C17.1866 23.1726 18.2866 23.1726 18.9652 22.4812L22.25 19.7397L22.25 25.9484C22.25 27.0815 23.0335 28 24 28C24.9665 28 25.75 27.0815 25.75 25.9484Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'LaptopIcon',
  props: {
    dataName: String,
  },
};
</script>
