<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24ZM24 40.4091C33.0625 40.4091 40.4091 33.0625 40.4091 24C40.4091 14.9375 33.0625 7.59091 24 7.59091C14.9375 7.59091 7.59091 14.9375 7.59091 24C7.59091 33.0625 14.9375 40.4091 24 40.4091Z"
    />
    <path
      d="M31.5099 25.7433L20.6848 32.7164C20.4158 32.8982 20.1086 32.9961 19.7948 33C19.3188 33 18.8623 32.7873 18.5257 32.4088C18.1891 32.0302 18 31.5168 18 30.9815V17.0185C18 15.9037 18.8036 15 19.7948 15C20.1086 15.0039 20.4158 15.1018 20.6848 15.2836L31.5099 22.2567C32.0656 22.6175 32.4057 23.2867 32.3999 24.0083C32.4034 24.7249 32.0633 25.388 31.5099 25.7433Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'PlayCircleIcon',
};
</script>
