<template>
  <!-- eslint-disable  -->
  <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.36383 0.000463836L9 2.63617V6.36383L6.36383 9H2.63617L0 6.36383V2.63617L2.63617 0L6.36383 0.000463836ZM3.94336 5.79884V6.72657H4.8711V5.79884H3.94336ZM3.94336 2.08788V4.8711H4.8711V2.08788H3.94336Z" fill="#FF8A00"/>
</svg>
</template>
<script>
export default {
  name: 'ErrorWarningIcon',
};
</script>
