<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 20.5618C23.6328 20.5568 23.2791 20.7005 23.0194 20.9602C22.7597 21.2199 22.616 21.5735 22.621 21.9408V31.3544C22.621 32.116 23.2384 32.7334 24 32.7334C24.7616 32.7334 25.379 32.116 25.379 31.3544V21.9775C25.3889 21.6055 25.248 21.2452 24.9883 20.9786C24.7286 20.712 24.3722 20.5617 24 20.5618Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 6C14.0631 6.01013 6.01013 14.0631 6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24C41.9899 14.0631 33.9369 6.01013 24 6ZM24 39.2421C15.582 39.2421 8.75792 32.418 8.75792 24C8.75792 15.582 15.582 8.75792 24 8.75792C32.418 8.75792 39.2421 15.582 39.2421 24C39.232 32.4138 32.4138 39.232 24 39.2421Z"
    />
    <path
      d="M24.0006 15.5C23.1322 15.4893 22.5006 16.233 22.5006 16.9999C22.5006 17.7669 23.1676 18.489 24.0006 18.4999C24.8335 18.5108 25.5248 17.8523 25.5 16.99C25.4886 16.1716 24.8689 15.5107 24.0006 15.5Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'InfoOutlineIcon',
};
</script>
