<template>
  <!-- eslint-disable  -->
  <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 8.86783C15.2216 8.26091 13.7785 8.09362 12.3952 8.39197C11.0119 8.69033 9.76596 9.43761 8.85141 10.5175C7.93686 11.5974 7.40494 12.9493 7.33844 14.3629C7.27195 15.7765 7.67461 17.1724 8.48375 18.3333H0.91025C0.669513 18.3317 0.439099 18.2353 0.268783 18.0652C0.0984664 17.8951 0.0019201 17.6647 0 17.424V0.909333C0 0.407 0.407917 0 0.91025 0H11.9167L16.5 4.58333V8.86783Z"
      fill="#E0E0E5"
    />
    <g clip-path="url(#clip0_139_432)">
      <path
        d="M13.8333 10.166C11.3033 10.166 9.25 12.2193 9.25 14.7493C9.25 17.2793 11.3033 19.3327 13.8333 19.3327C16.3633 19.3327 18.4167 17.2793 18.4167 14.7493C18.4167 12.2193 16.3633 10.166 13.8333 10.166ZM12.9167 17.041L10.625 14.7493L11.2713 14.1031L12.9167 15.7439L16.3954 12.2652L17.0417 12.916L12.9167 17.041Z"
        fill="#E0E0E5"
      />
    </g>
    <defs>
      <clipPath id="clip0_139_432">
        <rect width="11" height="11" transform="translate(9.25 9.16602)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'PoolIcon',
};
</script>
