<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 7H39C40.6569 7 42 8.34315 42 10V19C42 20.6569 40.6569 22 39 22H30C28.3431 22 27 20.6569 27 19V10C27 8.34315 28.3431 7 30 7ZM39 17.3235V11.6765C39 10.6552 38.3448 10 37.3235 10L31.6765 10C30.6409 10 30 10.6409 30 11.6765V17.3235C30 18.3448 30.6552 19 31.6765 19H37.3235C38.3448 19 39 18.3448 39 17.3235Z"
    />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 17C22.3284 17 23 17.6716 23 18.5C23 19.3284 22.3284 20 21.5 20H7.5C6.67157 20 6 19.3284 6 18.5C6 17.6716 6.67157 17 7.5 17H21.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 36C22.3284 36 23 36.6716 23 37.5C23 38.3284 22.3284 39 21.5 39H7.5C6.67157 39 6 38.3284 6 37.5C6 36.6716 6.67157 36 7.5 36H21.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 11C22.3284 11 23 11.6716 23 12.5C23 13.3284 22.3284 14 21.5 14H10.5C9.67157 14 9 13.3284 9 12.5C9 11.6716 9.67157 11 10.5 11H21.5Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5 30C22.3284 30 23 30.6716 23 31.5C23 32.3284 22.3284 33 21.5 33H10.5C9.67157 33 9 32.3284 9 31.5C9 30.6716 9.67157 30 10.5 30H21.5Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 26H39C40.6569 26 42 27.3431 42 29V38C42 39.6569 40.6569 41 39 41H30C28.3431 41 27 39.6569 27 38V29C27 27.3431 28.3431 26 30 26ZM39 36.3235V30.6765C39 29.6552 38.3448 29 37.3235 29H31.6765C30.6409 29 30 29.6409 30 30.6765V36.3235C30 37.3448 30.6552 38 31.6765 38H37.3235C38.3448 38 39 37.3448 39 36.3235Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'FileListIcon',
};
</script>
