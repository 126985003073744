<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.3356 28.3349L26.0007 24L30.3356 19.6651C30.8881 19.1126 30.8881 18.2168 30.3356 17.6644C29.7832 17.1119 28.8874 17.1119 28.3349 17.6644L24 21.9993L19.6651 17.6644C19.1126 17.1119 18.2168 17.1119 17.6644 17.6644C17.1119 18.2168 17.1119 19.1126 17.6644 19.6651L21.9993 24L17.6644 28.3349C17.1119 28.8874 17.1119 29.7831 17.6644 30.3356C18.2168 30.8881 19.1126 30.8881 19.6651 30.3356L24 26.0007L28.3349 30.3356C28.8874 30.8881 29.7832 30.8881 30.3356 30.3356C30.8881 29.7831 30.8881 28.8874 30.3356 28.3349Z"
    />
    <path
      d="M42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24ZM9.6 24C9.6 31.9529 16.0471 38.4 24 38.4C31.9529 38.4 38.4 31.9529 38.4 24C38.4 16.0471 31.9529 9.6 24 9.6C16.0471 9.6 9.6 16.0471 9.6 24Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'CloseIcon',
};
</script>
