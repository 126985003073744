<template>
  <!-- eslint-disable  -->
<svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 48 48">
    <path d="M0 0h48v48H0z" fill="none"/>
    <path d="M20 8v6h4.43l-6.86 16H12v6h16v-6h-4.43l6.86-16H36V8z"/>
</svg>

</template>
<script>
export default {
  name: 'FormatItalicIcon',
};
</script>
