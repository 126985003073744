<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.9966 7H11.0034C7.54752 7.16846 4.86933 10.2025 5.00493 13.7955V27.0717C4.86933 30.6647 7.54752 33.6987 11.0034 33.8672H18.3015C18.3015 33.8672 17.2781 36.6592 16.8818 37.7978C16.6775 38.3194 16.7216 38.9107 17.001 39.3933C17.2804 39.8759 17.7623 40.1934 18.3015 40.25H29.6985C30.2377 40.1934 30.7196 39.8759 30.999 39.3933C31.2784 38.9107 31.3225 38.3194 31.1182 37.7978C30.7219 36.6592 29.6985 33.8672 29.6985 33.8672H36.9966C40.4525 33.6987 43.1307 30.6647 42.9951 27.0717V13.7955C43.1307 10.2025 40.4525 7.16846 36.9966 7ZM20.301 37.1328L21.3007 34.9063H26.5393L27.5391 37.1328H20.301ZM36.4383 30.3621C38.2333 30.1862 39.5649 28.5531 39.4375 26.6838V14.2408C39.5649 12.3715 38.2333 10.7384 36.4383 10.5625H11.5617C9.76668 10.7384 8.43506 12.3715 8.5625 14.2408V26.6838C8.43506 28.5531 9.76668 30.1862 11.5617 30.3621H36.4383Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.7867 13.4125C25.7867 12.9716 25.5985 12.5487 25.2634 12.2369C24.9283 11.9252 24.4739 11.75 24 11.75C23.5261 11.75 23.0717 11.9252 22.7366 12.2369C22.4015 12.5487 22.2133 12.9716 22.2133 13.4125V22.6995L19.903 20.5498C19.566 20.247 19.1147 20.0794 18.6462 20.0832C18.1777 20.087 17.7296 20.2618 17.3983 20.5701C17.067 20.8783 16.8791 21.2953 16.8751 21.7312C16.871 22.1671 17.0511 22.5871 17.3766 22.9006L22.7368 27.8882C23.0718 28.1999 23.5262 28.375 24 28.375C24.4738 28.375 24.9282 28.1999 25.2632 27.8882L30.6234 22.9006C30.9489 22.5871 31.129 22.1671 31.1249 21.7312C31.1209 21.2953 30.933 20.8783 30.6017 20.5701C30.2704 20.2618 29.8223 20.087 29.3538 20.0832C28.8853 20.0794 28.434 20.247 28.097 20.5498L25.7867 22.6995V13.4125Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'LocalRecordIcon',
};
</script>
