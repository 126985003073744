<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9429 12.7502V9.00023C14.9429 7.34339 16.286 6.00025 17.9428 6.00023L37.4 6.00003C39.0568 6.00001 40.4 7.33407 40.4 8.99094C40.4 14.9065 40.4 26.7198 40.4 32.2564C40.4 33.9132 39.0569 35.2498 37.4 35.2498H33.4571V39C33.4571 40.6569 32.114 42 30.4571 42H11C9.34314 42 8 40.6569 8 39V15.7502C8 14.0934 9.34315 12.7502 11 12.7502H14.9429ZM18.4089 12.7502H31.4571C32.5617 12.7502 33.4571 13.6456 33.4571 14.7502V31.8748H35.9286C36.4809 31.8748 36.9286 31.4271 36.9286 30.8748V10.375C36.9286 9.82269 36.4809 9.37497 35.9286 9.37497H19.4127C18.861 9.37497 18.4136 9.82172 18.4127 10.3734L18.4089 12.7502Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ContentCopyIcon',
};
</script>
