<template>
  <!-- eslint-disable  -->
<svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 48 48">
  <path d="M 30,2 H 18 v 4 h 12 z" />
  <path d="M 23.990234 8 C 14.040234 8 6 16.06 6 26 C 6 35.94 14.040234 44 23.990234 44 C 33.940234 44 42 35.94 42 26 C 42 21.75 40.520781 17.849531 38.050781 14.769531 L 40.900391 11.919922 C 40.040391 10.889922 39.100312 9.9498437 38.070312 9.0898438 L 35.220703 11.939453 C 32.150703 9.4794531 28.240234 8 23.990234 8 z M 24 12 C 31.73 12 38 18.27 38 26 C 38 33.73 31.73 40 24 40 C 16.27 40 10 33.73 10 26 C 10 18.27 16.27 12 24 12 z " />
  <path d="M 31.559729,18.216495 C 29.406293,16.072165 26.593078,15 23.779865,15 V 25.996564 L 16,33.776632 c 4.297711,4.297824 11.252855,4.297824 15.550566,0 4.297711,-4.297823 4.297711,-11.262314 0.0092,-15.560137 z"
     id="path24" />
</svg>

</template>
<script>
export default {
  name: 'TimeElapsedIcon',
};
</script>
