<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M27.3896 24.0087C27.3896 25.8855 25.8681 27.4069 23.9913 27.4069C22.1145 27.4069 20.5931 25.8855 20.5931 24.0087C20.5931 22.1319 22.1145 20.6104 23.9913 20.6104C25.8681 20.6104 27.3896 22.1319 27.3896 24.0087Z"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M36.8674 18.6699C37.1976 19.4746 37.9802 20.001 38.8501 20.0036C39.4203 20.0036 39.9671 20.2301 40.3703 20.6333C40.7735 21.0365 41 21.5833 41 22.1535V25.8638C41 27.0512 40.0374 28.0138 38.8501 28.0138C37.9802 28.0163 37.1976 28.5427 36.8674 29.3475C36.5371 30.1522 36.7243 31.0766 37.3417 31.6894C38.1698 32.5381 38.1698 33.8923 37.3417 34.7409L34.7409 37.3417C33.8923 38.1698 32.5381 38.1698 31.6894 37.3417C31.0766 36.7243 30.1522 36.5371 29.3475 36.8674C28.5427 37.1976 28.0163 37.9802 28.0138 38.8501C28.0138 40.0374 27.0512 41 25.8638 41H22.1535C20.9661 41 20.0036 40.0374 20.0036 38.8501C20.001 37.9802 19.4746 37.1976 18.6699 36.8674C17.8652 36.5371 16.9407 36.7243 16.3279 37.3417C15.4793 38.1698 14.125 38.1698 13.2764 37.3417L10.6757 34.7409C9.84755 33.8923 9.84755 32.5381 10.6757 31.6894C11.2952 31.0744 11.4814 30.1457 11.1467 29.3394C10.812 28.5331 10.0229 28.0093 9.14992 28.0138C7.96255 28.0138 7 27.0512 7 25.8638V22.1535C7 20.9661 7.96255 20.0036 9.14992 20.0036C10.0198 20.001 10.8024 19.4746 11.1326 18.6699C11.4629 17.8652 11.2757 16.9407 10.6583 16.3279C9.83021 15.4793 9.83021 14.125 10.6583 13.2764L13.2591 10.6757C14.1077 9.84755 15.4619 9.84755 16.3106 10.6757C16.9256 11.2952 17.8543 11.4814 18.6606 11.1467C19.4669 10.812 19.9907 10.0229 19.9862 9.14992C19.9862 7.96255 20.9488 7 22.1362 7H25.8638C26.434 7 26.9809 7.22651 27.3841 7.6297C27.7873 8.03289 28.0138 8.57973 28.0138 9.14992C28.0093 10.0229 28.5331 10.812 29.3394 11.1467C30.1457 11.4814 31.0744 11.2952 31.6894 10.6757C32.5381 9.84755 33.8923 9.84755 34.7409 10.6757L37.3417 13.2764C38.1698 14.125 38.1698 15.4793 37.3417 16.3279C36.7243 16.9407 36.5371 17.8652 36.8674 18.6699ZM17.9923 24.0087C17.9923 27.3218 20.6782 30.0076 23.9913 30.0076C27.3045 30.0076 29.9903 27.3218 29.9903 24.0087C29.9903 20.6955 27.3045 18.0097 23.9913 18.0097C20.6782 18.0097 17.9923 20.6955 17.9923 24.0087Z"/>
  </svg>

</template>
<script>
export default {
  name: 'SettingsIcon',
};
</script>
