<template>
  <!-- eslint-disable  -->

  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-name="webcam-off-icon"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.9897 32.8907C39.8078 33.2631 40.6434 33.4787 41.1909 33.1409C42.0694 32.5989 41.9986 30.9991 41.9986 29.4925C41.9986 25.9992 41.9984 21.9994 41.9984 18.2143C41.9984 16.9996 42.072 14.829 41.1909 14.2854C40.2037 13.6763 38.28 14.8719 37.2109 15.5364L37.0687 15.6247L31.5975 19.0108V13.4283C31.5975 11.5348 30.1657 9.99985 28.3993 9.99985H16.0992L38.9897 32.8907Z"
    />
    <path
      d="M28.3875 37.4274H9.19821C7.43189 37.4274 6 35.8924 6 33.9989V13.4283C6 11.9422 6.882 10.677 8.11546 10.2013L6.41397 8.49978C6.02346 8.10927 6.02346 7.47613 6.41397 7.08562L7.08756 6.41202C7.47807 6.02151 8.11121 6.02151 8.50172 6.41202L36.4998 34.4108C36.6243 34.5354 36.7091 34.6845 36.7543 34.8426C36.8506 35.1804 36.7658 35.559 36.4998 35.825L35.8262 36.4986C35.4357 36.8891 34.8026 36.8891 34.4121 36.4986L31.5857 33.6722V33.9989C31.5857 35.8924 30.1538 37.4274 28.3875 37.4274Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'VideocamOffIcon',
};
</script>
