import Util from '@/modules/whiteboard.v1/assets/js/util';
const icon = Util.Icon.get;

import { prepareIcon } from '@/modules/whiteboard.v1/assets/js/icon';
import ColorLensIcon from '@/modules/whiteboard.v1/assets/icons/color_lens.svg?raw';
import AddIcon from '@/modules/whiteboard.v1/assets/icons/add.svg?raw';
import ClearIcon from '@/modules/whiteboard.v1/assets/icons/delete_sweep.svg?raw';
import PointerIcon from '@/modules/whiteboard.v1/assets/icons/pointer.svg?raw';
import ImportExportIcon from '@/modules/whiteboard.v1/assets/icons/import_export.svg?raw';
import MoveIcon from '@/modules/whiteboard.v1/assets/icons/move.svg?raw';
import PenIcon from '@/modules/whiteboard.v1/assets/icons/pen.svg?raw';
import MarkerPenIcon from '@/modules/whiteboard.v1/assets/icons/marker_pen.svg?raw';
import CircleIcon from '@/modules/whiteboard.v1/assets/icons/circle.svg?raw';
import DeleteIcon from '@/modules/whiteboard.v1/assets/icons/delete.svg?raw';
import PanIcon from '@/modules/whiteboard.v1/assets/icons/pan.svg?raw';
import ZoomInIcon from '@/modules/whiteboard.v1/assets/icons/zoom_in.svg?raw';
import FitIcon from '@/modules/whiteboard.v1/assets/icons/fit.svg?raw';
import FullscreenIcon from '@/modules/whiteboard.v1/assets/icons/fullscreen.svg?raw';
import ExitIcon from '@/modules/whiteboard.v1/assets/icons/exit.svg?raw';
import FontIcon from '@/modules/whiteboard.v1/assets/icons/font.svg?raw';
import UndoIcon from '@/modules/whiteboard.v1/assets/icons/undo.svg?raw';
import RedoIcon from '@/modules/whiteboard.v1/assets/icons/redo.svg?raw';
import FullscreenExitIcon from '@/modules/whiteboard.v1/assets/icons/fullscreen_exit.svg?raw';
import PushBackIcon from '@/modules/whiteboard.v1/assets/icons/push_back.svg?raw';
import BringToFrontIcon from '@/modules/whiteboard.v1/assets/icons/bring_to_front.svg?raw';
import ZoomOutIcon from '@/modules/whiteboard.v1/assets/icons/zoom_out.svg?raw';
import LineIcon from '@/modules/whiteboard.v1/assets/icons/line.svg?raw';
import ArrowIcon from '@/modules/whiteboard.v1/assets/icons/arrow.svg?raw';
import RectangleIcon from '@/modules/whiteboard.v1/assets/icons/rectangle.svg?raw';
import RectangleFilledIcon from '@/modules/whiteboard.v1/assets/icons/rectangle_filled.svg?raw';
import TriangleIcon from '@/modules/whiteboard.v1/assets/icons/triangle.svg?raw';
import TriangleFilledIcon from '@/modules/whiteboard.v1/assets/icons/triangle_filled.svg?raw';
import CircleFilledIcon from '@/modules/whiteboard.v1/assets/icons/circle_filled.svg?raw';

export const ICON_LINE = prepareIcon(LineIcon);
export const ICON_ARROW = prepareIcon(ArrowIcon);
export const ICON_BRUSH = prepareIcon(PenIcon);
export const ICON_BRUSH_SMALL = prepareIcon(PenIcon, 'small');
// export const ICON_SQUARE = icon('crop_square');
export const ICON_RECTANGLE = prepareIcon(RectangleIcon);
export const ICON_RECTANGLE_FILL = prepareIcon(RectangleFilledIcon);
export const ICON_TEXT = prepareIcon(ExitIcon);
export const ICON_TRIANGLE = prepareIcon(TriangleIcon);
export const ICON_TRIANGLE_FILL = prepareIcon(TriangleFilledIcon);
export const ICON_CIRCLE = prepareIcon(CircleIcon);
export const ICON_CIRCLE_FILL = prepareIcon(CircleFilledIcon);
export const ICON_CLEAR = prepareIcon(ClearIcon, 'small');
export const ICON_ZOOM_IN = prepareIcon(ZoomInIcon);
export const ICON_ZOOM_RESET = prepareIcon(ZoomOutIcon);
export const ICON_PAN = prepareIcon(PanIcon);
export const ICON_FULLSCREEN = prepareIcon(FullscreenIcon);
export const ICON_FULLSCREEN_EXIT = prepareIcon(FullscreenExitIcon);
export const ICON_MOVE = prepareIcon(MoveIcon);
// export const ICON_FONT_SIZE = icon('format_size');
export const ICON_FONT = prepareIcon(FontIcon);
// export const ICON_FILL_COLOR = icon('format_color_fill');
// export const ICON_EMPTY_COLOR = icon('format_color_reset');
export const ICON_COLOR_PICKER = prepareIcon(ColorLensIcon);
export const ICON_COLOR_PICKER_SMALL = prepareIcon(ColorLensIcon, 'small');
export const ICON_FIT = prepareIcon(FitIcon);
export const ICON_NEXT = icon('navigate_next');
export const ICON_PRE = icon('navigate_before');
export const ICON_ADD = icon('add');
export const ICON_DELETE = prepareIcon(DeleteIcon);
export const ICON_UNDO = prepareIcon(UndoIcon);
export const ICON_REDO = prepareIcon(RedoIcon);
export const ICON_MENU_ADD = prepareIcon(AddIcon, 'small');
export const ICON_MOUSE = prepareIcon(PointerIcon, 'small');
export const ICON_IMPORT_EXPORT = prepareIcon(ImportExportIcon, 'small');
export const ICON_IMPORT = icon('file_upload', 'small');
export const ICON_EXPORT = icon('file_download', 'small');
// export const ICON_BOLD = icon('format_bold');
// export const ICON_ITALIC = icon('format_italic');
export const ICON_MARKER = prepareIcon(MarkerPenIcon);
export const ICON_PUSH_BACK = prepareIcon(PushBackIcon);
export const ICON_BRING_FRONT = prepareIcon(BringToFrontIcon);
// export const ICON_CLOSE = icon('close', 'small');
export const ICON_ONE_BOARD = icon('crop_portrait', 'small');
export const ICON_ALL_BOARDS = icon('content_copy', 'small');
