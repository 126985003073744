//since there is no multi inheritance in JS/ES6, the dom element class has to extend the eventmanager,
// because all components will extend DomElement class
import EventManager from '@/modules/whiteboard.v1/assets/js/event-manager';
import { extendOptionsWithDefaults, isArray, isDefined } from '@/modules/whiteboard.v1/assets/js/util';
import Util from './util';

/**
 * Dom Element wrapper
 */
export default class DomElement extends EventManager {
  /**
   * @param {object} [options]
   * @param {Element|Node|NodeList|null} [options.el] the original dom element or a collection of elements
   * @param {DomElement} [options.parent]
   */
  constructor(options) {
    super();

    options = extendOptionsWithDefaults(options, {
      enabled: true,
      element: null,
      parent: null,
    });

    this._enabled = options.enabled;
    //keep track of dom element events so we can use in off
    this._domEvents = {};

    /**
     *
     * @type {Element|NodeList|null}
     * @protected
     */
    this._el = null;
    this._isNodeList = false;

    let el = options.element;
    let parent = options.parent;
    if (el) {
      if (el instanceof NodeList) {
        this._el = [];
        this._isNodeList = true;
        for (let node of el) {
          this._el.push(new DomElement({ element: node }));
        }
      } else if (isArray(el)) {
        this._el = el;
        this._isNodeList = true;
      } else this._el = el;
    }

    /**
     *
     * @type {null|DomElement}
     */
    this._parent = parent;

    if (!this._isNodeList && parent && el && parent.element && !parent.element.contains(el)) {
      this.appendTo(parent);
    }
  }

  /**
   *
   * @returns {Element|NodeList|null}
   */
  get element() {
    return this._el;
  }

  /**
   * get the parent dom element if its set
   * @return {Element}
   */
  get parentElement() {
    if (!this._isNodeList && this.element && this.element.parentElement) return this.element.parentElement;

    return null;
  }

  isEnabled() {
    return this._enabled;
  }

  setEnabled(enabled) {
    this._enabled = enabled;
    if (!this._enabled) this.addClass('disabled');
    else this.removeClass('disabled');
  }

  /**
   * Shortcut for creating DomElements
   * @param {string} tag
   * @param {object|null} [attr]
   * @param {Element|DomElement|string|null} [content]
   * @param {DomElement} [parent]
   * @return {DomElement}
   * @constructor
   */
  static CreateDomElement(tag, attr, content, parent) {
    let el = new DomElement({ element: document.createElement(tag), parent: parent });
    if (attr) el.attr(attr);
    if (isDefined(content) && content != null) el.html(content);

    return el;
  }

  /**
   * Shortcut for creating a DomElement, used mainly in components
   * @param tag
   * @param {object} [attr]
   * @param {string|Element|DomElement} [content]
   * @param {DomElement} [parent]
   * @returns {DomElement}
   */
  createDomElement(tag, attr, content, parent) {
    return DomElement.CreateDomElement(tag, attr, content, parent);
  }

  /**
   * create the main dom element for this class, used mainly for components
   * @param tag
   * @param attr
   * @param [content]
   * @returns {DomElement}
   */
  createElement(tag, attr, content) {
    this._el = document.createElement(tag);
    this.attr(attr);
    this.html(content);
    this.setEnabled(this.isEnabled());

    return this;
  }

  /**
   * get the parent element if its set, otherwise try to set the parent DomElement
   * @returns {null|DomElement}
   */
  parent() {
    if (!this.element) return null;

    if (!this._parent) {
      //node list can't have parent
      if (this._isNodeList) return null;

      if (this.element && this.element.parentElement)
        this._parent = new DomElement({ element: this.element.parentElement });
    }

    return this._parent;
  }

  /**
   *
   * @param newClass
   * @return {DomElement}
   */
  addClass(newClass) {
    if (!this.element) return this;

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].addClass(newClass);
      }
    } else {
      if (newClass && Util.isString(newClass)) {
        newClass = newClass.trim();
        if (newClass.length) {
          newClass = newClass.split(' ');
          this._el.classList.add.apply(this._el.classList, newClass);
        }
      }
    }

    return this;
  }

  toggleClass(className, status) {
    if (status) return this.addClass(className);
    else return this.removeClass(className);
  }

  /**
   *
   * @param className
   * @return {DomElement}
   */
  setClass(className) {
    if (!this.element) return this;

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].setClass(className);
      }
    } else this._el.className = className;

    return this;
  }

  /**
   *
   * @param className
   * @return {DomElement}
   */
  removeClass(className) {
    if (!this.element) return this;

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].removeClass(className);
      }
    } else {
      className = className.trim().split(' ');
      for (let i = 0, l = className.length; i < l; i++)
        if (className[i] && className[i].trim().length) this._el.classList.remove(className[i].trim());
    }

    return this;
  }

  /**
   * Determine whether any of the matched elements are assigned the given class.
   * @param className
   * @return {boolean}
   */
  hasClass(className) {
    if (!this.element) return false;

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        if (this._el[i].element.classList.contains(className)) return true;
      }
    } else return this._el.classList.contains(className);
  }

  /**
   * Get the HTML contents of the first element in the set of matched elements or set the HTML contents of every
   * matched element.
   * @param {string|Element|NodeList|DomElement} [html]
   * @returns {DomElement|string}
   */
  html(html) {
    if (Util.isDefined(html)) {
      //set the html
      if (this._isNodeList) {
        for (let i = 0, l = this._el.length; i < l; i++) {
          this._el[i].html(html);
        }
      } else {
        if (html instanceof Element || html instanceof Node) {
          if (this._el) {
            this.empty();
            this._el.appendChild(html);
          } else this._el = html;
        } else if (html instanceof NodeList) {
          if (this._el) {
            this.empty();
            for (let node of html) this._el.appendChild(node);
          } else Util.err('DomElement.setHtml() : cannot set a NodeList to empty element');
        } else if (html instanceof DomElement) {
          this.html(html.element);
        } else {
          try {
            html = html.toString();
          } catch (e) {
            html = '';
          }

          if (this._el) {
            if (this._el.tagName == 'INPUT') this._el.value = html;
            //if this DomElement has a base element just set the inner html
            else this._el.innerHTML = html;
          }
          //otherwise convert this string to html and set it as the base element
          else this._el = Util.Dom.htmlToDom(html);
        }
      }

      return this;
    } else {
      if (!this.element) return null;

      //if html is not defined just return the html content of this node
      if (this._isNodeList) return this._el[0].html(html);
      else return this.element.innerHTML.trim();
    }
  }

  /**
   * Get the combined text contents of each element in the set of matched elements, including their descendants, or
   * set the text contents of the matched elements.
   * @param text
   * @return {DomElement|string}
   */
  text(text) {
    if (Util.isDefined(text)) {
      if (this._isNodeList) {
        for (let i = 0, l = this._el.length; i < l; i++) {
          this._el[i].text(text);
        }
      } else {
        this.empty();
        this.element.textContent = text;
      }

      return this;
    } else {
      if (!this.element) return null;

      if (this._isNodeList) return this._el[0].text(text);
      else return this.element.textContent.trim();
    }
  }

  /**
   * Remove all child nodes of the set of matched elements from the DOM.
   * @returns {DomElement}
   */
  empty() {
    if (!this.element) return this;

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].empty();
      }
    } else {
      if (this.element) {
        while (this.element.firstChild) {
          this.element.removeChild(this.element.firstChild);
        }
      }
    }

    return this;
  }

  isEmpty() {
    if (!this.element) return true;

    if (this._isNodeList) return false;

    return !!this.element.firstChild;
  }

  /**
   *
   * @return {DomElement}
   */
  focus() {
    if (!this.element) return this;

    if (this._isNodeList) {
      return this._el[0].focus();
    } else this.element.focus();

    return this;
  }

  /**
   * Get the value of an attribute for the first element in the set of matched elements or set one or more attributes
   * for every matched element.
   * @param {object|string} [attr] key value pair
   * @param [value]
   * @return {string|int|object}
   */
  attr(attr, value) {
    let attrIsObject = Util.isObject(attr);
    let attrIsString = Util.isString(attr);
    let valueIsDefined = Util.isDefined(value);

    if (!this.element) return attrIsObject || valueIsDefined ? this : null;

    //if attr is object we want to set the attr key,value pair to all the elements
    if (attrIsObject) {
      if (this._isNodeList) {
        for (let i = 0, l = this._el.length; i < l; i++) {
          this._el[i].attr(attr, value);
        }
      } else {
        for (let property in attr) {
          if (attr.hasOwnProperty(property)) {
            this._el.setAttribute(property, attr[property]);
          }
        }
      }

      return this;
    }

    //set the value for attr for all the elements
    if (attrIsString && valueIsDefined) {
      if (this._isNodeList) {
        for (let i = 0, l = this._el.length; i < l; i++) {
          this._el[i].element.setAttribute(attr, value);
        }
      } else {
        this.element.setAttribute(attr, value);
      }

      return this;
    }

    //get the value for attr for the first element
    if (attrIsString) {
      if (this._isNodeList) {
        return this._el[0].element.getAttribute(attr);
      } else {
        return this.element.getAttribute(attr);
      }
    }

    //return an array of all the attrs
    if (this._isNodeList) {
      return this._el[0].attr();
    } else {
      let attrs = {};
      for (let item of this.element.attributes) {
        attrs[item.nodeName] = item.nodeValue;
      }

      return attrs;
    }
  }

  /**
   * remove attr by name
   * @param {string} attr
   * @return {DomElement}
   */
  removeAttr(attr) {
    if (!this.element) return this;

    if (attr) {
      if (this._isNodeList) {
        for (let i = 0, l = this._el.length; i < l; i++) {
          this._el[i].element.removeAttribute(attr);
        }
      } else {
        this.element.removeAttribute(attr);
      }
    }

    return this;
  }

  /**
   * Insert content, specified by the parameter, to the end of each element in the set of matched elements.
   * @param child
   * @return {DomElement}
   */
  appendChild(...child) {
    //can't append to something that does not exist
    if (!this.element) return this;

    for (let i = 0, l = child.length; i < l; i++) {
      this._appendChild(child[i]);
    }

    return this;
  }

  _appendChild(child) {
    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].appendChild(child);
      }
    } else {
      if (child instanceof DomElement) {
        // console.log(this);
        this._el.appendChild(child.element);
        child._parent = this;
      } else if (typeof child === 'string') {
        this.appendChild(Util.Dom.htmlToDom(child));
      } else if (child instanceof Element || child instanceof Node) {
        this._el.appendChild(child);
      } else if (child instanceof NodeList) {
        for (let node of child) this._el.appendChild(node);
      }
    }

    return this;
  }

  append(...child) {
    return this.appendChild.apply(this, child);
  }

  /**
   * Insert content, specified by the parameter, to the beginning of each element in the set of matched elements.
   * @param child
   * @return {DomElement}
   */
  prependChild(...child) {
    //same logic as append
    if (!this.element) return this;

    for (let i = 0, l = child.length; i < l; i++) {
      this._prependChild(child[i]);
    }

    return this;
  }

  _prependChild(child) {
    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].prependChild(child);
      }
    } else {
      if (child instanceof DomElement) {
        if (this.element.firstChild) this.element.insertBefore(child.element, this.element.firstChild);
        else this.element.appendChild(child.element);

        child._parent = this;
      } else if (typeof child == 'string') {
        this.prependChild(DomElement.Select(child));
      } else if (child instanceof Element) {
        this.prependChild(DomElement.Select(child));
      } else if (child instanceof NodeList) {
        for (let node of child) this.prependChild(DomElement.Select(node));
      }
    }

    return this;
  }

  prepend(...child) {
    return this.prependChild.apply(this, child);
  }

  prependTo(parent) {
    parent = DomElement.Select(parent);
    parent.prependChild(this);

    return this;
  }

  /**
   * Insert every element in the set of matched elements before the target.
   * @param {DomElement} target
   * @return {DomElement}
   */
  insertBefore(target) {
    //can't insert something if it does not exist
    if (!this.element) return this;

    //just in case, convert to DomElement
    target = DomElement.Select(target);

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        // target.parentElement.insertBefore(this._el[i].element, target.element);
        this._el[i].insertBefore(target);
      }
    } else {
      if (target.parent()) {
        target.parent().element.insertBefore(this.element, target.element);
      }
    }

    return this;
  }

  /**
   * add a event listener for this object
   * @param {string} event the name of the event
   * @param {function} fn what to do when this event is triggered
   * @param {object} [options]
   * @param {int} [options.bottleneckTimeout] bottleneck the execution of this handler for every X milliseconds
   * @param {boolean} [options.passive]
   * @return {DomElement}
   */
  on(event, fn, options) {
    if (!this.element) return this;

    options = Util.extendOptionsWithDefaults(options, {
      bottleneckTimeout: false,
      passive: true,
    });
    let __timeout = null;
    let _this = this;
    let events = event.split(' ');

    // avoid memory overhead of new anonymous functions for every event handler that's installed
    // by using local functions
    function listenHandler(e) {
      if (!_this.isEnabled()) return false;

      let ret;

      if (events.indexOf('resize') !== -1 || options.bottleneckTimeout) {
        options.bottleneckTimeout = options.bottleneckTimeout || 66;
        if (!__timeout) {
          __timeout = setTimeout(function () {
            __timeout = null;

            ret = fn.apply(this, arguments);
            if (ret === false) {
              e.stopPropagation();
              if (e.cancelable) e.preventDefault();
            }

            // The actualResizeHandler will execute at a rate of 15fps
          }, options.bottleneckTimeout);
        }
      } else {
        ret = fn.apply(this, arguments);
        if (ret === false) {
          e.stopPropagation();
          if (e.cancelable) e.preventDefault();
        }
      }

      return ret;
    }

    listenHandler.FN = fn;
    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].on(event, fn);
      }
    } else {
      for (let i = 0, l = events.length; i < l; i++) {
        this.element.addEventListener(
          events[i],
          listenHandler,
          options.passive && Util.Dom.SupportsPassiveEventHandler ? { passive: true } : false,
        );

        (this._domEvents[events[i]] = this._domEvents[events[i]] || []).push(listenHandler);
      }
    }

    return this;
  }

  /**
   *
   * @param event
   * @param fn
   * @returns {DomElement}
   */
  off(event, fn) {
    if (!this.element) return this;

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].off(event, fn);
      }
    } else {
      let events = event.split(' ');

      for (let j = 0, jl = events.length; j < jl; j++) {
        if (this._domEvents[events[j]] && this._domEvents[events[j]].length) {
          for (let i = 0, l = this._domEvents[events[j]].length; i < l; i++) {
            let handler = this._domEvents[events[j]][i];
            if (fn) {
              if (handler.FN === fn) {
                this.element.removeEventListener(events[j], handler);
                this._domEvents[events[j]].splice(i, 1);
                break;
              }
            } else this.element.removeEventListener(events[j], handler);
          }
        }

        !fn && (this._domEvents[events[j]] = []);
      }
    }

    return this;
  }

  /**
   *
   * @param event
   */
  trigger(event) {
    if (!this.element) return this;

    let e = document.createEvent('HTMLEvents');
    e.initEvent(event, false, true);
    this.element.dispatchEvent(e);
  }

  /**
   * Insert every element in the set of matched elements to the end of the target.
   * @param parent
   */
  appendTo(parent) {
    if (!this.element) return this;

    parent = DomElement.Select(parent);

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        parent.appendChild(this._el[i]);
      }
    } else {
      parent.appendChild(this);
    }

    return this;
  }

  insertAt(parent, index) {
    if (!this.element) return this;

    parent = DomElement.Select(parent);

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].insertAt(parent, index);
      }
    } else {
      parent.element.insertBefore(this.element, parent.element.children[index]);
    }
  }

  /**
   * Check to see if a DOM element is a descendant of another DOM element.
   * @param el
   * @return {*|boolean}
   */
  contains(el) {
    if (!this.element) return false;

    if (this._isNodeList) return false;

    let search = el instanceof DomElement ? el.element : el;
    return this.element.contains(search);
  }

  /**
   * Remove the set of matched elements from the DOM.
   * @return {DomElement}
   */
  remove() {
    if (!this.element) return this;

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].remove();
      }
    } else {
      if (this.element && this.element.parentElement) this.element.parentElement.removeChild(this.element);
    }

    return this;
  }

  /**
   * Shows/hides the matched elements.
   * @param   {boolean}   visible  Show or hide
   * @return {DomElement}
   */
  setVisible(visible) {
    if (!this.element) return this;

    if (this._isNodeList) {
      for (let i = 0, l = this._el.length; i < l; i++) {
        this._el[i].setVisible(visible);
      }
    } else {
      if (visible) {
        this.removeClass('hidden');
      } else {
        this.addClass('hidden');
      }
    }

    return this;
  }

  /**
   * Display the matched elements.
   * @return {DomElement}
   */
  show() {
    return this.setVisible(true);
  }

  /**
   * Hide the matched elements.
   * @return {DomElement}
   */
  hide() {
    return this.setVisible(false);
  }

  /**
   * Get the value of a computed style property for the first element in the set of matched elements or set one or
   * more CSS properties for every matched element.
   * @param {object|string} data
   * @param {string} [value]
   * @return {DomElement|Object}
   */
  style(data, value) {
    let dataIsObject = Util.isObject(data);
    let dataIsString = Util.isString(data);
    let valueIsDefined = Util.isDefined(value);

    if (!this.element) return dataIsObject || valueIsDefined ? this : null;

    //if data is object we want to set the attr key,value pair to all the elements
    if (dataIsObject) {
      if (this._isNodeList) {
        for (let i = 0, l = this._el.length; i < l; i++) {
          this._el[i].style(data, value);
        }
      } else {
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            this.element.style[key] = data[key];
          }
        }
      }

      return this;
    }

    //set the value for attr for all the elements
    if (dataIsString && valueIsDefined) {
      if (this._isNodeList) {
        for (let i = 0, l = this._el.length; i < l; i++) {
          this._el[i].element.style[data] = value;
        }
      } else {
        this.element.style[data] = value;
      }

      return this;
    }

    //get the value for attr for the first element
    if (dataIsString) {
      if (this._isNodeList) {
        return this._el[0].element.style[data];
      } else {
        return this.element.style[data];
      }
    }

    //return all the styles
    if (this._isNodeList) {
      return this._el[0].element.style;
    } else {
      return this.element.style;
    }
  }

  val(value) {
    if (!this.element) return Util.isDefined(value) ? this : null;

    let el = this._el;
    if (this._isNodeList) el = this._el[0].element;

    if (!Util.isDefined(value)) return el.value;

    el.value = value;

    return this;
  }

  /**
   * Get the value of a property for the first element in the set of matched elements or set one or more properties
   * for every matched element.
   */
  prop(props, value) {
    let propsIsObject = Util.isObject(props);
    let propsIsString = Util.isString(props);
    let valueIsDefined = Util.isDefined(value);

    if (!this.element) return propsIsObject || valueIsDefined ? this : null;

    //if data is object we want to set the attr key,value pair to all the elements
    if (propsIsObject) {
      if (this._isNodeList) {
        for (let i = 0, l = this._el.length; i < l; i++) {
          this._el[i].prop(props, value);
        }
      } else {
        for (let key in props) {
          if (props.hasOwnProperty(key)) {
            this.element[key] = props[key];
          }
        }
      }

      return this;
    }

    //set the value for attr for all the elements
    if (propsIsString && valueIsDefined) {
      if (this._isNodeList) {
        for (let i = 0, l = this._el.length; i < l; i++) {
          this._el[i].element[props] = value;
        }
      } else {
        this.element[props] = value;
      }

      return this;
    }

    //get the value for attr for the first element
    if (propsIsString) {
      if (this._isNodeList) {
        return this._el[0].element[props];
      } else {
        return this.element[props];
      }
    }

    //return this
    if (this._isNodeList) {
      return this._el[0];
    } else {
      return this;
    }
  }

  /**
   * Get the descendants of each element in the current set of matched elements, filtered by a selector
   */
  find(selector) {
    if (!this.element) return null;

    if (!Util.isString(selector)) {
      Util.err('DomElement.find(selector), currently only string selectors are supported');
      return null;
    }

    if (this._isNodeList) {
      let found = [];
      for (let i = 0, l = this._el.length; i < l; i++) {
        let el = this._el[i].find(selector);
        if (el._isNodeList) found = found.concat(el.element);
        else found.push(el);
      }

      return new DomElement({ element: found });
    } else {
      let id = this.attr('id');
      if (!id) {
        id = 'el_' + Date.now() + '' + Util.random(1000, 99999);
        this.attr('id', id);
      }

      return DomElement.Select('#' + id + ' ' + selector);
    }
  }

  /**
   * width in pixels, including padding and border
   * @return {*}
   */
  outerWidth() {
    if (!this.element) return 0;

    if (this._isNodeList) return this.element[0].outerWidth();

    return this.element.offsetWidth;
  }

  /**
   * height in pixels, including padding and border
   * @return {*}
   */
  outerHeight() {
    if (!this.element) return 0;

    if (this._isNodeList) return this.element[0].outerHeight();

    return this.element.offsetHeight;
  }

  /**
   * width in pixels, including padding, but without border
   * @return {*}
   */
  innerWidth() {
    if (!this.element) return 0;

    if (this._isNodeList) return this.element[0].innerWidth();

    return this.element.clientWidth;
  }

  /**
   * height in pixels, including padding, but without border
   * @return {*}
   */
  innerHeight() {
    if (!this.element) return 0;

    if (this._isNodeList) return this.element[0].innerHeight();

    return this.element.clientHeight;
  }

  /**
   *
   * @return {DomElement|null}
   */
  lastChild() {
    if (!this.element) return null;

    if (this._isNodeList) return this.element[0].lastChild();

    let c = this.element.lastElementChild;
    if (c) return new DomElement({ element: c, parent: this });

    return null;
  }

  childElementCount() {
    if (!this.element) return 0;

    if (this._isNodeList) return this.element.length;

    return this.element.childElementCount;
  }

  closest(selector) {
    if (!this.element) return null;

    if (this._isNodeList) return null;

    // if (this.element.tagName === tag.toUpperCase())
    //   return this;
    //
    // if (this.parent())
    //   return this.parent().closest(tag);

    let c = this.element.closest(selector);
    if (c) return new DomElement({ element: c });

    return null;
  }

  offset() {
    if (this._isNodeList || !this._el) return null;

    return this._el.getBoundingClientRect();
  }

  absoluteOffset() {
    if (this._isNodeList || !this._el) return null;

    let elem = this._el;
    let box = elem.getBoundingClientRect();

    let body = document.body;
    let docEl = document.documentElement;

    let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    let clientTop = docEl.clientTop || body.clientTop || 0;
    let clientLeft = docEl.clientLeft || body.clientLeft || 0;

    let top = box.top + scrollTop - clientTop;
    let left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left), width: box.width, height: box.height };
  }

  isFocused() {
    return this.element && document.activeElement === this.element;
  }
  toString() {
    if ('outerHTML' in this.element) return this.element.outerHTML;

    const tmp = document.createElement('div');
    tmp.appendChild(this.element.cloneNode(true));
    return tmp.innerHTML;
  }
}

/**
 *
 * @param selector
 * @return {DomElement}
 */
DomElement.Select = function (selector) {
  if (selector instanceof DomElement) return selector;
  else if (selector instanceof Element || selector instanceof Document || selector instanceof Window)
    return new DomElement({ element: selector });
  else if (typeof selector === 'string') {
    selector = selector.trim();
    if (selector[0] === '<' && selector[selector.length - 1] === '>' && selector.length >= 3) {
      let el = new DomElement();
      el.html(selector);

      return el;
    } else {
      let matches = document.querySelectorAll(selector);
      if (matches.length === 1) matches = matches[0];
      else if (matches.length === 0) matches = null;

      return new DomElement({ element: matches });
    }
  } else return null;
};
DomElement.Window = function () {
  return new DomElement({ element: window });
};
DomElement.Document = function () {
  return new DomElement({ element: window.document });
};
DomElement.Body = function () {
  return DomElement.Select('body');
};

// window.$ = DomElement.Select;

//---------------------------------------------------Polyfill--------------------------------------------------------
//For browsers that do not support Element.matches() or Element.matchesSelector(), but carry support for
// document.querySelectorAll()
if (!Element.prototype.matches)
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.matchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.oMatchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    function (s) {
      let matches = (this.document || this.ownerDocument).querySelectorAll(s),
        i = matches.length;
      while (--i >= 0 && matches.item(i) !== this) {}
      return i > -1;
    };
}

//For browsers that do not support Element.closest(), but carry support for element.matches() (or a prefixed
// equivalent, meaning IE9+)
if (!Element.prototype.closest)
  Element.prototype.closest = function (s) {
    let el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
//-----------------------------------------------------------------------------------------------------------
