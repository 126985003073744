<template>
  <!-- eslint-disable  -->
  <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="2.5" width="6" height="1" />
    <rect x="2.5" y="6" width="6" height="1" transform="rotate(-90 2.5 6)" />
  </svg>
</template>
<script>
export default {
  name: 'AddQuestion',
};
</script>
