<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" width="61.1" height="24.094" viewBox="0 0 61.1 24.094">
    <g id="Group_1730" transform="translate(-1523 -927.923)">
      <g id="smartphone" transform="translate(1577.473 940.968)">
        <g id="Group_1729">
          <path
            id="Path_622"
            d="M95.106 0h-3.872a1.374 1.374 0 0 0-1.378 1.378v8.269a1.382 1.382 0 0 0 1.378 1.378h3.871a1.374 1.374 0 0 0 1.378-1.378V1.378A1.374 1.374 0 0 0 95.106 0zm-1.93 10.136a.626.626 0 1 1 .626-.626.62.62 0 0 1-.626.626zm2.681-1.917h-5.375V1.842h5.375z"
            class="cls-1"
            transform="translate(-89.856)"
          />
        </g>
      </g>
      <g id="computer-tablet" transform="translate(1557.499 935.513)">
        <path
          id="Path_621"
          d="M49.494.551A1.806 1.806 0 0 0 48.169 0h-9.752a1.806 1.806 0 0 0-1.325.551 1.806 1.806 0 0 0-.551 1.325v12.753a1.881 1.881 0 0 0 1.876 1.871h9.752a1.881 1.881 0 0 0 1.876-1.875V1.876a1.808 1.808 0 0 0-.551-1.325zm-5.674 14.98a.736.736 0 1 1 .223-.531.721.721 0 0 1-.223.531zm4.724-2.4a.38.38 0 0 1-.375.375h-9.752a.38.38 0 0 1-.375-.375V1.876a.38.38 0 0 1 .375-.375h9.752a.38.38 0 0 1 .375.375z"
          class="cls-1"
          transform="translate(-36.541)"
        />
      </g>
      <path
        id="computer"
        fill="#a9a8a8"
        fill-rule="evenodd"
        d="M1.25 6.831A3.581 3.581 0 0 1 4.831 3.25h20.832a3.581 3.581 0 0 1 3.581 3.581v13.02a3.581 3.581 0 0 1-3.581 3.581h-7.534l.977 1.953h2.652a.977.977 0 1 1 0 1.953H8.737a.977.977 0 0 1 0-1.953h2.652l.977-1.953H4.831a3.581 3.581 0 0 1-3.581-3.581zm24.413 14.648H4.831A1.628 1.628 0 0 1 3.2 19.851V6.831A1.628 1.628 0 0 1 4.831 5.2h20.832a1.628 1.628 0 0 1 1.628 1.628v13.02a1.627 1.627 0 0 1-1.628 1.631z"
        transform="translate(1521.75 924.673)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DevicesIcon',
};
</script>

<style scoped>
.cls-1 {
  fill: #a9a8a8;
}
</style>
