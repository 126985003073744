<template>
  <!-- eslint-disable  -->
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M34.8 12.3994V19.0409C38.3101 19.0409 40 20.5189 40 24.1762V34.8285C40 38.5882 38.1176 39.9958 34.464 39.9958H13.536C9.88235 39.9958 8 38.5882 8 34.8285V18.3529C8 14.4012 9.68993 13.1536 13.2 13.1536C13.2 13.1307 13.2001 12.3536 13.2002 12.3309C13.2214 9.39017 14.6114 8 17.6 8H30.4C33.4118 8 34.8 9.41176 34.8 12.3994ZM20.896 13.1536C23.0588 13.1536 24 13.9965 24 16.0812C24 18.166 24.9412 19.0089 27.104 19.0089H32.4V12.3994C32.4 10.8235 32 10.3997 30.4 10.3997H17.6C16.0234 10.3997 15.612 10.8112 15.6003 12.3309C15.6001 12.3535 15.6 13.1305 15.6 13.1536H20.896Z"/>
</svg>
</template>
<script>
export default {
  name: 'SlideshowIcon',
};
</script>
