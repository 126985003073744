<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.75 6.5C5.75 6.05499 5.88196 5.61998 6.12919 5.24997C6.37643 4.87996 6.72783 4.59157 7.13896 4.42127C7.5501 4.25097 8.0025 4.20642 8.43895 4.29323C8.87541 4.38005 9.27632 4.59434 9.59099 4.90901C9.90566 5.22368 10.12 5.62459 10.2068 6.06105C10.2936 6.49751 10.249 6.94991 10.0787 7.36104C9.90843 7.77217 9.62004 8.12358 9.25003 8.37081C8.88002 8.61804 8.44501 8.75 8 8.75V9.5M14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="8" cy="11.75" r="0.75" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'QuestionMarkCircleIcon',
};
</script>
