<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="7.99995"
      cy="8.00001"
      r="7.2"
      transform="rotate(-180 7.99995 8.00001)"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 12L8 4M8 4L10 6M8 4L6 6"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowUpCircleIcon',
};
</script>
