<template>
  <!-- eslint-disable  --><svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.79167 4.95833H11.3333L7.08333 9.20833L2.83333 4.95833H6.375V0H7.79167V4.95833ZM1.41667 11.3333H12.75V6.375H14.1667V12.0417C14.1667 12.2295 14.092 12.4097 13.9592 12.5425C13.8264 12.6754 13.6462 12.75 13.4583 12.75H0.708333C0.520472 12.75 0.340304 12.6754 0.207466 12.5425C0.0746277 12.4097 0 12.2295 0 12.0417V6.375H1.41667V11.3333Z"
      fill="#C4C4C4"
    />
  </svg>
</template>
<script>
export default {
  name: 'ExportIcon',
};
</script>
