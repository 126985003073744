<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.1617 20.6811L36.1601 7.31222C36.4455 7.09044 36.802 6.98044 37.1628 7.00286C37.5235 7.02527 37.8637 7.17856 38.1195 7.43396L42.5654 11.879C42.8212 12.1349 42.9747 12.4754 42.9971 12.8366C43.0196 13.1977 42.9093 13.5546 42.6872 13.8402L29.3202 28.8377C28.0815 26.2582 23.7432 21.9194 21.1617 20.6811ZM29.2383 23.076L38.8642 12.921L37.2767 11.3336L27.1217 20.9594C27.5548 21.3926 27.747 21.5844 28.18 22.0177C28.5649 22.4027 28.8532 22.6913 29.2383 23.076Z"/>
  <path d="M20.1454 22.8195C22.148 23.7958 23.1702 24.7288 24.2808 25.9167C25.2351 26.9374 26.3425 28.1334 27.1822 29.8536C27.5288 31.2676 27.5496 32.7419 27.243 34.1651C26.9364 35.5884 26.3104 36.9233 25.4123 38.0692C24.5142 39.215 23.3675 40.1418 22.0587 40.7796C20.7499 41.4173 19.3134 41.7493 17.8575 41.7505C13.796 41.7505 8.68594 40.1725 5.38051 38.4479C4.91 38.2024 4.87469 37.5576 5.28096 37.2162C9.36712 33.7818 8.34822 31.6156 8.89259 29.5847C10.2472 24.5274 15.092 21.5737 20.1454 22.8195Z"/>
  </svg>
</template>
<script>
export default {
  name: 'PaintBrushIcon',
};
</script>
