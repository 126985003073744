<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M42 6.5C42 6.22386 41.7761 6 41.5 6H6.5C6.22386 6 6 6.22386 6 6.5V41.5C6 41.7761 6.22386 42 6.5 42H41.5C41.7761 42 42 41.7761 42 41.5V6.5ZM37.5 11C37.5 10.7239 37.2761 10.5 37 10.5H11C10.7239 10.5 10.5 10.7239 10.5 11V37C10.5 37.2761 10.7239 37.5 11 37.5H37C37.2761 37.5 37.5 37.2761 37.5 37V11Z"/>
  </svg>

</template>
<script>
export default {
  name: 'RectangleIcon',
};
</script>
