<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.7017 31.7874L19.7001 31.7874C19.3323 31.7871 18.976 31.6596 18.6916 31.4265L18.6915 31.4264L12.8945 26.6775C12.2475 26.1121 12.1657 25.1348 12.7104 24.4696C13.2552 23.8044 14.2294 23.692 14.9113 24.2149L19.4005 27.8891L19.6357 28.0816L19.8592 27.8757L32.8082 15.9497L32.8246 15.9346L32.8389 15.9175C33.2379 15.4431 33.876 15.2433 34.4743 15.4056C35.0727 15.5678 35.5224 16.0626 35.6271 16.6736C35.7317 17.2846 35.4723 17.9008 34.9621 18.2529L34.9415 18.2671L34.9232 18.2841L20.783 31.3625L20.7813 31.364C20.4889 31.6381 20.1025 31.7896 19.7017 31.7874Z" stroke="black" stroke-width="0.7"/>
  </svg>
</template>
<script>
export default {
  name: 'CheckedIcon',
};
</script>
