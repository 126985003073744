<template>
  <!-- eslint-disable  -->
<svg
   xmlns="http://www.w3.org/2000/svg"
   
   
   viewBox="0 0 48 48" >
  <path
     d="m 33.669383,26.194029 c 0.32999,0.312213 0.416654,0.798864 0.243325,1.19774 -0.173327,0.416654 -0.573315,0.694423 -1.024411,0.694423 h -6.632014 l 3.48989,8.264185 c 0.243325,0.573315 -0.03444,1.215518 -0.589982,1.458844 l -3.073237,1.302181 c -0.573315,0.243325 -1.215517,-0.03444 -1.458843,-0.589982 l -3.315451,-7.847531 -5.416497,5.416497 c -0.207771,0.207771 -0.486651,0.329989 -0.781086,0.329989 -0.138885,0 -0.295547,-0.03444 -0.416654,-0.08666 C 14.277769,36.160383 14,35.760396 14,35.309299 V 9.1990077 c 0,-0.4510969 0.277769,-0.8510844 0.694423,-1.0244122 0.121107,-0.052221 0.277769,-0.086664 0.416654,-0.086664 0.295546,0 0.573315,0.1044412 0.781086,0.3299896 z" />
</svg>

</template>
<script>
export default {
  name: 'PointerIcon',
};
</script>
