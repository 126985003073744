<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1078 7.95071L35.3927 17.8618C37.4708 19.1313 38.75 21.47 38.75 24C38.75 26.53 37.4708 28.8687 35.3927 30.1382L19.1078 40.0493C17.0222 41.3173 14.4526 41.3169 12.3674 40.0481C10.2822 38.7794 8.99846 36.4353 9 33.8992V14.1008C8.99846 11.5647 10.2822 9.22058 12.3674 7.95185C14.4526 6.68312 17.0222 6.68269 19.1078 7.95071ZM17.2316 36.3482L33.2857 26.4697C34.1063 25.9663 34.6108 25.027 34.6056 24.0118C34.6141 22.9895 34.1098 22.0415 33.2857 21.5304L17.2316 11.6518C16.8327 11.3942 16.3771 11.2555 15.9117 11.25C14.4417 11.25 13.25 12.5303 13.25 14.1096V33.8904C13.25 34.6488 13.5304 35.3762 14.0296 35.9124C14.5288 36.4487 15.2058 36.75 15.9117 36.75C16.3771 36.7445 16.8327 36.6058 17.2316 36.3482Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'PlayArrowIcon',
};
</script>
