<template>
  <!-- eslint-disable  -->
  <svg viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.828 6.364L7.778 11.314L6.364 12.728L0 6.364L6.364 0L7.778 1.414L2.828 6.364Z" fill="#C4C4C4" />
  </svg>
</template>
<script>
export default {
  name: 'PoolArrowIcon',
};
</script>
