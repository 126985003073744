<template>
  <!-- eslint-disable -->

  <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 2.25C13.5 1.00736 14.4529 -2.1727e-07 15.6284 0L20.3716 7.53084e-06C21.5471 7.74811e-06 22.5 1.00737 22.5 2.25001V6.75C22.5 7.99264 21.5471 9 20.3716 9L15.6284 8.99999C14.4529 8.99999 13.5 7.99263 13.5 6.74999L13.5 2.25Z"
      fill="currentColor"
      style="fill: currentColor; fill-opacity: 1"
    />
    <path
      d="M7.44279e-07 2.25C9.49804e-07 1.00736 0.952909 -2.1727e-07 2.12838 0L6.87162 7.53084e-06C8.04709 7.74811e-06 9.00001 1.00737 9.00001 2.25001L9 6.75C9 7.99264 8.04709 9 6.87162 9L2.12838 8.99999C0.952907 8.99999 -2.05526e-07 7.99263 0 6.74999L7.44279e-07 2.25Z"
      fill="currentColor"
      style="fill: currentColor; fill-opacity: 1"
    />
    <path
      d="M27 2.25C27 1.00736 27.9529 -2.1727e-07 29.1284 0L33.8716 7.53084e-06C35.0471 7.74811e-06 36 1.00737 36 2.25001V6.75C36 7.99264 35.0471 9 33.8716 9L29.1284 8.99999C27.9529 8.99999 27 7.99263 27 6.74999V2.25Z"
      fill="currentColor"
      style="fill: currentColor; fill-opacity: 1"
    />
    <path
      d="M13.5 15.75C13.5 14.5074 14.4529 13.5 15.6284 13.5L20.3716 13.5C21.5471 13.5 22.5 14.5074 22.5 15.75V20.25C22.5 21.4926 21.5471 22.5 20.3716 22.5L15.6284 22.5C14.4529 22.5 13.5 21.4926 13.5 20.25L13.5 15.75Z"
      fill="currentColor"
      style="fill: currentColor; fill-opacity: 1"
    />
    <path
      d="M7.44279e-07 15.75C9.49804e-07 14.5074 0.952909 13.5 2.12838 13.5L6.87162 13.5C8.04709 13.5 9.00001 14.5074 9.00001 15.75L9 20.25C9 21.4926 8.04709 22.5 6.87162 22.5L2.12838 22.5C0.952907 22.5 -2.05526e-07 21.4926 0 20.25L7.44279e-07 15.75Z"
      fill="currentColor"
      style="fill: currentColor; fill-opacity: 1"
    />
    <path
      d="M27 15.75C27 14.5074 27.9529 13.5 29.1284 13.5L33.8716 13.5C35.0471 13.5 36 14.5074 36 15.75V20.25C36 21.4926 35.0471 22.5 33.8716 22.5L29.1284 22.5C27.9529 22.5 27 21.4926 27 20.25V15.75Z"
      fill="currentColor"
      style="fill: currentColor; fill-opacity: 1"
    />
    <path
      d="M13.5 29.25C13.5 28.0074 14.4529 27 15.6284 27L20.3716 27C21.5471 27 22.5 28.0074 22.5 29.25V33.75C22.5 34.9926 21.5471 36 20.3716 36L15.6284 36C14.4529 36 13.5 34.9926 13.5 33.75L13.5 29.25Z"
      fill="currentColor"
      style="fill: currentColor; fill-opacity: 1"
    />
    <path
      d="M7.44279e-07 29.25C9.49804e-07 28.0074 0.952909 27 2.12838 27L6.87162 27C8.04709 27 9.00001 28.0074 9.00001 29.25L9 33.75C9 34.9926 8.04709 36 6.87162 36L2.12838 36C0.952907 36 -2.05526e-07 34.9926 0 33.75L7.44279e-07 29.25Z"
      fill="currentColor"
      style="fill: currentColor; fill-opacity: 1"
    />
    <path
      d="M27 29.25C27 28.0074 27.9529 27 29.1284 27L33.8716 27C35.0471 27 36 28.0074 36 29.25V33.75C36 34.9926 35.0471 36 33.8716 36L29.1284 36C27.9529 36 27 34.9926 27 33.75V29.25Z"
      fill="currentColor"
      style="fill: currentColor; fill-opacity: 1"
    />
  </svg>
</template>

<script>
export default {
  name: 'HamburgerIcon',
};
</script>

<style scoped>
.cls-1 {
  fill: #fff;
}
</style>
