<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M42 22.5C42 21.9477 41.5523 21.5 41 21.5H7C6.44772 21.5 6 21.9477 6 22.5V25.9C6 26.4523 6.44772 26.9 7 26.9H41C41.5523 26.9 42 26.4523 42 25.9V22.5ZM34.5 36C34.5 35.4477 34.0523 35 33.5 35H14.5C13.9477 35 13.5 35.4477 13.5 36V39.4C13.5 39.9523 13.9477 40.4 14.5 40.4H33.5C34.0523 40.4 34.5 39.9523 34.5 39.4V36Z"/>
  <path d="M42 9C42 8.44772 41.5523 8 41 8H7C6.44772 8 6 8.44772 6 9V12.4C6 12.9523 6.44772 13.4 7 13.4H41C41.5523 13.4 42 12.9523 42 12.4V9Z"/>
  </svg>

</template>
<script>
export default {
  name: 'FormatAlignCenterIcon',
};
</script>
