<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M42.134 40.909C42.5189 40.909 42.7594 40.4923 42.567 40.159L24.433 8.75C24.2406 8.41667 23.7594 8.41667 23.567 8.75L5.43301 40.159C5.24056 40.4923 5.48112 40.909 5.86603 40.909H42.134Z"/>
  </svg>


</template>
<script>
export default {
  name: 'FilledTriangleIcon',
};
</script>
