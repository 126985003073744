<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24.1682 37.7171C24.1104 37.9882 24.4198 38.186 24.6416 38.0197L42.68 24.49C42.84 24.37 42.84 24.13 42.68 24.01L24.6416 10.4803C24.4198 10.314 24.1104 10.5118 24.1682 10.7829L25.8238 18.5437C25.8636 18.7303 25.7216 18.9062 25.5308 18.9095C17.1314 19.0586 8.2579 24.2529 5.87215 31.375C5.24404 33.2501 4.95028 35.2274 5.00686 37.1935C5.01535 37.4883 5.40429 37.5791 5.56441 37.3313C8.56189 32.6935 13.9951 29.5937 20.2015 29.5937H25.5304C25.7212 29.5937 25.8636 29.7697 25.8238 29.9563L24.1682 37.7171Z"/>
  </svg>

</template>
<script>
export default {
  name: 'RedoIcon',
};
</script>
