<template>
  <!-- eslint-disable  -->
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48pt" height="48pt" viewBox="0 0 48 48" version="1.1">
    <g id="surface1">
      <path
        style=" stroke:none;fill-rule:nonzero;fill:#FFFFFF;fill-opacity:1;"
        d="M 31.65625 4.0625 C 30.101563 4.054688 28.546875 4.625 27.414063 5.757813 L 23 10.171875 L 21.414063 8.585938 L 18.585938 11.414063 L 22.171875 15 L 18.585938 18.585938 L 21.414063 21.414063 L 25 17.828125 L 30.171875 23 L 26.585938 26.585938 L 29.414063 29.414063 L 33 25.828125 L 36.585938 29.414063 L 39.414063 26.585938 L 37.828125 25 L 42.242188 20.585938 C 44.585938 18.242188 44.585938 14.445313 42.242188 12.101563 L 40.484375 10.34375 L 43.414063 7.414063 L 40.585938 4.585938 L 37.65625 7.515625 L 35.898438 5.757813 C 34.765625 4.625 33.210938 4.0625 31.65625 4.0625 Z M 11.414063 18.585938 L 8.585938 21.414063 L 10.171875 23 L 5.757813 27.414063 C 3.414063 29.757813 3.414063 33.554688 5.757813 35.898438 L 7.515625 37.65625 L 4.585938 40.585938 L 7.414063 43.414063 L 10.34375 40.484375 L 12.101563 42.242188 C 13.234375 43.375 14.742188 44 16.34375 44 C 17.945313 44 19.453125 43.375 20.585938 42.242188 L 25 37.828125 L 26.585938 39.414063 L 29.414063 36.585938 Z "
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'DisconnectedIcon',
};
</script>
