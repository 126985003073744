<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.8531 7H10.3969C7.29386 7.15872 4.89251 9.77837 5.00371 12.8835V30.4366C4.90314 33.5378 7.2987 36.1511 10.3969 36.3201H14.3192C15.1315 36.3201 15.7901 35.6615 15.7901 34.8492C15.7901 34.0368 15.1315 33.3783 14.3192 33.3783H10.3969C8.89262 33.1939 7.80701 31.8477 7.94545 30.3385V12.9619C7.80759 11.4567 8.89555 10.1164 10.3969 9.94174H37.8531C39.3545 10.1164 40.4424 11.4567 40.3046 12.9619V30.3385C40.443 31.8477 39.3574 33.1939 37.8531 33.3783H33.9308C33.1185 33.3783 32.4599 34.0368 32.4599 34.8492C32.4599 35.6615 33.1185 36.3201 33.9308 36.3201H37.8531C40.9513 36.1511 43.3469 33.5378 43.2463 30.4366V12.9619C43.3575 9.85682 40.9561 7.15872 37.8531 7Z"
    />
    <path
      d="M30.082 24.619C30.3593 24.8901 30.7394 25.0416 31.1352 25.0387C31.5293 25.043 31.9087 24.8952 32.1883 24.6285C32.7706 24.0666 32.7706 23.1587 32.1883 22.5968L25.2336 15.9197C24.9544 15.6513 24.5756 15.5003 24.1804 15.5C23.8088 15.5099 23.4544 15.6528 23.1869 15.9006L16.2322 22.5777C15.7626 22.9094 15.5376 23.4736 15.6563 24.0216C15.775 24.5696 16.2157 25.0008 16.7841 25.1252C17.3526 25.2496 17.9444 25.0444 18.2987 24.5999L22.69 20.3768V39.5692C22.69 40.3594 23.3572 41 24.1803 41C25.0034 41 25.6706 40.3594 25.6706 39.5692V20.3766L30.082 24.619Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'UploadIcon',
};
</script>
