<template>
  <!-- eslint-disable  -->
  <svg viewBox="0 0 1 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.137793 4V1.425H0.862793V4H0.137793ZM0.132793 1V0.435H0.867793V1H0.132793Z" />
  </svg>
</template>
<script>
export default {
  name: 'TooltipIcon',
};
</script>
