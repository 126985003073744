<template>
  <!-- eslint-disable  -->

  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0814 1.17059C17.3588 0.902799 17.3588 0.468629 17.0814 0.200841C16.8039 -0.066947 16.354 -0.066947 16.0765 0.200841L0.918635 14.8294C0.641157 15.0972 0.641157 15.5314 0.918635 15.7992C1.19611 16.0669 1.64599 16.0669 1.92347 15.7992L4.79875 13.0243C6.04721 13.5801 7.47649 13.9429 9 13.9429C11.48 13.9429 13.7103 12.9816 15.3174 11.7912C16.122 11.1953 16.7822 10.5334 17.2454 9.88921C17.6998 9.25732 18 8.59288 18 8C18 7.40712 17.6998 6.74268 17.2454 6.11079C16.7822 5.46663 16.122 4.80474 15.3174 4.20878C15.0611 4.0189 14.7888 3.83485 14.5023 3.6596L17.0814 1.17059ZM11.964 6.10922L10.9238 7.11309C11.057 7.38164 11.1316 7.68241 11.1316 8C11.1316 9.13613 10.1772 10.0571 9 10.0571C8.67092 10.0571 8.35926 9.98518 8.08099 9.85666L7.0408 10.8605C7.60243 11.2195 8.27592 11.4286 9 11.4286C10.9621 11.4286 12.5526 9.89355 12.5526 8C12.5526 7.3012 12.336 6.65123 11.964 6.10922Z"
      fill="currentColor"
      style="fill: currentColor; fill: color(display-p3 0.8784 0.8784 0.8784); fill-opacity: 1"
    />
    <path
      d="M9.63068 4.62529C9.71238 4.63942 9.79661 4.61576 9.85627 4.55818L11.6695 2.80831C11.8064 2.67619 11.7511 2.44644 11.5672 2.39761C10.7556 2.18207 9.89402 2.05714 9 2.05714C6.52001 2.05714 4.28969 3.01841 2.68258 4.20878C1.87798 4.80474 1.21778 5.46663 0.75457 6.11079C0.300184 6.74268 0 7.40712 0 8C0 8.59288 0.300184 9.25732 0.75457 9.88921C1.14881 10.4375 1.68575 10.9985 2.33297 11.5209C2.42958 11.5989 2.5689 11.5911 2.65824 11.5048L5.42776 8.83204C5.49064 8.77136 5.51655 8.68238 5.50093 8.5964C5.46573 8.40271 5.44737 8.20342 5.44737 8C5.44737 6.10645 7.03794 4.57143 9 4.57143C9.21523 4.57143 9.426 4.5899 9.63068 4.62529Z"
      fill="currentColor"
      style="fill: currentColor; fill: color(display-p3 0.8784 0.8784 0.8784); fill-opacity: 1"
    />
  </svg>
</template>
<script>
export default {
  name: 'AccessIcon',
};
</script>
