<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="7.2"
      cy="7.2"
      r="7.2"
      transform="matrix(-1 0 0 1 15.2 0.799988)"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 4L8 12M8 12L10 10M8 12L6 10"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowDownCircleIcon',
};
</script>
