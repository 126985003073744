<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="6" y="6" width="36" height="36" rx="0.5"/>
  </svg>

</template>
<script>
export default {
  name: 'FilledRectangleIcon',
};
</script>
