<template>
  <!-- eslint-disable  -->
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="18"
      cy="18"
      r="18"
      fill="#233348"
      style="fill: #233348; fill: color(display-p3 0.1373 0.2 0.2824); fill-opacity: 1"
    />
    <path
      d="M18 27C17.6023 26.9997 17.221 26.8414 16.94 26.56L9.94 19.56C9.35458 18.9718 9.35682 18.0204 9.945 17.435C10.5332 16.8496 11.4846 16.8518 12.07 17.44L16.5 21.8849V10.5C16.5 9.67157 17.1716 9 18 9C18.8284 9 19.5 9.67157 19.5 10.5V21.8875L23.92 17.46C24.5054 16.8718 25.4568 16.8696 26.045 17.455C26.6332 18.0404 26.6354 18.9918 26.05 19.58L19.05 26.58C18.7682 26.8516 18.3914 27.0024 18 27Z"
      fill="#466286"
      style="fill: #466286; fill: color(display-p3 0.2745 0.3843 0.5255); fill-opacity: 1"
    />
  </svg>
</template>
<script>
export default {
  name: 'ArrowScrollChat',
};
</script>
