<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.6464 21.6476C41.8417 21.8429 41.8416 22.1596 41.6462 22.3548L39.6854 24.3142C39.4901 24.5093 39.1737 24.5093 38.9784 24.3141L38.3523 23.6879C38.157 23.4927 37.8405 23.4927 37.6452 23.6879L30.1071 31.2261C30.0373 31.2959 29.9897 31.3848 29.9703 31.4816L28.6966 37.8521C28.6772 37.9489 28.6296 38.0379 28.5597 38.1077L26.3523 40.3135C26.157 40.5087 25.8405 40.5086 25.6453 40.3134L18.3536 33.0199C18.1583 32.8246 17.8417 32.8246 17.6464 33.0198L9.0198 41.6464C8.82454 41.8417 8.50796 41.8417 8.31269 41.6464L6.35355 39.6873C6.15829 39.492 6.15829 39.1755 6.35355 38.9802L14.9802 30.3536C15.1754 30.1583 15.1754 29.8417 14.9801 29.6464L7.68664 22.3547C7.49138 22.1595 7.49131 21.843 7.68647 21.6477L9.89227 19.4403C9.9621 19.3704 10.0511 19.3228 10.1479 19.3034L16.5203 18.0297C16.6171 18.0103 16.706 17.9627 16.7758 17.8929L24.314 10.3548C24.5092 10.1595 24.5092 9.84297 24.314 9.6477L23.6878 9.02156C23.4926 8.82635 23.4925 8.50986 23.6877 8.31458L25.6471 6.35382C25.8423 6.15844 26.159 6.15839 26.3543 6.35372L41.6464 21.6476Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'PinIcon',
};
</script>
