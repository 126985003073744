<template>
  <!-- eslint-disable  -->
<svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 48 48">
  <path d="M 27.17,14.83 34.34,22 H 6 v 4 H 34.34 L 27.17,33.17 30,36 42,24 30,12 Z" />
</svg>

</template>
<script>
export default {
  name: 'ArrowRightIcon',
};
</script>
