<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="9" />
  </svg>
</template>
<script>
export default {
  name: 'RecordIcon',
};
</script>
