<template>
  <!-- eslint-disable  -->
  <svg fill="#A9A8A8" viewBox="0 0 12 5" xmlns="http://www.w3.org/2000/svg">
    <rect width="12" height="2" fill="#A9A8A8" />
    <rect x="5" y="3" width="7" height="2" fill="#A9A8A8" />
  </svg>
</template>
<script>
export default {
  name: 'ShortAnswerIcon',
};
</script>
