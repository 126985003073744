<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.75 7H31.1389C28.7917 7 26.8889 8.90279 26.8889 11.25V17.8611C26.8889 20.2083 28.7917 22.1111 31.1389 22.1111H37.75C40.0972 22.1111 42 20.2083 42 17.8611V11.25C42 8.90279 40.0972 7 37.75 7ZM31.1389 19.277C30.3565 19.277 29.7222 18.6428 29.7222 17.8604V11.2493C29.7222 10.4669 30.3565 9.8326 31.1389 9.8326H37.75C38.5281 9.84277 39.1565 10.4711 39.1667 11.2493V17.8604C39.1565 18.6385 38.5281 19.2669 37.75 19.277H31.1389Z"
    />
    <path d="M22.6154 14H13.3846C12.6199 14 12 13.3284 12 12.5C12 11.6716 12.6199 11 13.3846 11H22.6154C23.3801 11 24 11.6716 24 12.5C24 13.3284 23.3801 14 22.6154 14Z" />
    <path d="M22.5789 20H7.42105C6.63623 20 6 19.3284 6 18.5C6 17.6716 6.63623 17 7.42105 17H22.5789C23.3638 17 24 17.6716 24 18.5C24 19.3284 23.3638 20 22.5789 20Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.75 25.8886H31.1389C28.7917 25.8886 26.8889 27.7914 26.8889 30.1386V36.7497C26.8889 37.8769 27.3367 38.9579 28.1337 39.7549C28.9307 40.5519 30.0117 40.9997 31.1389 40.9997H37.75C40.0972 40.9997 42 39.0969 42 36.7497V30.1386C42 27.7914 40.0972 25.8886 37.75 25.8886ZM31.1389 38.1675C30.3565 38.1675 29.7222 37.5332 29.7222 36.7508V30.1397C29.7222 29.3573 30.3565 28.7231 31.1389 28.7231H37.75C38.5281 28.7332 39.1565 29.3616 39.1667 30.1397V36.7508C39.1565 37.529 38.5281 38.1573 37.75 38.1675H31.1389Z"
    />
    <path d="M22.6154 33H13.3846C12.6199 33 12 32.3284 12 31.5C12 30.6716 12.6199 30 13.3846 30H22.6154C23.3801 30 24 30.6716 24 31.5C24 32.3284 23.3801 33 22.6154 33Z" />
    <path d="M7.42105 36H22.5789C23.3638 36 24 36.6716 24 37.5C24 38.3284 23.3638 39 22.5789 39H7.42105C6.63623 39 6 38.3284 6 37.5C6 36.6716 6.63623 36 7.42105 36Z" />
  </svg>
</template>
<script>
export default {
  name: 'TableTitleIcon',
};
</script>
