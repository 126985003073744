<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.8531 41H10.3969C7.29386 40.8413 4.89251 38.2216 5.00371 35.1165V17.5634C4.90314 14.4622 7.2987 11.8489 10.3969 11.6799H14.3192C15.1315 11.6799 15.7901 12.3385 15.7901 13.1508C15.7901 13.9632 15.1315 14.6217 14.3192 14.6217H10.3969C8.89262 14.8061 7.80701 16.1523 7.94545 17.6615V35.0381C7.80759 36.5433 8.89555 37.8836 10.3969 38.0583H37.8531C39.3545 37.8836 40.4424 36.5433 40.3046 35.0381V17.6615C40.443 16.1523 39.3574 14.8061 37.8531 14.6217H33.9308C33.1185 14.6217 32.4599 13.9632 32.4599 13.1508C32.4599 12.3385 33.1185 11.6799 33.9308 11.6799H37.8531C40.9513 11.8489 43.3469 14.4622 43.2463 17.5634V35.0381C43.3575 38.1432 40.9561 40.8413 37.8531 41Z"
    />
    <path
      d="M30.082 23.381C30.3593 23.1099 30.7394 22.9584 31.1352 22.9613C31.5293 22.957 31.9087 23.1048 32.1883 23.3715C32.7706 23.9334 32.7706 24.8413 32.1883 25.4032L25.2336 32.0803C24.9544 32.3487 24.5756 32.4997 24.1804 32.5C23.8088 32.4901 23.4544 32.3472 23.1869 32.0994L16.2322 25.4223C15.7626 25.0906 15.5376 24.5264 15.6563 23.9784C15.775 23.4304 16.2157 22.9992 16.7841 22.8748C17.3526 22.7504 17.9444 22.9556 18.2987 23.4001L22.69 27.6232V8.43081C22.69 7.64059 23.3572 7 24.1803 7C25.0034 7 25.6706 7.64059 25.6706 8.43081V27.6234L30.082 23.381Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'FileDownloadIcon',
};
</script>
