<template>
  <!-- eslint-disable  -->
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.44 134">
    <path
      fill="#ccc"
      class="cls-1"
      d="M527.72,390.19A7.69,7.69,0,0,0,520,382.5H398.41a7.69,7.69,0,0,0-7.69,7.69v83.62a7.69,7.69,0,0,0,7.69,7.69H520a7.69,7.69,0,0,0,7.69-7.69Zm-68.5-4A1.24,1.24,0,1,1,458,387.4,1.23,1.23,0,0,1,459.22,386.17Zm59.5,86.33h-119v-81h119Z"
      transform="translate(-61.72 -353.5)"
    />
    <path
      fill="#ccc"
      class="cls-1"
      d="M543.79,483.28a1.9,1.9,0,0,0-1.65-.78H473.58c-.62,0-1.13-.05-1.51.39a2.57,2.57,0,0,1-1.93.61H448.66a2.61,2.61,0,0,1-1.94-.61c-.37-.44-1.05-.39-1.5-.39H375.86a1.91,1.91,0,0,0-1.66.78,1.45,1.45,0,0,0-.2,1.61c.32.67,3.45,2.61,10.66,2.61H533.34c7.2,0,10.34-1.94,10.66-2.61A1.45,1.45,0,0,0,543.79,483.28Z"
      transform="translate(-61.72 -353.5)"
    />
    <path
      fill="#ccc"
      class="cls-1"
      d="M369,353.5H210.77c-5.17,0-10.05,3.4-10.05,8.57V462.32c0,5.17,4.88,10.18,10.05,10.18h61v10H259.4a2.5,2.5,0,0,0,0,5h60a2.5,2.5,0,0,0,0-5H306.72v-10H369c5.17,0,8.7-5,8.7-10.18V362.07C377.72,356.9,374.19,353.5,369,353.5ZM289.57,464.71a4.8,4.8,0,1,1,4.8-4.79A4.78,4.78,0,0,1,289.57,464.71Zm78.15-14.21h-157v-87h157Z"
      transform="translate(-61.72 -353.5)"
    />
    <path
      fill="#ccc"
      class="cls-1"
      d="M105.72,412.78a5.29,5.29,0,0,0-5.29-5.28H67a5.28,5.28,0,0,0-5.28,5.28v69.44A5.28,5.28,0,0,0,67,487.5h33.43a5.29,5.29,0,0,0,5.29-5.28Zm-26-2.28h7.8a1.5,1.5,0,1,1,0,3h-7.8a1.5,1.5,0,0,1,0-3Zm3.87,74.75a2.95,2.95,0,1,1,2.95-2.95A2.95,2.95,0,0,1,83.54,485.25Zm17.18-8.75h-34v-60h34Z"
      transform="translate(-61.72 -353.5)"
    />
    <path
      fill="#ccc"
      class="cls-1"
      d="M185.72,399.62a7.12,7.12,0,0,0-7.12-7.12H124.84a7.12,7.12,0,0,0-7.12,7.12v80.76a7.12,7.12,0,0,0,7.12,7.12H178.6a7.12,7.12,0,0,0,7.12-7.12ZM151,396.13a1.24,1.24,0,1,1-1.24,1.24A1.23,1.23,0,0,1,151,396.13Zm.22,89.25a3,3,0,1,1,3-3A3,3,0,0,1,151.23,485.38Zm28.49-8.88h-56v-74h56Z"
      transform="translate(-61.72 -353.5)"
    />
  </svg>
</template>
<script>
export default {
  name: 'DevicesIcon',
};
</script>
