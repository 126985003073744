<template>
  <!-- eslint-disable  -->
 
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24 6C33.9396 6 42 13.1604 42 22.0002C41.9986 24.652 40.9444 27.1947 39.0691 29.0696C37.1939 30.9445 34.651 31.9982 31.9992 31.9992H28.4604C26.8008 31.9992 25.4598 33.3402 25.4598 34.9998C25.4598 35.7594 25.7604 36.4596 26.2194 36.9798C26.7 37.5198 27.0006 38.22 27.0006 38.9994C27.0006 40.6608 25.62 42 24 42C14.0604 42 6 33.9396 6 24C6 14.0604 14.0604 6 24 6ZM15.9 24C16.6161 24 17.3028 23.7155 17.8092 23.2092C18.3155 22.7028 18.6 22.0161 18.6 21.3C18.6 20.5839 18.3155 19.8972 17.8092 19.3908C17.3028 18.8845 16.6161 18.6 15.9 18.6C15.1839 18.6 14.4972 18.8845 13.9908 19.3908C13.4845 19.8972 13.2 20.5839 13.2 21.3C13.2 22.0161 13.4845 22.7028 13.9908 23.2092C14.4972 23.7155 15.1839 24 15.9 24V24ZM32.1 24C32.8161 24 33.5028 23.7155 34.0092 23.2092C34.5155 22.7028 34.8 22.0161 34.8 21.3C34.8 20.5839 34.5155 19.8972 34.0092 19.3908C33.5028 18.8845 32.8161 18.6 32.1 18.6C31.3839 18.6 30.6972 18.8845 30.1908 19.3908C29.6845 19.8972 29.4 20.5839 29.4 21.3C29.4 22.0161 29.6845 22.7028 30.1908 23.2092C30.6972 23.7155 31.3839 24 32.1 24ZM24 18.6C24.7161 18.6 25.4028 18.3155 25.9092 17.8092C26.4155 17.3028 26.7 16.6161 26.7 15.9C26.7 15.1839 26.4155 14.4972 25.9092 13.9908C25.4028 13.4845 24.7161 13.2 24 13.2C23.2839 13.2 22.5972 13.4845 22.0908 13.9908C21.5845 14.4972 21.3 15.1839 21.3 15.9C21.3 16.6161 21.5845 17.3028 22.0908 17.8092C22.5972 18.3155 23.2839 18.6 24 18.6Z"/>
  </svg>
</template>
<script>
export default {
  name: 'PaintingPaletteIcon',
};
</script>
