<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.9249 15.1667L31.2543 20.1464C32.6079 20.9387 33.7457 22 33.7457 24C33.7457 26 32.6079 27.1181 31.2543 27.9104L22.9249 32.8723C22.2689 33.2628 21.5218 33.4603 20.7648 33.4434C19.619 33.4434 18.5202 32.9733 17.71 32.1365C16.8998 31.2997 16.4446 30.1648 16.4446 28.9813V19.0398C16.4307 17.4393 17.251 15.9548 18.5922 15.1532C19.9335 14.3516 21.5884 14.3567 22.9249 15.1667ZM21.629 30.5142L29.9584 25.5702C30.4913 25.2509 31 24.8374 31 24C31 23.1626 30.4913 22.8017 29.9584 22.4824L21.629 17.5027C21.3673 17.3423 21.0693 17.2561 20.765 17.2528C19.8106 17.2528 19.0369 18.0519 19.0369 19.0376V28.9792C19.0406 29.615 19.3714 30.2007 19.9054 30.5169C20.4394 30.8331 21.0959 30.8321 21.629 30.5142Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8 7H34.2C37.9555 7 41 10.0445 41 13.8V34.2C41 37.9555 37.9555 41 34.2 41H13.8C10.0445 41 7 37.9555 7 34.2V13.8C7 10.0445 10.0445 7 13.8 7ZM38 34.2V13.8C38 11.4852 36.5148 10 34.2 10H13.8C11.4528 10 10 11.4528 10 13.8V34.2C10 36.5148 11.4852 38 13.8 38H34.2C36.5148 38 38 36.5148 38 34.2Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'PlayOutlineIcon',
};
</script>
