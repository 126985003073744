<template>
  <!-- eslint-disable  -->

  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8199 39.0598C13.5852 38.8882 11.4619 38.0149 9.75306 36.5644C8.04424 35.114 6.83734 33.1607 6.30468 30.9834C5.77202 28.8061 5.94085 26.5162 6.78705 24.4406C7.63326 22.365 9.11357 20.6098 11.0166 19.4257C11.4216 16.2681 12.9631 13.3662 15.3529 11.2631C17.7427 9.16007 20.8168 8 24 8C27.1832 8 30.2574 9.16007 32.6471 11.2631C35.0369 13.3662 36.5784 16.2681 36.9834 19.4257C38.8864 20.6098 40.3667 22.365 41.2129 24.4406C42.0592 26.5162 42.228 28.8061 41.6953 30.9834C41.1627 33.1607 39.9558 35.114 38.2469 36.5644C36.5381 38.0149 34.4148 38.8882 32.1801 39.0598V39.0909H15.8199V39.0598ZM25.636 26.0015H30.544L24 17.8207L17.456 26.0015H22.364V32.5462H25.636V26.0015Z"
      fill="#4B4E58"
    />
  </svg>
</template>
<script>
export default {
  name: 'UploadIcon',
};
</script>
