<template>
  <!-- eslint-disable  -->

  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36">
    <g id="WebAddress">
      <path
        d="M30.242 26.181C38.923 15.838 31.407-.147 17.77.001 4.344.249-2.787 16.011 5.759 26.181H2.045A2.048 2.048 0 0 0 0 28.227v5.728A2.048 2.048 0 0 0 2.045 36h31.91A2.049 2.049 0 0 0 36 33.955v-5.728a2.048 2.048 0 0 0-2.045-2.046h-3.712Zm-5.268 0c.438-.925.803-1.882 1.091-2.864h5.163a15.084 15.084 0 0 1-2.075 2.864h-4.18Zm-6.565 0v-2.864h6.798c-.298.985-.68 1.943-1.14 2.864H18.41Zm-6.476 0c-.46-.92-.841-1.879-1.14-2.864h6.798v2.864h-5.658Zm-9.06-9.819h6.052c.024 2.07.289 4.13.791 6.137H4.351a15.085 15.085 0 0 1-1.477-6.137Zm1.495-6.955h5.35a26.265 26.265 0 0 0-.798 6.137H2.875c.055-2.129.564-4.22 1.493-6.137Zm28.758 6.137h-6.045a26.248 26.248 0 0 0-.798-6.137h5.35a14.969 14.969 0 0 1 1.493 6.137Zm-1.476 6.955h-5.365c.502-2.007.767-4.067.79-6.137h6.052A15.084 15.084 0 0 1 31.65 22.5Zm-5.393-6.137a25.574 25.574 0 0 1-.815 6.137h-7.033v-6.137h7.848Zm.005-.818H18.41V9.407h7.044a25.74 25.74 0 0 1 .808 6.137ZM18.41 8.589V.835c2.927.262 5.457 3.326 6.81 7.754h-6.81Zm-.819 0h-6.81c1.352-4.428 3.883-7.492 6.81-7.754v7.754Zm0 .818v6.137H9.738a25.74 25.74 0 0 1 .809-6.137h7.044Zm-7.847 6.955h7.847V22.5H10.56a25.56 25.56 0 0 1-.815-6.137ZM31.202 8.59h-5.145c-.979-3.288-2.59-5.908-4.549-7.353a15.246 15.246 0 0 1 9.694 7.353ZM14.488 1.24c-1.957 1.446-3.566 4.065-4.544 7.35H4.8a15.255 15.255 0 0 1 9.688-7.35ZM4.774 23.317h5.163a19.5 19.5 0 0 0 1.091 2.864h-4.18a15.086 15.086 0 0 1-2.074-2.864Zm30.409 10.637a1.23 1.23 0 0 1-1.227 1.227H2.046A1.228 1.228 0 0 1 .82 33.955v-5.728A1.229 1.229 0 0 1 2.047 27h31.909a1.228 1.228 0 0 1 1.227 1.227v5.728Z"
      />
      <path
        d="M7.434 31.4c1.317-2.044-1.394-4.285-3.153-2.574-1.705 1.756.521 4.474 2.574 3.154l1.447 1.446a.41.41 0 1 0 .578-.579l-1.446-1.446Zm-2.574-.259a1.228 1.228 0 1 1 1.737-1.736A1.228 1.228 0 0 1 4.86 31.14Zm26.231-1.687H13.083a.409.409 0 1 0 0 .819H31.09a.41.41 0 0 0 0-.819Zm-6.137 2.456H13.083a.41.41 0 0 0 0 .818h11.871a.409.409 0 1 0 0-.819Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'URLFileAdressIcon',
};
</script>
