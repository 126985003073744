<template>
  <!-- eslint-disable  -->
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="7.5" r="4.5" fill="transparent" />
    <path
      d="M7.5 15C3.35775 15 0 11.6423 0 7.5C0 3.35775 3.35775 0 7.5 0C11.6423 0 15 3.35775 15 7.5C15 11.6423 11.6423 15 7.5 15ZM7.5 6.4395L5.379 4.31775L4.31775 5.379L6.4395 7.5L4.31775 9.621L5.379 10.6823L7.5 8.5605L9.621 10.6823L10.6823 9.621L8.5605 7.5L10.6823 5.379L9.621 4.31775L7.5 6.4395Z"
      fill="#C4C4C4"
    />
  </svg>
</template>
<script>
export default {
  name: 'CloseSearchIcon',
};
</script>
