<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9099 11.3614C21.7277 8.21287 26.2723 8.21287 28.0901 11.3614L40.3602 32.6139C42.178 35.7625 39.9058 39.6981 36.2702 39.6981H11.7298C8.09423 39.6981 5.82198 35.7625 7.63978 32.6139L19.9099 11.3614ZM25.5 28V19.5C25.5 18.6716 24.8284 18 24 18C23.1716 18 22.5 18.6716 22.5 19.5V28C22.5 28.8284 23.1716 29.5 24 29.5C24.8284 29.5 25.5 28.8284 25.5 28ZM22.5 32.5C22.5 31.6716 23.1716 31 24 31C24.8284 31 25.5 31.6716 25.5 32.5C25.5 33.3284 24.8284 34 24 34C23.1716 34 22.5 33.3284 22.5 32.5Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'WarningIcon',
};
</script>
