<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8 12C8 9.79086 9.79086 8 12 8H18.2222C20.4314 8 22.2222 9.79086 22.2222 12V36C22.2222 38.2091 20.4314 40 18.2222 40H12C9.79086 40 8 38.2091 8 36V12Z"/>
  <path d="M25.7778 29.7778C25.7778 27.5686 27.5686 25.7778 29.7778 25.7778H36C38.2091 25.7778 40 27.5686 40 29.7778V36C40 38.2091 38.2091 40 36 40H29.7778C27.5686 40 25.7778 38.2091 25.7778 36V29.7778Z"/>
  <path d="M25.7778 12C25.7778 9.79086 27.5686 8 29.7778 8H36C38.2091 8 40 9.79086 40 12V18.2222C40 20.4314 38.2091 22.2222 36 22.2222H29.7778C27.5686 22.2222 25.7778 20.4314 25.7778 18.2222V12Z"/>
  </svg>

</template>
<script>
export default {
  name: 'WebIcon',
};
</script>
