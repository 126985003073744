// TODO try replacing with EventEmitter
/**
 * Private class
 */
export class EventArgument {
  /**
   *
   * @param name The name of the Event
   * @param data any data that should be passed to the handlers
   */
  constructor(name, data) {
    this.name = name;
    this.data = data;
    this.cancelled = false;
    this.removed = false;
  }

  /**
   * cancel the event, if there  is more event handlers after this one they won't be called
   */
  cancel() {
    this.cancelled = true;
  }

  /**
   * remove this event handler, future events won't triggerEvent this handler
   */
  remove() {
    this.removed = true;
  }
}

/**
 * private class for event management in components
 */
export default class EventManager {
  constructor() {
    this._events = {};
  }

  /**
   * Bind event listeners for a event that will be fired when needed
   * @param {string} name A unique name for the event
   * @param {function} fn A function to call when the event is fired
   * @returns {EventManager}
   */
  addListener(name, fn) {
    (this._events[name] = this._events[name] || []).push(fn);
    return this;
  }

  /**
   * Remove a listener from the list by name or all of them
   * @param name
   * @param fn
   * @returns {EventManager}
   */
  removeListener(name, fn) {
    if (arguments.length === 1) {
      // remove all
      this._events[name] = [];
    } else if (typeof fn === 'function') {
      let listeners = this._events[name];
      if (listeners !== undefined) {
        let foundAt = -1;
        for (let i = 0, len = listeners.length; i < len; i++) {
          if (listeners[i] === fn) {
            foundAt = i;
            break;
          }
        }

        if (foundAt >= 0) {
          listeners.splice(foundAt, 1);
        }
      }
    }

    return this;
  }

  /**
   * Trigger the event
   * @param {string} name which event to triggerEvent
   * @param {object|string|int} [data] a key,value pair that will be passed to the event handler
   * @returns {EventManager}
   */
  triggerEvent(name, data) {
    let listeners = this._events[name];
    if (listeners !== undefined) {
      for (let i = 0, len = listeners.length; i < len; i++) {
        let evt = new EventArgument(name, data);

        listeners[i].call(this, evt);

        if (evt.removed) {
          listeners.splice(i, 1);
          len = listeners.length;
          --i;
        }

        if (evt.cancelled) {
          break;
        }
      }
    }

    return this;
  }

  /**
   * check if there is any listener registered for the event name
   * @param name
   * @return {boolean}
   */
  hasListeners(name) {
    return (this._events[name] === undefined ? 0 : this._events[name].length) > 0;
  }

  get events() {
    return this._events;
  }

  set events(value) {
    this._events = value;
  }
}
