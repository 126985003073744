<template>
  <!-- eslint-disable  -->
  <svg viewBox="0 0 48 48" xml:space="preserve">
    <g>
      <rect height="32" width="10" x="26" y="8" />
      <rect height="32" width="10" x="12" y="8" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'PauseIcon',
};
</script>
