<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8 7H34.2C37.9555 7 41 10.0445 41 13.8V34.2C41 37.9555 37.9555 41 34.2 41H13.8C10.0445 41 7 37.9555 7 34.2V13.8C7 10.0445 10.0445 7 13.8 7ZM36.8249 36.8249C37.5773 36.0726 38 35.0521 38 33.9881V14.0119C38 12.9479 37.5773 11.9274 36.8249 11.1751C36.0726 10.4227 35.0521 10 33.9881 10H14.0119C11.7962 10 10 11.7962 10 14.0119V33.9881C10 35.0521 10.4227 36.0726 11.1751 36.8249C11.9274 37.5773 12.9479 38 14.0119 38H33.9881C35.0521 38 36.0726 37.5773 36.8249 36.8249Z"
    />
    <path
      d="M31.5789 22.5789H25.4211V16.4211C25.4211 15.6362 24.7848 15 24 15C23.2152 15 22.5789 15.6362 22.5789 16.4211V22.5789H16.4211C15.6362 22.5789 15 23.2152 15 24C15 24.7848 15.6362 25.4211 16.4211 25.4211H22.5789V31.5789C22.5789 32.3638 23.2152 33 24 33C24.7848 33 25.4211 32.3638 25.4211 31.5789V25.4211H31.5789C32.3638 25.4211 33 24.7848 33 24C33 23.2152 32.3638 22.5789 31.5789 22.5789Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'AddFileIcon',
};
</script>
