<template>
  <!-- eslint-disable  -->

<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M32.2247 37.3929C32.4875 37.3929 32.7006 37.6082 32.7006 37.8737V41.5182C32.7006 41.9259 33.1713 42.1486 33.4813 41.8876L40.8288 35.7015C41.0571 35.5092 41.0571 35.1549 40.8288 34.9627L33.4813 28.7765C33.1713 28.5155 32.7006 28.7382 32.7006 29.1459V32.7904C32.7006 33.056 32.4875 33.2713 32.2247 33.2713L7.47594 33.2713C7.21308 33.2713 7 33.4866 7 33.7521L7 36.912C7 37.1776 7.21308 37.3929 7.47594 37.3929H32.2247Z"/>
  <path d="M16.6445 22.1945C16.6982 22.1945 16.7417 22.2382 16.7417 22.2921V29.6367C16.7417 29.8397 16.9056 30.0044 17.1078 30.0044H21.0489C21.2511 30.0044 21.415 29.8397 21.415 29.6367L21.415 11.2764L21.415 6H17.2913C15.4246 6 13.7855 6.34347 12.374 7.0304C10.9512 7.71733 9.85847 8.69621 9.09583 9.96704C8.33319 11.2264 7.95188 12.6633 7.95188 14.2775C7.95188 16.7276 8.7885 18.6625 10.4617 20.0821C12.1236 21.4903 13.6964 22.1945 16.6445 22.1945Z"/>
  <path d="M29.819 30.0044C30.0212 30.0044 30.1851 29.8397 30.1851 29.6367L30.1851 10.5408C30.1851 10.3377 30.349 10.1731 30.5512 10.1731H36.142C36.3442 10.1731 36.5081 10.0085 36.5081 9.80541L36.5081 6.36771C36.5081 6.16463 36.3442 6 36.142 6H21.415L21.415 11.2764V10.654C21.415 10.3884 21.6281 10.1731 21.891 10.1731H24.9467C25.1489 10.1731 25.3128 10.3377 25.3128 10.5408L25.3128 29.6367C25.3128 29.8397 25.4767 30.0044 25.6789 30.0044H29.819Z"/>
</svg>

</template>
<script>
export default {
  name: 'LtrIcon',
};
</script>
