<template>
  <!-- eslint-disable  -->
  <svg viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="1.37679" cy="1" rx="0.968586" ry="1" fill="#C4C4C4" />
    <ellipse cx="4.28304" cy="1" rx="0.968586" ry="1" fill="#C4C4C4" />
    <ellipse cx="1.37679" cy="4" rx="0.968586" ry="1" fill="#C4C4C4" />
    <ellipse cx="4.28304" cy="4" rx="0.968586" ry="1" fill="#C4C4C4" />
  </svg>
</template>
<script>
export default {
  name: 'DragIcon',
};
</script>
