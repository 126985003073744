<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M23.8318 37.7171C23.8896 37.9882 23.5802 38.186 23.3584 38.0197L5.31997 24.49C5.15998 24.37 5.15998 24.13 5.31997 24.01L23.3584 10.4803C23.5802 10.314 23.8896 10.5118 23.8318 10.7829L22.1762 18.5437C22.1364 18.7303 22.2784 18.9062 22.4692 18.9095C30.8686 19.0586 39.7421 24.2529 42.1279 31.375C42.756 33.2501 43.0497 35.2274 42.9931 37.1935C42.9847 37.4883 42.5957 37.5791 42.4356 37.3313C39.4381 32.6935 34.0049 29.5937 27.7985 29.5937H22.4696C22.2788 29.5937 22.1364 29.7697 22.1762 29.9563L23.8318 37.7171Z"/>
  </svg>

</template>
<script>
export default {
  name: 'UndoIcon',
};
</script>
