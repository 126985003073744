<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" width="12.034" height="9.855" viewBox="0 0 12.034 9.855">
    <g id="Group_1718" transform="translate(-376.818 -160)">
      <g id="arrow-pointing-left" transform="translate(376.818 160)">
        <g id="Group_1717">
          <path
            id="Path_620"
            d="M111.432 5.1l5.742 4.69c.143.115.208.068.145-.1l-1.524-4.482a1.05 1.05 0 0 1 0-.623l1.522-4.418c.063-.172 0-.219-.145-.105l-5.739 4.627a.252.252 0 0 0-.001.411z"
            class="cls-1"
            transform="translate(-111.325)"
          />
        </g>
      </g>
      <g id="arrow-pointing-left-2" transform="translate(382.835 160)">
        <g id="Group_1717-2">
          <path
            id="Path_620-2"
            d="M111.432 5.1l5.742 4.69c.143.115.208.068.145-.1l-1.524-4.482a1.05 1.05 0 0 1 0-.623l1.522-4.418c.063-.172 0-.219-.145-.105l-5.739 4.627a.252.252 0 0 0-.001.411z"
            class="cls-1"
            transform="translate(-111.325)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArrowIcon',
};
</script>

<style scoped>
.cls-1 {
  fill: #3aa3ff;
}
</style>
