<template>
  <!-- eslint-disable  -->
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.16782 34.8063C10.7258 40.3369 16.9351 44 24 44C31.0649 44 37.2742 40.3368 40.8322 34.8063C39.762 31.4393 36.6103 29 32.8889 29H32.1314C31.7214 29 31.314 29.0648 30.9243 29.1921L29.0009 29.8201C25.7514 30.8812 22.2486 30.8812 18.9991 29.8201L17.0757 29.1921C16.686 29.0648 16.2786 29 15.8686 29H15.1111C11.3897 29 8.23802 31.4393 7.16782 34.8063ZM15.6667 18.4444C15.6667 13.8421 19.3976 10.1111 24 10.1111C28.6024 10.1111 32.3333 13.8421 32.3333 18.4444C32.3333 23.0468 28.6024 26.7778 24 26.7778C19.3976 26.7778 15.6667 23.0468 15.6667 18.4444Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'PersonIcon',
};
</script>
