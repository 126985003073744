<template>
  <!-- eslint-disable  -->
  
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M24 43C34.4934 43 43 34.4934 43 24C43 13.5066 34.4934 5 24 5C13.5066 5 5 13.5066 5 24C5 34.4934 13.5066 43 24 43ZM24 37.8182C31.6316 37.8182 37.8182 31.6316 37.8182 24C37.8182 16.3684 31.6316 10.1818 24 10.1818C16.3684 10.1818 10.1818 16.3684 10.1818 24C10.1818 31.6316 16.3684 37.8182 24 37.8182Z"/>
  </svg>

</template>
<script>
export default {
  name: 'CircleIcon',
};
</script>
