<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.6515 12.9299L35.0228 5.30109C34.7895 5.10115 34.4908 4.99407 34.1836 5.00025L12.5208 5.00024C8.36718 5.00024 5 8.36743 5 12.5211V35.4792C5 39.6328 8.36718 43 12.5208 43L35.479 43C39.6327 43 42.9998 39.6328 42.9998 35.4792V13.8165C43.0052 13.4866 42.88 13.1679 42.6515 12.9299ZM7.37499 35.4792C7.38368 38.3175 9.68246 40.6163 12.5208 40.625L35.479 40.625C38.3174 40.6163 40.6161 38.3175 40.6248 35.4792V15.004H36.0909C34.3636 15.004 32.9961 13.6364 32.9961 11.9091V7.37525L12.5208 7.37524C9.68246 7.38394 7.38368 9.68272 7.37499 12.5211V35.4792Z"
      fill="#43566D"
    />
  </svg>
</template>
<script>
export default {
  name: 'RecordFirstPartIcon',
};
</script>
