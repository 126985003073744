<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 287 235">
    <defs>
      <linearGradient id="linear-gradient" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#b4ebfb" />
        <stop offset="1" stop-color="#488eff" />
      </linearGradient>
    </defs>
    <g id="Group_1702" transform="translate(-1433 -287)">
      <g id="Group_1606" transform="translate(1433 287.411)">
        <rect id="Rectangle_385" width="287" height="192" class="cls-1" rx="40" transform="translate(0 -.411)" />
        <path
          id="Polygon_19"
          d="M25.128 17.821a12 12 0 0 1 20.743 0L60.5 42.964A12 12 0 0 1 50.132 61H20.868A12 12 0 0 1 10.5 42.964z"
          class="cls-1"
          transform="rotate(180 89.5 117.294)"
        />
      </g>
      <g id="video-camera" transform="translate(1525.309 353.705)">
        <g id="Group_1609">
          <g id="Group_1608">
            <path
              id="Path_551"
              d="M57.734 96.512H11.167A11.2 11.2 0 0 0 0 107.679v37.633a11.2 11.2 0 0 0 11.167 11.167h46.567A11.2 11.2 0 0 0 68.9 145.312v-37.633a11.128 11.128 0 0 0-11.166-11.167z"
              class="cls-2"
              transform="translate(0 -96.512)"
            />
          </g>
        </g>
        <g id="Group_1611" transform="translate(74.484 5.962)">
          <g id="Group_1610">
            <path
              id="Path_552"
              d="M360.823 124.027a5.688 5.688 0 0 0-1.9.782l-17.423 10.05v25.908l17.532 10.05a6.639 6.639 0 0 0 9.157-2.457 6.822 6.822 0 0 0 .893-3.35V130.5a6.721 6.721 0 0 0-8.259-6.473z"
              class="cls-2"
              transform="translate(-341.504 -123.847)"
            />
          </g>
        </g>
      </g>
      <circle id="Ellipse_88" cx="39" cy="39" r="39" fill="#fff" opacity="0.43" transform="translate(1487 315)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SlideCameraIcon',
};
</script>

<style scoped>
.cls-1 {
  fill: #fff;
}
.cls-2 {
  fill: url(#linear-gradient);
}
</style>
