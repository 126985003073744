<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 24C7 14.6112 14.6112 7 24 7C33.3888 7 41 14.6112 41 24C41 33.3888 33.3888 41 24 41C14.6112 41 7 33.3888 7 24ZM24 18.3333C22.9568 18.3333 22.1111 17.4876 22.1111 16.4444C22.1111 15.4012 22.9568 14.5556 24 14.5556C25.0432 14.5556 25.8889 15.4012 25.8889 16.4444C25.8889 17.4876 25.0432 18.3333 24 18.3333ZM25.8889 31.5556V22.1111C25.8889 21.0679 25.0432 20.2222 24 20.2222C22.9568 20.2222 22.1111 21.0679 22.1111 22.1111V31.5556C22.1111 32.5988 22.9568 33.4444 24 33.4444C25.0432 33.4444 25.8889 32.5988 25.8889 31.5556Z"/>
  </svg>
</template>
<script>
export default {
  name: 'ErrorOutlineIcon',
};
</script>
