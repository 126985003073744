<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.8116 16.4773L23.8403 6.09223C24.0369 5.88867 24.3631 5.88867 24.5597 6.09223L34.5884 16.4773C36.6456 18.6077 38.0484 21.3239 38.6169 24.2835C39.1854 27.2432 38.8935 30.3108 37.7787 33.0977C36.664 35.8845 34.7774 38.2636 32.3601 39.9362C29.9431 41.6085 27.1034 42.5 24.2 42.5C21.2966 42.5 18.4569 41.6085 16.0399 39.9362C13.6226 38.2636 11.736 35.8845 10.6213 33.0977C9.50652 30.3108 9.21458 27.2432 9.78308 24.2835C10.3516 21.3239 11.7544 18.6077 13.8116 16.4773Z" stroke="black"/>
  </svg>

</template>
<script>
export default {
  name: 'EditPoolIcon',
};
</script>
