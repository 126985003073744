<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M25.934 13.0961L34.9039 22.0681L13.9699 43H5V34.028L25.934 13.0961ZM28.9239 10.1062L33.4089 5.61911C33.8054 5.22269 34.3432 5 34.9039 5C35.4646 5 36.0023 5.22269 36.3988 5.61911L42.3809 11.6012C42.7773 11.9977 43 12.5354 43 13.0961C43 13.6568 42.7773 14.1946 42.3809 14.5911L37.8938 19.0761L28.9239 10.1062Z"/>
  </svg>
</template>
<script>
export default {
  name: 'PenIcon',
};
</script>
