<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.047 30.0382L16.9252 25.9085C17.053 25.3346 17.341 24.8094 17.7552 24.3946L32.5881 9.58074C33.9961 8.13673 36.4836 7.67162 38.0134 9.18108C39.5432 10.6905 39.2037 13.2348 37.6164 14.643L22.7835 29.5296C22.3715 29.9466 21.8498 30.2365 21.2798 30.3652L17.1778 31.2493H16.9492C16.6493 31.2609 16.3629 31.1235 16.1829 30.8818C16.0028 30.6401 15.952 30.3248 16.047 30.0382Z"
      fill="currentColor"
    />
    <path
      d="M7.99989 13.0002C7.99989 10.2388 10.2385 8.00021 12.9999 8.00022L25.2553 8.00022C26.1872 8.00022 26.9427 8.75573 26.9427 9.6877C26.9427 10.6197 26.1872 11.3752 25.2553 11.3752L12.4713 11.3752C11.919 11.3752 11.4713 11.8229 11.4713 12.3752L11.4713 34.875C11.4713 35.4273 11.919 35.875 12.4713 35.875L34.986 35.875C35.5381 35.875 35.9858 35.4276 35.986 34.8754L35.9903 24.2324C35.9907 23.2754 36.7666 22.4998 37.7237 22.4998C38.681 22.4998 39.457 23.2758 39.457 24.2332L39.457 34.2498C39.457 37.0112 37.2185 39.2498 34.4571 39.2498L12.9999 39.25C10.2385 39.25 7.99989 37.0114 7.99989 34.25L7.99989 13.0002Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ModeEditIcon',
};
</script>
