<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24ZM24 40.4091C33.0625 40.4091 40.4091 33.0625 40.4091 24C40.4091 14.9375 33.0625 7.59091 24 7.59091C14.9375 7.59091 7.59091 14.9375 7.59091 24C7.59091 33.0625 14.9375 40.4091 24 40.4091Z"
      fill="#43566D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.5244 15H16.4756C14.4749 15.0975 12.9244 16.8541 13.0029 18.9342V26.6205C12.9244 28.7006 14.4749 30.4572 16.4756 30.5547H20.7009C20.7009 30.5547 20.1084 32.1711 19.879 32.8303C19.7606 33.1323 19.7862 33.4746 19.9479 33.754C20.1097 34.0334 20.3887 34.2172 20.7009 34.25H27.2991C27.6113 34.2172 27.8903 34.0334 28.052 33.754C28.2138 33.4746 28.2394 33.1323 28.121 32.8303C27.8916 32.1711 27.2991 30.5547 27.2991 30.5547H31.5244C33.5251 30.4572 35.0757 28.7006 34.9971 26.6205V18.9342C35.0757 16.8541 33.5251 15.0975 31.5244 15ZM21.8584 32.4453L22.4372 31.1563H25.4701L26.0489 32.4453H21.8584ZM31.5244 28.75C32.5636 28.6481 33.3345 27.7027 33.2608 26.6205V18.9342C33.3345 17.852 32.5636 16.9065 31.5244 16.8047H16.4756C15.4364 16.9065 14.6655 17.852 14.7392 18.9342V26.6205C14.6655 27.7027 15.4364 28.6481 16.4756 28.75H31.5244Z"
      fill="#E0E0E0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.0344 18.7125C25.0344 18.4572 24.9254 18.2124 24.7315 18.0319C24.5375 17.8514 24.2743 17.75 24 17.75C23.7257 17.75 23.4625 17.8514 23.2685 18.0319C23.0746 18.2124 22.9656 18.4572 22.9656 18.7125V24.0892L21.6281 22.8446C21.433 22.6693 21.1717 22.5723 20.9004 22.5745C20.6292 22.5767 20.3698 22.6779 20.178 22.8563C19.9862 23.0348 19.8774 23.2762 19.875 23.5286C19.8727 23.781 19.9769 24.0241 20.1654 24.2056L23.2687 27.0932C23.4626 27.2736 23.7257 27.375 24 27.375C24.2743 27.375 24.5374 27.2736 24.7313 27.0932L27.8346 24.2056C28.0231 24.0241 28.1273 23.781 28.125 23.5286C28.1226 23.2762 28.0138 23.0348 27.822 22.8563C27.6302 22.6779 27.3708 22.5767 27.0996 22.5745C26.8283 22.5723 26.567 22.6693 26.3719 22.8446L25.0344 24.0892V18.7125Z"
      fill="#E0E0E0"
    />
  </svg>
</template>
<script>
export default {
  name: 'LocalRecordingIcon',
};
</script>
