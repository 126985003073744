<template>
  <!-- eslint-disable  -->
  
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 22.5C6 21.9477 6.44772 21.5 7 21.5H41C41.5523 21.5 42 21.9477 42 22.5V25.9C42 26.4523 41.5523 26.9 41 26.9H7C6.44772 26.9 6 26.4523 6 25.9V22.5ZM20.4 36C20.4 35.4477 20.8477 35 21.4 35H41C41.5523 35 42 35.4477 42 36V39.4C42 39.9523 41.5523 40.4 41 40.4H21.4C20.8477 40.4 20.4 39.9523 20.4 39.4V36Z"/>
  <path d="M6 9C6 8.44772 6.44772 8 7 8H41C41.5523 8 42 8.44772 42 9V12.4C42 12.9523 41.5523 13.4 41 13.4H7C6.44772 13.4 6 12.9523 6 12.4V9Z"/>
  </svg>

</template>
<script>
export default {
  name: 'FormatAlignRightIcon',
};
</script>
