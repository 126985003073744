///////////////////////////////////////////////////////////
/////////////////// WARNING ///////////////////////////////
// do not use this file anymore
// import each icon separately where ever its needed
// TODO switch to use https://github.com/jpkleemans/vite-svg-loader
///////////////////////////////////////////////////////////
import { markRaw } from 'vue';
import AccessTimeIconRaw from './AccessTimeIcon.vue';
import AccountIconRaw from './AccountIcon.vue';
import AddIconRaw from './AddIcon.vue';
import ArrowBackIconRaw from './ArrowBackIcon.vue';
import ArrowDownIconRaw from './ArrowDownIcon.vue';
import ArrowDropDownIconRaw from './ArrowDropDownIcon.vue';
import ArrowForwardIconRaw from './ArrowForwardIcon.vue';
import ArrowIconRaw from './ArrowIcon.vue';
import ArrowRightIconRaw from './ArrowRightIcon.vue';
import AspectRatioIconRaw from './AspectRatioIcon.vue';
import AttachFileIconRaw from './AttachFileIcon.vue';
import AudiotrackIconRaw from './AudiotrackIcon.vue';
import AutorenewIconRaw from './AutorenewIcon.vue';
import BlockIconRaw from './BlockIcon.vue';
import BrushIconRaw from './BrushIcon.vue';
import CachedIconRaw from './CachedIcon.vue';
import ChatIconRaw from './ChatIcon.vue';
import CheckBoxIconRaw from './CheckBoxIcon.vue';
import CheckBoxOutlineBlankIconRaw from './CheckBoxOutlineBlankIcon.vue';
import CheckIconRaw from './CheckIcon.vue';
import CircleIconRaw from './CircleIcon.vue';
import ClearAllIconRaw from './ClearAllIcon.vue';
import ClearIconRaw from './ClearIcon.vue';
import CloseIconRaw from './CloseIcon.vue';
import ColorizeIconRaw from './ColorizeIcon.vue';
import ColorLensIconRaw from './ColorLensIcon.vue';
import ContentCopyIconRaw from './ContentCopyIcon.vue';
import CropPortraitIconRaw from './CropPortraitIcon.vue';
import CropSquareIconRaw from './CropSquareIcon.vue';
import DeleteIconRaw from './DeleteIcon.vue';
import DoneIconSquareRaw from './DoneIconSquare.vue';
import DeleteSweepIconRaw from './DeleteSweepIcon.vue';
import DesktopWindowsIconRaw from './DesktopWindowsIcon.vue';
import DevicesIconRaw from './DevicesIcon.vue';
import DisconnectedIconRaw from './DisconnectedIcon.vue';
import DoneIconRaw from './DoneIcon.vue';
import EnterIconRaw from './EnterIcon.vue';
import EraserIconRaw from './EraserIcon.vue';
import ErrorIconRaw from './ErrorIcon.vue';
import ErrorOutlineIconRaw from './ErrorOutlineIcon.vue';
import ExitIconRaw from './ExitIcon.vue';
import ExpandLessIconRaw from './ExpandLessIcon.vue';
import ExpandMoreIconRaw from './ExpandMoreIcon.vue';
import FileDownloadIconRaw from './FileDownloadIcon.vue';
import FileUploadIconRaw from './FileUploadIcon.vue';
import FilledCircleIconRaw from './FilledCircleIcon.vue';
import FilledRectangleIconRaw from './FilledRectangleIcon.vue';
import FilledTriangleIconRaw from './FilledTriangleIcon.vue';
import FilterNoneIconRaw from './FilterNoneIcon.vue';
import FlipToBackIconRaw from './FlipToBackIcon.vue';
import FlipToFrontIconRaw from './FlipToFrontIcon.vue';
import FolderOpenIconRaw from './FolderOpenIcon.vue';
import FontDownloadIconRaw from './FontDownloadIcon.vue';
import FormatAlignCenterIconRaw from './FormatAlignCenterIcon.vue';
import FormatAlignJustifyIconRaw from './FormatAlignJustifyIcon.vue';
import FormatAlignLeftIconRaw from './FormatAlignLeftIcon.vue';
import FormatAlignRightIconRaw from './FormatAlignRightIcon.vue';
import FormatBoldIconRaw from './FormatBoldIcon.vue';
import FormatColorFillIconRaw from './FormatColorFillIcon.vue';
import FormatColorResetIconRaw from './FormatColorResetIcon.vue';
import FormatItalicIconRaw from './FormatItalicIcon.vue';
import FormatSizeIconRaw from './FormatSizeIcon.vue';
import FormatStrikethroughIconRaw from './FormatStrikethroughIcon.vue';
import FormatUnderlinedIconRaw from './FormatUnderlinedIcon.vue';
import FullscreenExitIconRaw from './FullscreenExitIcon.vue';
import FullscreenIconRaw from './FullscreenIcon.vue';
import GroupIconRaw from './GroupIcon.vue';
import HamburgerIconRaw from './HamburgerIcon.vue';
import HandIconRaw from './HandIcon.vue';
import HandOffIconRaw from './HandOffIcon.vue';
import HelpIconRaw from './HelpIcon.vue';
import HighlightOffIconRaw from './HighlightOffIcon.vue';
import HLineIconRaw from './HLineIcon.vue';
import HourGlassIconRaw from './HourGlassIcon.vue';
import ImageIconRaw from './ImageIcon.vue';
import ImportExportIconRaw from './ImportExportIcon.vue';
import InfoIconRaw from './InfoIcon.vue';
import InfoOutlineIconRaw from './InfoOutlineIcon.vue';
import InsertDriveFileIconRaw from './InsertDriveFileIcon.vue';
import InsertEmoticonIconRaw from './InsertEmoticonIcon.vue';
import KeyboardArrowLeftIconRaw from './KeyboardArrowLeftIcon.vue';
import KeyboardArrowRightIconRaw from './KeyboardArrowRightIcon.vue';
import LanguageIconRaw from './LanguageIcon.vue';
import LaptopIconRaw from './LaptopIcon.vue';
import LockIconRaw from './LockIcon.vue';
import MarkerPenIconRaw from './MarkerPenIcon.vue';
import MenuIconRaw from './MenuIcon.vue';
import MessageIconRaw from './MessageIcon.vue';
import MicIconRaw from './MicIcon.vue';
import MicOffIconRaw from './MicOffIcon.vue';
import ModeCommentIconRaw from './ModeCommentIcon.vue';
import ModeEditIconRaw from './ModeEditIcon.vue';
import MoonIconRaw from './MoonIcon.vue';
import MoreVertIconRaw from './MoreVertIcon.vue';
import MusicVideoIconRaw from './MusicVideoIcon.vue';
import NavigateBeforeIconRaw from './NavigateBeforeIcon.vue';
import NavigateNextIconRaw from './NavigateNextIcon.vue';
import NetworkCheckIconRaw from './NetworkCheckIcon.vue';
import NotificationsIconRaw from './NotificationsIcon.vue';
import PageviewIconRaw from './PageviewIcon.vue';
import PanIconRaw from './PanIcon.vue';
import PauseIconRaw from './PauseIcon.vue';
import PersonIconRaw from './PersonIcon.vue';
import PersonOutlineIconRaw from './PersonOutlineIcon.vue';
import PlayArrowIconRaw from './PlayArrowIcon.vue';
import PlayOutlineIconRaw from './PlayOutlineIcon.vue';
import PointerIconRaw from './PointerIcon.vue';
import PowerSettingsNewIconRaw from './PowerSettingsNewIcon.vue';
import RecentActorsIconRaw from './RecentActorsIcon.vue';
import RectangleIconRaw from './RectangleIcon.vue';
import RedoIconRaw from './RedoIcon.vue';
import RefreshIconRaw from './RefreshIcon.vue';
import RemoveCircleOutlineIconRaw from './RemoveCircleOutlineIcon.vue';
import RepeatIconRaw from './RepeatIcon.vue';
import ReplyIconRaw from './ReplyIcon.vue';
import SaveIconRaw from './SaveIcon.vue';
import ScheduleIconRaw from './ScheduleIcon.vue';
import SearchIconRaw from './SearchIcon.vue';
import SendIconRaw from './SendIcon.vue';
import SettingsIconRaw from './SettingsIcon.vue';
import SlideCameraIconRaw from './SlideCameraIcon.vue';
import SlideshowIconRaw from './SlideshowIcon.vue';
import SpinnerIconRaw from './SpinnerIcon.vue';
import StopIconRaw from './StopIcon.vue';
import SunIconRaw from './SunIcon.vue';
import SyncIconRaw from './SyncIcon.vue';
import ThumbDownIconRaw from './ThumbDownIcon.vue';
import TimeElapsedIconRaw from './TimeElapsedIcon.vue';
import TimelapseIconRaw from './TimelapseIcon.vue';
import TimerIconRaw from './TimerIcon.vue';
import TimerOffIconRaw from './TimerOffIcon.vue';
import TitleIconRaw from './TitleIcon.vue';
import TriangleIconRaw from './TriangleIcon.vue';
import UndoIconRaw from './UndoIcon.vue';
import VideocamIconRaw from './VideocamIcon.vue';
import VisibilityIconRaw from './VisibilityIcon.vue';
import VolumeDownIconRaw from './VolumeDownIcon.vue';
import VolumeMuteIconRaw from './VolumeMuteIcon.vue';
import VolumeOffIconRaw from './VolumeOffIcon.vue';
import VolumeUpIconRaw from './VolumeUpIcon.vue';
import VpnKeyIconRaw from './VpnKeyIcon.vue';
import WarningIconRaw from './WarningIcon.vue';
import WebIconRaw from './WebIcon.vue';
import ZoomInIconRaw from './ZoomInIcon.vue';
import ZoomOutIconRaw from './ZoomOutIcon.vue';
import DeviceIconRaw from './Devices.vue';
import PoolIconRaw from './PoolIcon.vue';
import EditPoolIconRaw from './EditPoolIcon.vue';
import PoolSearchIconRaw from './PoolSearchIcon.vue';
import CloseSearchIconRaw from './CloseSearchIcon.vue';
import AddQuestionIconRaw from './AddQuestionIcon.vue';
import TooltipIconRaw from './TooltipIcon.vue';
import PoolArrowIconRaw from './PoolArrowIcon.vue';
import ShortAnswerIconRaw from './ShortAnswerIcon.vue';
import SquareIconRaw from './SquareIcon.vue';
import DragIconRaw from './DragIcon.vue';
import ErrorWarningIconRaw from './ErrorWarningIcon.vue';
import ExportIconRaw from './ExportIcon.vue';
import UploadIconRaw from './UploadIcon.vue';
import DoubleArrowIconRaw from './DoubleArrowIcon.vue';
import WebcamPlaceholderRaw from './WebcamPlaceholder.vue';
import TextIconRaw from './TextIcon.vue';
import PaintBrushIconRaw from './PaintBrushIcon.vue';
import PaintingPaletteIconRaw from './PaintingPaletteIcon.vue';
import DropIconRaw from './DropIcon.vue';
import TrashIconRaw from './TrashIcon.vue';
import PenIconRaw from './PenIcon.vue';
import CheckedIconRaw from './CheckedIcon.vue';
import FontIconRaw from './FontIcon.vue';
import RtlIconRaw from './RtlIcon.vue';
import LtrIconRaw from './LtrIcon.vue';
import FontSizeIconRaw from './FontSizeIcon.vue';
import WhiteboardIconRaw from './WhiteboardIcon.vue';
import VideocamOffIconRaw from './VideocamOffIcon.vue';
import PreviewIconRaw from './PreviewIcon.vue';
import FileListIconRaw from './FileListIcon.vue';
import FilePlayUrlIconRaw from './FilePlayUrlIcon.vue';
import TableTitleIconRaw from './TableTitleIcon.vue';
import PinMessageIconRaw from './PinMessageIcon.vue';
import AudioRecIconRaw from './AudioRecIcon.vue';
import URLFileAdressIconRaw from './URLFileAdressIcon.vue';
import AddFileIconRaw from './AddFileIcon.vue';
import UploadCloudIconRaw from './UploadCloudIcon.vue';
import PauseCircleIconRaw from './PauseCircleIcon.vue';
import PlayCircleIconRaw from './PlayCircleIcon.vue';
import StopCircleIconRaw from './StopCircleIcon.vue';
import RecordCircleIconRaw from './RecordCircleIcon.vue';
import RecordIconRaw from './RecordIcon.vue';
import BgBlurIconRaw from './BgBlurIcon.vue';
import ForbiddenIconRaw from './ForbiddenIcon.vue';
import SettingFillIconRaw from './SettingFillIcon.vue';
import ServerRecordIconRaw from './ServerRecordIcon.vue';
import RecordMorePartsIconRaw from './RecordMorePartsIcon.vue';
import RecordFirstPartIconRaw from './RecordFirstPartIcon.vue';
import LocalRecordIconRaw from './LocalRecordIcon.vue';
import LocalRecordingIconRaw from './LocalRecordingIcon.vue';
import CommentIconRaw from './CommentIcon.vue';
import FeedbackIconRaw from './FeedbackIcon.vue';
import FillFeedbackIconRaw from './FillFeedbackIcon.vue';
import CloudRecordCircleIconRaw from './CloudRecordCircleIcon.vue';
import LocalRecordCircleIconRaw from './LocalRecordCircleIcon.vue';
import RecordPauseCircleIconRaw from './RecordPauseCircleIcon.vue';
import ArrowScrollChatIconRaw from './ArrowScrollChat.vue';
import TelecommunicationTowerRaw from './TelecommunicationTowerIcon.vue';
import DisabledChatRaw from '@/assets/icons/DisabledChatIcon.vue';
import PinIconRaw from './PinIcon.vue';
import ArrowUpCircleIconRaw from './ArrowUpCircleIcon.vue';
import ArrowDownCircleIconRaw from './ArrowDownCircleIcon.vue';
import QuestionMarkCircleIconRaw from './QuestionMarkCircleIcon.vue';
import AccessIconRaw from './AccessIcon.vue';
import ArrowDownOutlineIconRaw from './ArrowDownOutlineIcon.vue';

export const AccessIcon = markRaw(AccessIconRaw);
export const AccessTimeIcon = markRaw(AccessTimeIconRaw);
export const AccountIcon = markRaw(AccountIconRaw);
export const AddIcon = markRaw(AddIconRaw);
export const ArrowBackIcon = markRaw(ArrowBackIconRaw);
export const ArrowDownIcon = markRaw(ArrowDownIconRaw);
export const ArrowDropDownIcon = markRaw(ArrowDropDownIconRaw);
export const ArrowForwardIcon = markRaw(ArrowForwardIconRaw);
export const ArrowIcon = markRaw(ArrowIconRaw);
export const ArrowRightIcon = markRaw(ArrowRightIconRaw);
export const AspectRatioIcon = markRaw(AspectRatioIconRaw);
export const AttachFileIcon = markRaw(AttachFileIconRaw);
export const AudiotrackIcon = markRaw(AudiotrackIconRaw);
export const AutorenewIcon = markRaw(AutorenewIconRaw);
export const BlockIcon = markRaw(BlockIconRaw);
export const BrushIcon = markRaw(BrushIconRaw);
export const CachedIcon = markRaw(CachedIconRaw);
export const ChatIcon = markRaw(ChatIconRaw);
export const CheckBoxIcon = markRaw(CheckBoxIconRaw);
export const CheckBoxOutlineBlankIcon = markRaw(CheckBoxOutlineBlankIconRaw);
export const CheckIcon = markRaw(CheckIconRaw);
export const CircleIcon = markRaw(CircleIconRaw);
export const ClearAllIcon = markRaw(ClearAllIconRaw);
export const ClearIcon = markRaw(ClearIconRaw);
export const CloseIcon = markRaw(CloseIconRaw);
export const ColorizeIcon = markRaw(ColorizeIconRaw);
export const ColorLensIcon = markRaw(ColorLensIconRaw);
export const ContentCopyIcon = markRaw(ContentCopyIconRaw);
export const CropPortraitIcon = markRaw(CropPortraitIconRaw);
export const CropSquareIcon = markRaw(CropSquareIconRaw);
export const DeleteIcon = markRaw(DeleteIconRaw);
export const DoneIconSquare = markRaw(DoneIconSquareRaw);
export const DeleteSweepIcon = markRaw(DeleteSweepIconRaw);
export const DesktopWindowsIcon = markRaw(DesktopWindowsIconRaw);
export const DevicesIcon = markRaw(DevicesIconRaw);
export const DisconnectedIcon = markRaw(DisconnectedIconRaw);
export const DoneIcon = markRaw(DoneIconRaw);
export const EnterIcon = markRaw(EnterIconRaw);
export const EraserIcon = markRaw(EraserIconRaw);
export const ErrorIcon = markRaw(ErrorIconRaw);
export const ErrorOutlineIcon = markRaw(ErrorOutlineIconRaw);
export const ExitIcon = markRaw(ExitIconRaw);
export const ExpandLessIcon = markRaw(ExpandLessIconRaw);
export const ExpandMoreIcon = markRaw(ExpandMoreIconRaw);
export const FileDownloadIcon = markRaw(FileDownloadIconRaw);
export const FileUploadIcon = markRaw(FileUploadIconRaw);
export const FilledCircleIcon = markRaw(FilledCircleIconRaw);
export const FilledRectangleIcon = markRaw(FilledRectangleIconRaw);
export const FilledTriangleIcon = markRaw(FilledTriangleIconRaw);
export const FilterNoneIcon = markRaw(FilterNoneIconRaw);
export const FlipToBackIcon = markRaw(FlipToBackIconRaw);
export const FlipToFrontIcon = markRaw(FlipToFrontIconRaw);
export const FolderOpenIcon = markRaw(FolderOpenIconRaw);
export const FontDownloadIcon = markRaw(FontDownloadIconRaw);
export const FormatAlignCenterIcon = markRaw(FormatAlignCenterIconRaw);
export const FormatAlignJustifyIcon = markRaw(FormatAlignJustifyIconRaw);
export const FormatAlignLeftIcon = markRaw(FormatAlignLeftIconRaw);
export const FormatAlignRightIcon = markRaw(FormatAlignRightIconRaw);
export const FormatBoldIcon = markRaw(FormatBoldIconRaw);
export const FormatColorFillIcon = markRaw(FormatColorFillIconRaw);
export const FormatColorResetIcon = markRaw(FormatColorResetIconRaw);
export const FormatItalicIcon = markRaw(FormatItalicIconRaw);
export const FormatSizeIcon = markRaw(FormatSizeIconRaw);
export const FormatStrikethroughIcon = markRaw(FormatStrikethroughIconRaw);
export const FormatUnderlinedIcon = markRaw(FormatUnderlinedIconRaw);
export const FullscreenExitIcon = markRaw(FullscreenExitIconRaw);
export const FullscreenIcon = markRaw(FullscreenIconRaw);
export const GroupIcon = markRaw(GroupIconRaw);
export const HamburgerIcon = markRaw(HamburgerIconRaw);
export const HandIcon = markRaw(HandIconRaw);
export const HandOffIcon = markRaw(HandOffIconRaw);
export const HelpIcon = markRaw(HelpIconRaw);
export const HighlightOffIcon = markRaw(HighlightOffIconRaw);
export const HLineIcon = markRaw(HLineIconRaw);
export const HourGlassIcon = markRaw(HourGlassIconRaw);
export const ImageIcon = markRaw(ImageIconRaw);
export const ImportExportIcon = markRaw(ImportExportIconRaw);
export const InfoIcon = markRaw(InfoIconRaw);
export const InfoOutlineIcon = markRaw(InfoOutlineIconRaw);
export const InsertDriveFileIcon = markRaw(InsertDriveFileIconRaw);
export const InsertEmoticonIcon = markRaw(InsertEmoticonIconRaw);
export const KeyboardArrowLeftIcon = markRaw(KeyboardArrowLeftIconRaw);
export const KeyboardArrowRightIcon = markRaw(KeyboardArrowRightIconRaw);
export const LanguageIcon = markRaw(LanguageIconRaw);
export const LaptopIcon = markRaw(LaptopIconRaw);
export const LockIcon = markRaw(LockIconRaw);
export const MarkerPenIcon = markRaw(MarkerPenIconRaw);
export const MenuIcon = markRaw(MenuIconRaw);
export const MessageIcon = markRaw(MessageIconRaw);
export const MicIcon = markRaw(MicIconRaw);
export const MicOffIcon = markRaw(MicOffIconRaw);
export const ModeCommentIcon = markRaw(ModeCommentIconRaw);
export const ModeEditIcon = markRaw(ModeEditIconRaw);
export const MoonIcon = markRaw(MoonIconRaw);
export const MoreVertIcon = markRaw(MoreVertIconRaw);
export const MusicVideoIcon = markRaw(MusicVideoIconRaw);
export const NavigateBeforeIcon = markRaw(NavigateBeforeIconRaw);
export const NavigateNextIcon = markRaw(NavigateNextIconRaw);
export const NetworkCheckIcon = markRaw(NetworkCheckIconRaw);
export const NotificationsIcon = markRaw(NotificationsIconRaw);
export const PageviewIcon = markRaw(PageviewIconRaw);
export const PanIcon = markRaw(PanIconRaw);
export const PauseIcon = markRaw(PauseIconRaw);
export const PersonIcon = markRaw(PersonIconRaw);
export const PersonOutlineIcon = markRaw(PersonOutlineIconRaw);
export const PlayArrowIcon = markRaw(PlayArrowIconRaw);
export const PlayOutlineIcon = markRaw(PlayOutlineIconRaw);
export const PointerIcon = markRaw(PointerIconRaw);
export const PowerSettingsNewIcon = markRaw(PowerSettingsNewIconRaw);
export const RecentActorsIcon = markRaw(RecentActorsIconRaw);
export const RectangleIcon = markRaw(RectangleIconRaw);
export const RedoIcon = markRaw(RedoIconRaw);
export const RefreshIcon = markRaw(RefreshIconRaw);
export const RemoveCircleOutlineIcon = markRaw(RemoveCircleOutlineIconRaw);
export const RepeatIcon = markRaw(RepeatIconRaw);
export const ReplyIcon = markRaw(ReplyIconRaw);
export const SaveIcon = markRaw(SaveIconRaw);
export const ScheduleIcon = markRaw(ScheduleIconRaw);
export const SearchIcon = markRaw(SearchIconRaw);
export const SendIcon = markRaw(SendIconRaw);
export const SettingsIcon = markRaw(SettingsIconRaw);
export const SlideCameraIcon = markRaw(SlideCameraIconRaw);
export const SlideshowIcon = markRaw(SlideshowIconRaw);
export const SpinnerIcon = markRaw(SpinnerIconRaw);
export const StopIcon = markRaw(StopIconRaw);
export const SunIcon = markRaw(SunIconRaw);
export const SyncIcon = markRaw(SyncIconRaw);
export const ThumbDownIcon = markRaw(ThumbDownIconRaw);
export const TimeElapsedIcon = markRaw(TimeElapsedIconRaw);
export const TimelapseIcon = markRaw(TimelapseIconRaw);
export const TimerIcon = markRaw(TimerIconRaw);
export const TimerOffIcon = markRaw(TimerOffIconRaw);
export const TitleIcon = markRaw(TitleIconRaw);
export const TriangleIcon = markRaw(TriangleIconRaw);
export const UndoIcon = markRaw(UndoIconRaw);
export const VideocamIcon = markRaw(VideocamIconRaw);
export const VisibilityIcon = markRaw(VisibilityIconRaw);
export const VolumeDownIcon = markRaw(VolumeDownIconRaw);
export const VolumeMuteIcon = markRaw(VolumeMuteIconRaw);
export const VolumeOffIcon = markRaw(VolumeOffIconRaw);
export const VolumeUpIcon = markRaw(VolumeUpIconRaw);
export const VpnKeyIcon = markRaw(VpnKeyIconRaw);
export const WarningIcon = markRaw(WarningIconRaw);
export const WebIcon = markRaw(WebIconRaw);
export const ZoomInIcon = markRaw(ZoomInIconRaw);
export const ZoomOutIcon = markRaw(ZoomOutIconRaw);
export const DeviceIcon = markRaw(DeviceIconRaw);

export const EditPoolIcon = markRaw(EditPoolIconRaw);
export const PoolIcon = markRaw(PoolIconRaw);
export const PoolSearchIcon = markRaw(PoolSearchIconRaw);
export const CloseSearchIcon = markRaw(CloseSearchIconRaw);
export const AddQuestionIcon = markRaw(AddQuestionIconRaw);
export const TooltipIcon = markRaw(TooltipIconRaw);
export const PoolArrowIcon = markRaw(PoolArrowIconRaw);
export const ShortAnswerIcon = markRaw(ShortAnswerIconRaw);
export const SquareIcon = markRaw(SquareIconRaw);
export const DragIcon = markRaw(DragIconRaw);
export const ErrorWarningIcon = markRaw(ErrorWarningIconRaw);
export const ExportIcon = markRaw(ExportIconRaw);
export const UploadIcon = markRaw(UploadIconRaw);
export const DoubleArrowIcon = markRaw(DoubleArrowIconRaw);
export const WebcamPlaceholderIcon = markRaw(WebcamPlaceholderRaw);
export const TextIcon = markRaw(TextIconRaw);
export const PaintBrushIcon = markRaw(PaintBrushIconRaw);
export const PaintingPaletteIcon = markRaw(PaintingPaletteIconRaw);
export const DropIcon = markRaw(DropIconRaw);
export const TrashIcon = markRaw(TrashIconRaw);
export const PenIcon = markRaw(PenIconRaw);
export const CheckedIcon = markRaw(CheckedIconRaw);
export const FontIcon = markRaw(FontIconRaw);
export const RtlIcon = markRaw(RtlIconRaw);
export const LtrIcon = markRaw(LtrIconRaw);
export const FontSizeIcon = markRaw(FontSizeIconRaw);
export const WhiteboardIcon = markRaw(WhiteboardIconRaw);
export const VideocamOffIcon = markRaw(VideocamOffIconRaw);
export const PreviewIcon = markRaw(PreviewIconRaw);
export const FileListIcon = markRaw(FileListIconRaw);
export const FilePlayUrlIcon = markRaw(FilePlayUrlIconRaw);
export const TableTitleIcon = markRaw(TableTitleIconRaw);
export const PinMessageIcon = markRaw(PinMessageIconRaw);
export const AudioRecIcon = markRaw(AudioRecIconRaw);
export const URLFileAdressIcon = markRaw(URLFileAdressIconRaw);
export const AddFileIcon = markRaw(AddFileIconRaw);
export const UploadCloudIcon = markRaw(UploadCloudIconRaw);
export const BgBlurIcon = markRaw(BgBlurIconRaw);
export const ForbiddenIcon = markRaw(ForbiddenIconRaw);
export const PauseCircleIcon = markRaw(PauseCircleIconRaw);
export const PlayCircleIcon = markRaw(PlayCircleIconRaw);
export const StopCircleIcon = markRaw(StopCircleIconRaw);
export const RecordCircleIcon = markRaw(RecordCircleIconRaw);
export const RecordIcon = markRaw(RecordIconRaw);
export const SettingFillIcon = markRaw(SettingFillIconRaw);
export const ServerRecordIcon = markRaw(ServerRecordIconRaw);
export const RecordMorePartsIcon = markRaw(RecordMorePartsIconRaw);
export const RecordFirstPartIcon = markRaw(RecordFirstPartIconRaw);
export const LocalRecordIcon = markRaw(LocalRecordIconRaw);
export const LocalRecordingIcon = markRaw(LocalRecordingIconRaw);
export const CommentIcon = markRaw(CommentIconRaw);
export const FillFeedbackIcon = markRaw(FillFeedbackIconRaw);
export const FeedbackIcon = markRaw(FeedbackIconRaw);
export const CloudRecordCircleIcon = markRaw(CloudRecordCircleIconRaw);
export const LocalRecordCircleIcon = markRaw(LocalRecordCircleIconRaw);
export const RecordPauseCircleIcon = markRaw(RecordPauseCircleIconRaw);
export const ArrowScrollChatIcon = markRaw(ArrowScrollChatIconRaw);
export const TelecommunicationTowerIcon = markRaw(TelecommunicationTowerRaw);
export const DisabledChatIcon = markRaw(DisabledChatRaw);
export const PinIcon = markRaw(PinIconRaw);
export const ArrowUpCircleIcon = markRaw(ArrowUpCircleIconRaw);
export const ArrowDownCircleIcon = markRaw(ArrowDownCircleIconRaw);
export const QuestionMarkCircleIcon = markRaw(QuestionMarkCircleIconRaw);
export const ArrowDownOutlineIcon = markRaw(ArrowDownOutlineIconRaw);
