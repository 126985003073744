<template>
  <!-- eslint-disable  -->

  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :data-name="dataName ? dataName : 'hand-icon'"
  >
    <path
      d="M40 13.7083V36.6667C40 40.15 37.1364 43 33.6364 43H22.0227C20.3045 43 18.6818 42.3192 17.4886 41.1158L7.46344 28.0833C7.18955 27.7272 7.19475 27.2356 7.53803 26.9459C7.86103 26.6733 8.33949 26.3303 9 26C10 25.5 11 25.5 12 26.5C16 30 16.1364 30.1908 16.1364 30.1908V11.3333C16.1364 10.0192 17.2023 8.95833 18.5227 8.95833C19.8432 8.95833 20.9091 10.0192 20.9091 11.3333V22.4167H22.5V7.375C22.5 6.06083 23.5659 5 24.8864 5C26.2068 5 27.2727 6.06083 27.2727 7.375V22.4167H28.8636V8.95833C28.8636 7.64417 29.9295 6.58333 31.25 6.58333C32.5705 6.58333 33.6364 7.64417 33.6364 8.95833V22.4167H35.2273V13.7083C35.2273 12.3942 36.2932 11.3333 37.6136 11.3333C38.9341 11.3333 40 12.3942 40 13.7083Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'HandIcon',
  props: {
    dataName: String,
  },
};
</script>
