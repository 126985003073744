<template>
  <!-- eslint-disable  -->
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.1432 20.2968L33.0758 9.91321C34.47 9.15963 38.1665 7.29772 39.4299 8.27939C40.6932 9.26105 39.2886 12.9038 38.9314 14.4632L37.4566 21.8929L25.7435 24.2104L37.4772 26.6321L39.0148 33.6318C39.346 35.188 40.7412 38.7975 39.485 39.7418C38.2288 40.6861 34.5502 38.7931 33.1649 38.0292L13.1741 27.4013C12.552 27.0812 11.1636 26.2797 9.86293 25.518C7.37899 24.2104 7.37905 23.6112 9.86293 22.1582C11.1527 21.4023 12.5262 20.6102 13.1432 20.2968Z"/>
</svg>

</template>
<script>
export default {
  name: 'SendIcon',
};
</script>
