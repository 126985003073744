<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" id="sun" width="19.032" height="19.032" viewBox="0 0 19.032 19.032">
    <g id="Group_1657" transform="translate(4.733 4.733)">
      <g id="Group_1656">
        <path
          id="Path_603"
          d="M83.356 76.61a4.8 4.8 0 0 0-3.373-1.41 4.7 4.7 0 0 0-3.373 1.41 4.783 4.783 0 0 0 3.373 8.156 4.7 4.7 0 0 0 3.373-1.41 4.8 4.8 0 0 0 1.41-3.373 4.7 4.7 0 0 0-1.41-3.373zm-.906 5.84a3.526 3.526 0 1 1 1.007-2.467 3.482 3.482 0 0 1-1.007 2.467z"
          class="cls-1"
          transform="translate(-75.2 -75.2)"
        />
      </g>
    </g>
    <g id="Group_1659" transform="translate(15.784 8.861)">
      <g id="Group_1658">
        <path
          id="Path_604"
          d="M253.393 140.8h-1.938a.655.655 0 1 0 0 1.309h1.938a.655.655 0 0 0 0-1.309z"
          class="cls-1"
          transform="translate(-250.8 -140.8)"
        />
      </g>
    </g>
    <g id="Group_1661" transform="translate(8.861 15.784)">
      <g id="Group_1660">
        <path
          id="Path_605"
          d="M141.455 250.8a.666.666 0 0 0-.655.655v1.938a.655.655 0 1 0 1.309 0v-1.938a.666.666 0 0 0-.654-.655z"
          class="cls-1"
          transform="translate(-140.8 -250.8)"
        />
      </g>
    </g>
    <g id="Group_1663" transform="translate(13.758 13.758)">
      <g id="Group_1662">
        <path
          id="Path_606"
          d="M221.08 220.173l-1.385-1.385a.641.641 0 0 0-.906.906l1.385 1.385a.641.641 0 0 0 .906-.906z"
          class="cls-1"
          transform="translate(-218.6 -218.6)"
        />
      </g>
    </g>
    <g id="Group_1665" transform="translate(8.861)">
      <g id="Group_1664">
        <path
          id="Path_607"
          d="M141.455 0a.666.666 0 0 0-.655.655v1.938a.655.655 0 1 0 1.309 0V.655a.666.666 0 0 0-.654-.655z"
          class="cls-1"
          transform="translate(-140.8)"
        />
      </g>
    </g>
    <g id="Group_1667" transform="translate(13.783 2.606)">
      <g id="Group_1666">
        <path
          id="Path_608"
          d="M221.48 41.589a.638.638 0 0 0-.906 0l-1.385 1.385a.641.641 0 0 0 .906.906l1.385-1.38a.638.638 0 0 0 0-.911z"
          class="cls-1"
          transform="translate(-219 -41.4)"
        />
      </g>
    </g>
    <g id="Group_1669" transform="translate(0 8.861)">
      <g id="Group_1668">
        <path
          id="Path_609"
          d="M2.593 140.8H.655a.666.666 0 0 0-.655.655.65.65 0 0 0 .655.655h1.938a.655.655 0 1 0 0-1.309z"
          class="cls-1"
          transform="translate(0 -140.8)"
        />
      </g>
    </g>
    <g id="Group_1671" transform="translate(2.58 13.758)">
      <g id="Group_1670">
        <path
          id="Path_610"
          d="M43.48 218.789a.638.638 0 0 0-.906 0l-1.385 1.385a.641.641 0 0 0 .906.906l1.385-1.38a.638.638 0 0 0 0-.911z"
          class="cls-1"
          transform="translate(-41 -218.6)"
        />
      </g>
    </g>
    <g id="Group_1673" transform="translate(2.58 2.606)">
      <g id="Group_1672">
        <path
          id="Path_611"
          d="M43.48 42.973l-1.38-1.384a.641.641 0 0 0-.906.906l1.385 1.385a.641.641 0 1 0 .906-.906z"
          class="cls-1"
          transform="translate(-41 -41.4)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SunIcon',
};
</script>

<style scoped>
.cls-1 {
  fill: #fff;
}
</style>
