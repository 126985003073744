<template>
  <!-- eslint-disable  -->

  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5498 36.7061C16.8769 36.7056 16.2249 36.4723 15.7045 36.0457L7.00284 28.9173C5.813 27.8837 5.66087 26.0912 6.6595 24.8718C7.65813 23.6524 9.44541 23.4483 10.6933 24.4111L16.7612 29.3773C17.1467 29.6928 17.7056 29.6765 18.072 29.339L36.8566 12.0384C37.5867 11.1703 38.7542 10.8048 39.8489 11.1017C40.9437 11.3985 41.7666 12.3038 41.9581 13.4217C42.1496 14.5397 41.6748 15.6672 40.7413 16.3115L19.5309 35.9291C18.9942 36.432 18.2852 36.7101 17.5498 36.7061Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'DoneIcon',
};
</script>
