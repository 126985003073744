<template>
  <!-- eslint-disable  -->
  <svg width="64" height="64" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M256 128C256 198.692 198.692 256 128 256C57.3075 256 0 198.692 0 128C0 57.3075 57.3075 0 128 0C198.692 0 256 57.3075 256 128Z" fill="#2B2F32" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M128.523 155.69C156.234 155.69 178.678 133.183 178.678 105.535C178.678 77.8239 156.234 55.3795 128.523 55.3795C100.812 55.3795 78.368 77.8239 78.368 105.535C78.368 133.183 100.812 155.69 128.523 155.69ZM128.523 180.767C101.006 180.767 50.7858 192.119 33.9138 214.786C57.301 240.128 90.7966 256 128 256C165.507 256 199.247 239.868 222.658 214.163C205.338 191.911 155.787 180.767 128.523 180.767Z"
      fill="#66666B"
    />
  </svg>
</template>
<script>
export default {
  name: 'WebcamPlaceholder',
};
</script>
