export function asArray<T>(arg: T | T[] | null) {
  return Array.isArray(arg) ? arg : arg !== null ? [arg] : [];
}

export function encodeBase64Utf8(str: string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode(Number('0x' + p1));
    }),
  );
}

export function uniqueId() {
  return `${Date.now()}${Math.floor(Math.random() * 1000)}`;
}

export function isArray(o: any) {
  return Array.isArray(o);
}

export function flattenObj(obj: object) {
  const result = {};

  for (const i in obj) {
    if (typeof obj[i] === 'object' && !isArray(obj[i])) {
      const temp = flattenObj(obj[i]);
      for (const j in temp) {
        result[i + '::' + j] = temp[j];
      }
    } else {
      result[i] = obj[i];
    }
  }

  return result;
}

export function randomItem<T = any>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}
