<template>
  <!-- eslint-disable  -->
<svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 48 48">
  <path d="M 33.5,22 26,14.5 6,34.5 V 42 h 7.5 z m 5.91,-5.91 c 0.78,-0.78 0.78,-2.05 0,-2.83 L 34.74,8.59 c -0.78,-0.78 -2.05,-0.78 -2.83,0 L 28,12.5 35.5,20 Z" />
  <path d="m 42,36 v 6 H 18 l 6,-6" />
</svg>

</template>
<script>
export default {
  name: 'MarkerPenIcon',
};
</script>
