<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M30.0341 34.3425C29.968 34.1352 29.7754 33.9945 29.5578 33.9945H18.6434C18.4253 33.9945 18.2323 34.1359 18.1666 34.3438L16.1733 40.6507C16.1076 40.8586 15.9147 41 15.6966 41H9.7067C9.36173 41 9.12039 40.6589 9.23521 40.3336L20.8826 7.33359C20.9531 7.13368 21.1421 7 21.3541 7H26.8024C27.014 7 27.2028 7.13329 27.2736 7.33277L38.9854 40.3328C39.1009 40.6582 38.8596 41 38.5142 41H32.5245C32.307 41 32.1144 40.8593 32.0482 40.652L30.0341 34.3425ZM20.2751 27.6699C20.1737 27.9921 20.4143 28.3201 20.7521 28.3201H27.4458C27.7842 28.3201 28.0249 27.9909 27.9222 27.6684L24.557 17.0981C24.4089 16.6329 23.7502 16.634 23.6036 17.0996L20.2751 27.6699Z"/>
  </svg>
</template>
<script>
export default {
  name: 'FontIcon',
};
</script>
