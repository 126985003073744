<template>
  <!-- eslint-disable  -->

  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :data-name="dataName ? dataName : 'mic-icon'"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.2535 7.56948C27.8608 5.07084 24.2607 4.31513 21.1256 5.65344C17.9906 6.99174 15.9351 10.1617 15.9141 13.6907V22.8554C15.9141 27.6849 19.6729 31.6 24.3095 31.6C28.9462 31.6 32.705 27.6849 32.705 22.8554V13.6907C32.6913 11.3942 31.8107 9.19543 30.2535 7.56948Z"
    />
    <path
      d="M37.5991 23.3071C37.6807 30.5286 32.4721 36.6282 25.5768 37.386V40H27.5C28.3284 40 29 40.6716 29 41.5C29 42.3284 28.3284 43 27.5 43H21C20.1716 43 19.5 42.3284 19.5 41.5C19.5 40.6716 20.1716 40 21 40H23.0245V37.4559C16.1615 36.7013 10.9641 30.6526 11.0022 23.4645C10.9752 22.9897 11.1935 22.5359 11.5749 22.2741C11.9562 22.0123 12.4426 21.9823 12.851 22.1954C13.2593 22.4085 13.5275 22.8323 13.5545 23.3071C13.5051 26.3252 14.6101 29.2398 16.626 31.4084C18.6418 33.577 21.403 34.8215 24.3006 34.8675C27.1983 34.8215 29.9594 33.577 31.9753 31.4084C33.9912 29.2398 35.0962 26.3252 35.0468 23.3071C35.0468 22.573 35.6182 21.978 36.3229 21.978C37.0277 21.978 37.5991 22.573 37.5991 23.3071Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'MicIcon',
  props: {
    dataName: String,
  },
};
</script>
