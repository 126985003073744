<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M42.134 40.909C42.5189 40.909 42.7594 40.4923 42.567 40.159L24.433 8.75C24.2406 8.41667 23.7594 8.41667 23.567 8.75L5.43301 40.159C5.24056 40.4923 5.48112 40.909 5.86603 40.909H42.134ZM33.9884 36.2077C34.3733 36.2077 34.6139 35.7911 34.4215 35.4577L24.433 18.1527C24.2406 17.8193 23.7594 17.8193 23.567 18.1526L13.5746 35.4577C13.3821 35.791 13.6227 36.2077 14.0076 36.2077H33.9884Z"/>
  </svg>

</template>
<script>
export default {
  name: 'TriangleIcon',
};
</script>
