<template>
  <!-- eslint-disable  -->
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43 24C43 34.4934 34.4934 43 24 43C13.5066 43 5 34.4934 5 24C5 13.5066 13.5066 5 24 5C34.4934 5 43 13.5066 43 24ZM24 40.4091C33.0625 40.4091 40.4091 33.0625 40.4091 24C40.4091 14.9375 33.0625 7.59091 24 7.59091C14.9375 7.59091 7.59091 14.9375 7.59091 24C7.59091 33.0625 14.9375 40.4091 24 40.4091Z"
      fill="#43566D"
    />
    <path
      d="M25.75 19.625C25.75 18.8096 25.75 18.4019 25.8832 18.0803C26.0608 17.6515 26.4015 17.3108 26.8303 17.1332C27.1519 17 27.5596 17 28.375 17C29.1904 17 29.5981 17 29.9197 17.1332C30.3485 17.3108 30.6892 17.6515 30.8668 18.0803C31 18.4019 31 18.8096 31 19.625V28.375C31 29.1904 31 29.5981 30.8668 29.9197C30.6892 30.3485 30.3485 30.6892 29.9197 30.8668C29.5981 31 29.1904 31 28.375 31C27.5596 31 27.1519 31 26.8303 30.8668C26.4015 30.6892 26.0608 30.3485 25.8832 29.9197C25.75 29.5981 25.75 29.1904 25.75 28.375V19.625Z"
      fill="#43566D"
    />
    <path
      d="M17 19.625C17 18.8096 17 18.4019 17.1332 18.0803C17.3108 17.6515 17.6515 17.3108 18.0803 17.1332C18.4019 17 18.8096 17 19.625 17C20.4404 17 20.8481 17 21.1697 17.1332C21.5985 17.3108 21.9392 17.6515 22.1168 18.0803C22.25 18.4019 22.25 18.8096 22.25 19.625V28.375C22.25 29.1904 22.25 29.5981 22.1168 29.9197C21.9392 30.3485 21.5985 30.6892 21.1697 30.8668C20.8481 31 20.4404 31 19.625 31C18.8096 31 18.4019 31 18.0803 30.8668C17.6515 30.6892 17.3108 30.3485 17.1332 29.9197C17 29.5981 17 29.1904 17 28.375V19.625Z"
      fill="#43566D"
    />
  </svg>
</template>
<script>
export default {
  name: 'RecordPauseCircleIcon',
};
</script>
