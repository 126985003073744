<template>
  <!-- eslint-disable  -->
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21 22.25C21 21.2835 21.7835 20.5 22.75 20.5L26.25 20.5C27.2165 20.5 28 21.2835 28 22.25L28 25.75C28 26.7165 27.2165 27.5 26.25 27.5L22.75 27.5C21.7835 27.5 21 26.7165 21 25.75L21 22.25Z"/>
  <path d="M21 11.75C21 10.7835 21.7835 10 22.75 10L26.25 10C27.2165 10 28 10.7835 28 11.75L28 15.25C28 16.2165 27.2165 17 26.25 17L22.75 17C21.7835 17 21 16.2165 21 15.25L21 11.75Z"/>
  <path d="M21 32.75C21 31.7835 21.7835 31 22.75 31L26.25 31C27.2165 31 28 31.7835 28 32.75L28 36.25C28 37.2165 27.2165 38 26.25 38L22.75 38C21.7835 38 21 37.2165 21 36.25L21 32.75Z"/>
</svg>

</template>
<script>
export default {
  name: 'MoreVertIcon',
};
</script>
