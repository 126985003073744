import { extendOptionsWithDefaults } from '@/modules/whiteboard.v1/assets/js/util';
import DomElement from '@/modules/whiteboard.v1/assets/js/domElement';

export function prepareIcon(svgString: string, className?: string, attr?: { [key: string]: string | number }) {
  className = `icon ${className ?? ''}`;
  attr = extendOptionsWithDefaults(attr, {
    width: 24,
    height: 24,
    class: className,
  });

  const icon = DomElement.Select(svgString).addClass(className);
  if (attr) {
    for (const key in attr) {
      // eslint-disable-next-line no-prototype-builtins
      if (attr.hasOwnProperty(key)) icon.attr(key, attr[key]);
    }
  }

  return icon.toString();
}
